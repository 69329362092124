import { Col, Row } from 'antd';
import ChartGroupedColumnPlot from '../../../../Components/ChartGroupedColumnPlot/ChartGroupedColumnPlot';

export default function MultipleCharts({ title, items }) {
    return (
        <div className="block">
            <h4 className="pb8">{title}</h4>
            <Row>
                {items.map(({ typeChart, titleChart, data }) => {
                    switch (typeChart) {
                        case 'groupedColumnPlot':
                            return (
                                <Col xs={24} md={8}>
                                    <ChartGroupedColumnPlot
                                        data={data}
                                        title={titleChart}
                                    />
                                </Col>
                            );
                        default:
                            return null;
                    }
                })}
            </Row>
        </div>
    );
}
