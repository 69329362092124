import './badge.scss';
import classNames from 'classnames';

/**
 * @param {number|string} value
 * @returns {JSX.Element}
 * @constructor
 */
export default function Badge({ value }) {
    let gradeScore = 'A+';
    if (value > 0 && value <= 20) {
        gradeScore = 'E';
    } else if (value > 20 && value <= 40) {
        gradeScore = 'D';
    } else if (value > 40 && value <= 50) {
        gradeScore = 'C';
    } else if (value > 50 && value <= 60) {
        gradeScore = 'C+';
    } else if (value > 60 && value <= 70) {
        gradeScore = 'B';
    } else if (value > 70 && value <= 80) {
        gradeScore = 'B+';
    } else if (value > 80 && value <= 90) {
        gradeScore = 'A';
    } else if (value === 'not ok') {
        gradeScore = 'E';
    } else if (value === '-') {
        gradeScore = 'empty';
    }

    const gradeClass = classNames({
        badge: true,
        'badge--b': gradeScore === 'B' || gradeScore === 'B+',
        'badge--c': gradeScore === 'C' || gradeScore === 'C+',
        'badge--d': gradeScore === 'D',
        'badge--e': gradeScore === 'E',
        'badge--empty': gradeScore === 'empty',
    });

    return (
        <div className={gradeClass}>
            <span className="badge-item">{value}</span>
        </div>
    );
}
