import React, { useState } from 'react'; // Import useState
import { Button, Card, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import AuthRepository from './AuthRepository';
import { notification } from 'antd';
import useAuth from '../Hooks/useAuth';
import { useTranslation } from 'react-i18next';

export default function LoginPage() {
    const [api, contextHolder] = notification.useNotification();
    const { setToken } = useAuth();
    const { t } = useTranslation(['site']);
    const navigate = useNavigate();

    // Use useState to track loading state
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {
            setLoading(true); // Start loading
            const item = await AuthRepository.login(values);
            setToken(item.data.token);
            navigate('/app/prepare-data');
        } catch (e) {
            const error = e.response.data;
            api.error({
                message: `Error`,
                description: error.message,
            });
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <>
            {contextHolder}

            <Card bordered={false} className="form form-card">
                <h1 className="pb16">{t('site:login_title')}</h1>
                <p className="text-large pb16">{t('site:login_description')}</p>

                <Form
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: t('site:login_emailDescription'),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder={t('site:login_email')}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: t('site:login_passwordDescription'),
                            },
                        ]}
                    >
                        <Input.Password
                            size="large"
                            placeholder={t('site:login_password')}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            block
                            type="primary"
                            size="large"
                            htmlType="submit"
                            loading={loading} // Pass the loading state to the Button
                        >
                            {t('site:login_loginButton')}
                        </Button>
                    </Form.Item>

                    <Form.Item className="m0">
                        <Link type="link" to="/auth/forgot-password">
                            {t('site:login_forgotPassword')}
                        </Link>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
}
