import Header from '../../Components/Header';
import React, { useEffect, useState } from 'react';
import AccountsRepository from './AccountsRepository';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'antd';
import http from '../../../Services/HttpService';
import { EditOutlined } from '@ant-design/icons';

export default function AccountsUsersList() {
    const { accountId } = useParams();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            title: 'Name',
            key: 'name',
            render: (_, record) => (
                <>{`${record.first_name} ${record.last_name}`}</>
            ),
        },
        {
            title: 'CPF',
            dataIndex: 'cpf',
            key: 'cpf',
            render: (text) => text,
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => text,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className="display-flex align-items-center fs-20">
                    <Link
                        to={`/app/admin/users/${record.id}/edit`}
                        className="ml8"
                    >
                        <EditOutlined />
                    </Link>
                </div>
            ),
            width: 100,
            fixed: 'right',
        },
    ];

    const fetchData = async (source) => {
        const data = await AccountsRepository.users(accountId, source);
        setData(data);
        setLoading(false);
    };

    useEffect(() => {
        let CancelToken = http.CancelToken;
        let source = CancelToken.source();

        fetchData(source);
    }, []);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <>
            <Header title="Accounts" subTitle="Users" />

            <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                loading={loading}
            />
        </>
    );
}
