import { Link } from 'react-router-dom';
import List from '../../Components/List';
import PermissionsRepository from './PermissionsRepository';
import Header from '../../Components/Header';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

export default function PermissionsList() {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => text,
        },
        {
            title: 'Guard Name',
            dataIndex: 'guard_name',
            key: 'guard_name',
            render: (text) => text,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className="display-flex align-items-center fs-20">
                    <Link to="#">
                        <EyeOutlined />
                    </Link>
                    <Link
                        to={`/app/admin/permissions/${record.id}/edit`}
                        className="ml8"
                    >
                        <EditOutlined />
                    </Link>
                </div>
            ),
            width: 100,
            fixed: 'right',
        },
    ];

    return (
        <>
            <Header
                title="Permissions"
                subTitle="You can change permissions for users here"
                button={{
                    to: '/app/admin/permissions/create',
                    label: 'Create',
                }}
            />
            <List
                fetchDataMethod={PermissionsRepository.list}
                columns={columns}
                pageSize={100}
            />
        </>
    );
}
