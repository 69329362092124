import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useUserInfo() {
    const [userInfo, setUserInfo] = useState(() =>
        JSON.parse(localStorage.getItem('userInfo')),
    );

    const saveUserInfo = (userUserInfo) => {
        localStorage.setItem('userInfo', JSON.stringify(userUserInfo));
        setUserInfo(userUserInfo); // Update the state with the new userInfo
    };

    const clearUserInfo = () => {
        try {
            localStorage.removeItem('userInfo');
        } catch (e) {}
        setUserInfo(null); // Update the state to clear the userInfo
    };

    // ONLY FOR TEST PROPOSE, TO REMOVE BEFORE LIVE
    const [queryParameters] = useSearchParams();

    const getUserRole = () => {
        return (
            queryParameters.get('rule') ?? userInfo.roles[0]?.name ?? 'farmer'
        );
    };

    return {
        saveUserInfo,
        userInfo,
        getUserRole,
        logout: clearUserInfo,
    };
}
