/**
 * @returns {{currentYearLessOne: number, currentYearLessThree: number, currentYearLessTwo: number, currentYear: number}}
 */
export default function GetCurrentYearAndLastThree() {
    const d = new Date();
    const currentYear = d.getFullYear();

    return {
        currentYear: currentYear,
        currentYearLessOne: currentYear - 1,
        currentYearLessTwo: currentYear - 2,
        currentYearLessThree: currentYear - 3,
    };
}
