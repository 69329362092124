import Header from '../../Components/Header';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Row, Col, Button, Checkbox, Input, notification } from 'antd';
import RolesRepository from './RolesRepository';
import PermissionsRepository from '../Permissions/PermissionsRepository';
import http from '../../../Services/HttpService';
import qs from 'qs';

export default function RolesEdit() {
    const { roleId } = useParams();
    const navigate = useNavigate();
    const { form } = Form.useForm();
    const formRef = React.useRef(null);

    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [permissions, setPermissions] = useState({});
    const [initialValues, setInitialValues] = useState({});

    const onFinish = async (values) => {
        setButtonLoading(true);

        const item = await RolesRepository.update(roleId, values);

        if (item) {
            api.success({
                message: 'Item updated',
                description: 'Item successfully updated',
            });
        }

        navigate(`/app/admin/roles`);
    };

    const fetchPermissions = async (source) => {
        const params = qs.stringify({ has_pagination: false });
        const permissions = await PermissionsRepository.list(params, source);

        setPermissions(
            permissions.map((permission) => {
                return {
                    label: permission.name,
                    value: permission.name,
                };
            }),
        );
    };

    const fetchData = async (source) => {
        const data = await RolesRepository.show(roleId, source);
        setInitialValues({
            name: data.name,
            permissions: data.permissions.map((permission) => permission.name),
        });
        setLoading(false);
    };

    useEffect(() => {
        let CancelToken = http.CancelToken;
        let source = CancelToken.source();

        fetchData(source);
        fetchPermissions(source);
    }, []);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <>
            {contextHolder}

            <Header
                title="Roles"
                subTitle="You can change roles for users here"
                button={{ to: '/app/admin/permissions', label: 'Back' }}
            />

            <Form
                form={form}
                name="roles"
                onFinish={onFinish}
                layout="vertical"
                scrollToFirstError
                ref={formRef}
                initialValues={initialValues}
            >
                <Row>
                    <Col>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Item name="permissions" label="Permissions">
                            <Checkbox.Group
                                options={permissions}
                                defaultValue={initialValues}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button
                        loading={buttonLoading}
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
