import { Col, Form, Input, Row, Tooltip } from 'antd';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CollapsibleEye from './CollapsibleEye';
import GetCurrentYearAndLastThree from '../../../../Admin/Pages/Applications/Utils/GetCurrentYearAndLastThree';

export default function FinancialKeyFiguresForm({ type }) {
    const { t } = useTranslation(['site']);
    const items = ['TotalRevenue', 'TotalCosts', 'TotalProfit'];

    const {
        currentYear,
        currentYearLessOne,
        currentYearLessTwo,
        currentYearLessThree,
    } = GetCurrentYearAndLastThree();

    return (
        <CollapsibleEye step={4} type={type}>
            <>
                {items.map((item) => (
                    <div key={item}>
                        <p className="text-medium pb8">
                            {t(`site:ca4_5_${type}${item}`)}

                            {item === 'TotalCosts' && (
                                <Tooltip
                                    className="pl8"
                                    title={t(
                                        `site:ca4_5_${type}${item}Tooltip`,
                                    )}
                                >
                                    <InfoCircleOutlined className="fs-20" />
                                </Tooltip>
                            )}
                        </p>
                        <Row gutter={24}>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name={`${item}${currentYearLessThree}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('site:ca4_5_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="one-time-code"
                                        size="large"
                                        placeholder={`${currentYearLessThree}*`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name={`${item}${currentYearLessTwo}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('site:ca4_5_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="one-time-code"
                                        size="large"
                                        placeholder={`${currentYearLessTwo}*`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name={`${item}${currentYearLessOne}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('site:ca4_5_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="one-time-code"
                                        size="large"
                                        placeholder={`${currentYearLessOne}*`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name={`${item}${currentYear}`}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('site:ca4_5_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="one-time-code"
                                        size="large"
                                        placeholder={`${t(
                                            'site:ca3_5_forecast',
                                        )} ${currentYear}`}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                ))}
            </>
        </CollapsibleEye>
    );
}
