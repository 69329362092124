import { useEffect, useState } from 'react';
import api from '../../Services/HttpService';
import { Col, Row, Table } from 'antd';
import qs from 'qs';
import ExceptionHandler from '../Services/ExceptionHandler';

/**
 * @param columns
 * @param fetchDataMethod
 * @param filtersPanel
 * @returns {JSX.Element}
 * @constructor
 */
export default function List({ columns, fetchDataMethod, filtersPanel }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [listParameters, setListParameters] = useState({});

    const getParams = (params) => {
        return {
            page: params.pagination?.current,
            ...params,
        };
    };

    const fetchData = async (source) => {
        setLoading(true);

        const params = qs.stringify(getParams(listParameters));

        try {
            const responseData = await fetchDataMethod(params, source);
            // console.log(responseData);
            setData(
                responseData.data.map((item) => {
                    item['key'] = item.id;
                    return item;
                }),
            );

            setLoading(false);

            setListParameters({
                ...listParameters,
                pagination: {
                    ...listParameters.pagination,
                    total: responseData.total,
                    current: responseData.current_page,
                },
            });
        } catch (error) {
            console.log(error);
            ExceptionHandler.catch(error);
        }
    };

    const filtersPanelHTML =
        filtersPanel !== undefined ? (
            <div className="card card-body">
                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    {filtersPanel}
                    <Col span={6} xs={24} md={7}>
                        <button
                            onClick={() => handlePanelFilters()}
                            type="button"
                            className="btn py-2 "
                        >
                            <span className="btn-inner--text">Filter</span>
                        </button>
                    </Col>
                </Row>
            </div>
        ) : null;

    const handleTableChange = (pagination, filters, sorter) => {
        setListParameters({
            pagination,
            filters,
            ...sorter,
        });
        // `dataSource` is useless since `pageSize` changed

        if (pagination.pageSize !== listParameters.pagination?.pageSize) {
            setData([]);
        }
    };

    const handlePanelFilters = () => {
        /*setListParameters({
          ...listParameters
        })*/
    };

    useEffect(() => {
        const CancelToken = api.CancelToken;
        const source = CancelToken.source();

        fetchData(source);

        return () => {
            source.cancel('cancelling request');
        };
    }, [JSON.stringify(listParameters)]);

    return (
        <>
            <div id="filtersPanel">{filtersPanelHTML}</div>
            <Table
                dataSource={data}
                columns={columns}
                pagination={{
                    ...listParameters.pagination,
                    pageSize: process.env.REACT_DEFAULT_PAGE_SIZE ?? 100,
                    showSizeChanger: false,
                }}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    );
}
