import './collapsible.scss';
import { Col, Collapse, Row } from 'antd';
import { useState } from 'react';

/**
 * @param {string} itemKey
 * @param {JSX.Element} children
 * @param {function} setIsCollapsed
 * @returns {JSX.Element}
 * @constructor
 */
export default function Collapsible({ itemKey, children, setIsCollapsed }) {
    const [label, setLabel] = useState('more details');

    const onChange = () => {
        if (label === 'more details') {
            setLabel('hide details');
            setIsCollapsed(true);
        } else {
            setLabel('more details');
            setIsCollapsed(false);
        }
    };

    return (
        <Row align="middle" justify="center" className="card-collapse">
            <Col md={24}>
                <Collapse
                    expandIconPosition="end"
                    ghost
                    onChange={onChange}
                    items={[
                        {
                            key: itemKey,
                            label,
                            children,
                        },
                    ]}
                />
            </Col>
        </Row>
    );
}
