import { Link } from 'react-router-dom';
import List from '../../Components/List';
import UsersRepository from './UsersRepository';
import Header from '../../Components/Header';
import { EditOutlined } from '@ant-design/icons';

export default function UsersList() {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <span>
                    {record.first_name} {record.last_name}
                </span>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => <span>{record.email}</span>,
        },
        {
            title: 'Verified at',
            dataIndex: 'email_verified_at',
            key: 'email_verified_at',
            render: (_, record) => <span>{record.email_verified_at}</span>,
        },
        {
            title: 'CPF',
            dataIndex: 'cpf',
            key: 'cpf',
            render: (_, record) => <span>{record.cpf}</span>,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => <span>{record.phone}</span>,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className="display-flex align-items-center fs-20">
                    <Link
                        to={`/app/admin/users/${record.id}/edit`}
                        className="ml8"
                    >
                        <EditOutlined />
                    </Link>
                </div>
            ),
            width: 100,
            fixed: 'right',
        },
    ];

    return (
        <>
            <Header
                title="Users"
                subTitle="You manage users here"
                button={{
                    to: '/app/admin/users/create',
                    label: 'Create',
                }}
            />

            <List
                fetchDataMethod={UsersRepository.list}
                columns={columns}
                pageSize={100}
            />
        </>
    );
}
