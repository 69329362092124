export const DEFAULT_VALUES = [
    {
        value: 'ok_nok',
        label: 'OK or NOK',
    },
    {
        value: 'score',
        label: 'Score',
    },
];
