import { useState } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Content, Header } from 'antd/lib/layout/layout';
import logo from '../Assets/logo.svg';
import useAuth from '../Hooks/useAuth';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

export default function LayoutSiteAndSystem() {
    const [current, setCurrent] = useState(null);
    const navigate = useNavigate();
    const { token } = useAuth();
    const { t } = useTranslation(['site']);

    const handleMenuClick = ({ key }) => {
        if (key !== '#') {
            setCurrent(key);
            navigate(key);
        }
    };

    let items = [
        {
            label: t('site:menu_homepage'),
            key: '/',
        },
        {
            label: t('site:menu_about'),
            key: '/about',
        },
    ];

    if (!token) {
        items = [
            ...items,
            {
                label: t('site:menu_login'),
                key: '/auth/login',
            },
            {
                label: t('site:menu_register'),
                key: '/auth/register',
            },
        ];
    } else {
        items = [
            ...items,
            {
                label: t('site:menu_dashboard'),
                key: '/app/admin/applications/create',
            },
            {
                label: t('site:menu_logout'),
                key: '/auth/logout',
            },
        ];
    }

    items = [
        ...items,
        {
            label: <LanguageSelector />,
            key: '#',
        },
    ];

    return (
        <Layout>
            <Header
                className="header"
                style={{ zIndex: '1', background: 'transparent' }}
            >
                <NavLink to="/" title="Logo">
                    <img src={logo} alt="Logo" />
                </NavLink>
                <Menu
                    style={{ minWidth: 0, flex: 'auto', justifyContent: 'end' }}
                    onClick={handleMenuClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    items={items}
                />
            </Header>
            <Content className="pb32">
                <div className="bg-page"></div>
                <Outlet />
            </Content>
        </Layout>
    );
}
