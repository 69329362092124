import { Col, Row } from 'antd';
import productRiskInteractive from '../../../Assets/Report/product-risk-interactive.png';
import nvdi from '../../../Assets/Report/NDVIData.jpg';
import esg1 from '../../../Assets/Report/esg1.png';
import esg2 from '../../../Assets/Report/esg2.png';
import esg3 from '../../../Assets/Report/esg3.png';
import farmLocation from '../../../Assets/Report/farmLocation.png';
import climateRisk2 from '../../../Assets/Report/climate-risk2.jpg';
import climateRisk3 from '../../../Assets/Report/climate-risk3.jpg';
import farmImage1 from '../../../Assets/Report/farm-basic-1.png';
import farmImage2 from '../../../Assets/Report/farm-basic-2.png';
import farmImage3 from '../../../Assets/Report/farm-basic-3.png';
import ReportHeader from '../../Components/Report/ReportHeader';
import ReportCard from '../../Components/Report/ReportCard';
import BlockInfo from '../../Components/BlockInfo/BlockInfo';
import TableCustom from './Components/Table/TableCustom';
import ListScore from '../../Components/ListScore/ListScore';
import ChartGroupedColumnPlot from '../../Components/ChartGroupedColumnPlot/ChartGroupedColumnPlot';

export default function Report() {
    return (
        <div className="report-wrapper mt24">
            <ReportHeader
                id="2023B2NS02"
                borrower="Central Agrícola Example Ltda"
                borrowerId="XX01"
                totalCurrentOutstanding="USD 0"
                numberTransactions="0"
                CNPJ="00.123.444/0000-22"
                companyType="Limited Liability Company"
                ownership="Family-Owned"
                owners="Jeanette P. Ortiz, Richard N. Wertz, Robert J. O'Connor"
                location="Uruçuí - Piauí"
                newLoanAmount="USD 2.000.000"
                interestRate="12%"
                session="2023/24"
                loanTerm="Up to 14 months"
                typeContract="Fertilizer"
                crop="Cotton"
                salesChannel="A1 (20% Co-Invest)"
                inputSeller="-"
                offTaker="-"
                collateral="fCPR (Land + Personal Guarantee)"
                score="89"
            />

            <div className="card-list">
                {/* Farm Conditions */}
                <ReportCard
                    title={'Farm Conditions'}
                    items={[
                        {
                            label: 'Technology (Machinery & Equipment)',
                            value: 90,
                        },
                        {
                            label: 'Production Processes & Digital Services',
                            value: 90,
                        },
                        {
                            label: 'Access to Infrastructure (Roads, Shipping)',
                            value: 90,
                        },
                        {
                            label: 'Well established Logistics for Storage and Crop Sales (e.g. Silos)',
                            value: 90,
                        },
                        {
                            label: 'Crop Diversity',
                            value: 90,
                        },
                    ]}
                    badgeText={'94'}
                    collapsedContent={
                        <>
                            <div className="block-summary">
                                <header>Reasoning for Rating</header>
                                <p className="text-small">
                                    Canel is a professionally managed farm. They
                                    use state-of-the-art technology and
                                    machinery•Own repair shop on farm•Precision
                                    ag farming using different farm management
                                    systems (solinftec)•Farm workers live on
                                    farm•Worker's security level very high
                                    (equipment, regular trainings, and adherence
                                    to security guidelines)•On-site farm storage
                                    (2 silos with 36,000 tons and 12,000 tons
                                    each)•Drying facilities for corn and
                                    soybean•Ginning and additional storage
                                    facilities for cotton•Own transportation
                                    equipment (i.e. trucks) and access to well
                                    established infrastructure (decent roads
                                    with asphalt)•Feed processing, chicken and
                                    cattle fattening
                                </p>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">Farm Area</h4>
                                <Row>
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Total Arable Land in ha',
                                                value: '12,601',
                                            },
                                            {
                                                label: 'Farm Size in ha',
                                                value: '12,601',
                                            },
                                        ]}
                                    />
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Cotton in ha',
                                                value: '3,367',
                                            },
                                            {
                                                label: 'Soy Bean in ha',
                                                value: '5,455',
                                            },
                                            {
                                                label: 'Corn in ha',
                                                value: '3,779',
                                            },
                                        ]}
                                    />
                                </Row>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">Farm impressions</h4>
                                <Row gutter={16} justify={'center'}>
                                    <Col xs={24} md={7}>
                                        <img
                                            className="img-responsive img-rounded img-shadow"
                                            src={farmImage1}
                                            alt="Farm 1"
                                        />
                                    </Col>
                                    <Col xs={24} md={7}>
                                        <img
                                            className="img-responsive img-rounded img-shadow"
                                            src={farmImage2}
                                            alt="Farm 2"
                                        />
                                    </Col>
                                    <Col xs={24} md={7}>
                                        <img
                                            className="img-responsive img-rounded img-shadow"
                                            src={farmImage3}
                                            alt="Farm 3"
                                        />
                                    </Col>
                                </Row>
                                <p className="text-center">
                                    Images taken on the farm by AgriFin
                                    employees. Machinery, Crop Processing, farm
                                    buildings including silos (left to right)
                                </p>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">Farm Location</h4>
                                <Row justify={'center'}>
                                    <Col xs={24} md={14}>
                                        <img
                                            className="img-responsive"
                                            src={farmLocation}
                                            alt="Farm Location"
                                        />
                                        <p className="text-center">
                                            The Canel farm is located in Uruçuí,
                                            Piauí, Brazil
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }
                />

                {/* Farm Financials */}
                <ReportCard
                    title={'Farm Financials'}
                    items={[
                        {
                            label: 'Free Cash Flow from Crop Production - Current Year',
                            extraInfo: '18% of crop-related revenue',
                            value: 90,
                        },
                        {
                            label: 'Expected Free Cash Flow from Crop Production - Next Year',
                            extraInfo: '- 8%',
                            value: 49,
                        },
                        {
                            label: 'Total Debt / Total Land Assets',
                            extraInfo: '- 11%',
                            value: 90,
                        },
                        {
                            label: 'Debt / Operational Result',
                            extraInfo: '1.4x',
                            value: 49,
                        },
                        {
                            label: 'Operational Result / (Principal & Interest current year)',
                            extraInfo: '18.1x',
                            value: 90,
                        },
                        {
                            label: 'Bankruptcy / Chapter 11',
                            extraInfo: 'None',
                            value: 90,
                        },
                    ]}
                    badgeText={'73'}
                    collapsedContent={
                        <>
                            <div className="block-summary">
                                <header>Reasoning for Rating</header>
                                <p className="text-small">
                                    Mid-sized farm with c. USD XXX m revenues.
                                    Farm with solid operations yielding >10%
                                    remaining cash for current and next year
                                    cycle, further protected by crop
                                    diversification (soy, corn, cotton). The
                                    loan-to-value ratio below 25% (11%). The
                                    overall farm debt is at a low level (c.
                                    factor 1.2 of operational result in (cycle
                                    22/23))
                                </p>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">Financial Numbers</h4>
                                <Row>
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Total Revenue',
                                                value: '$ 32,143,215',
                                            },
                                        ]}
                                    />
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Total Revenue per ha',
                                                value: '$ 2,876',
                                            },
                                        ]}
                                    />
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Operational Result',
                                                value: '$ 8,042,956',
                                            },
                                        ]}
                                    />
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Total Cost of Production',
                                                value: '$ 20,885,937',
                                            },
                                        ]}
                                    />
                                </Row>
                            </div>
                        </>
                    }
                />

                {/* Counter Party */}
                <ReportCard
                    title={'Counter Party'}
                    items={[
                        {
                            label: 'Company - Credit Risk Score',
                            value: 90,
                        },
                        {
                            label: 'Individuals - Credit Risk Score',
                            value: 90,
                        },
                    ]}
                    badgeText={'100'}
                    collapsedContent={
                        <>
                            <div className="block-summary">
                                <header>Reasoning for Rating</header>
                                <p className="text-small">
                                    Very high credit risk score (low risk) for
                                    both company and individuals (owners).
                                    <br />
                                    No documented bankruptcies, financial
                                    restrictions or claims.
                                </p>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">
                                    Credit Score of the Company
                                </h4>
                                <Row>
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Canel Central Agricola Nova Era LTDA',
                                                value: '795',
                                            },
                                        ]}
                                    />
                                </Row>

                                <h4 className="pt24 pb8">
                                    Credit Scores of the Owners
                                </h4>
                                <Row>
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Amilton Bortolozzo',
                                                value: '762',
                                            },
                                        ]}
                                    />
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Helio Segnini Filho',
                                                value: '762',
                                            },
                                        ]}
                                    />
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Jose Roberto Bortolozzo',
                                                value: '792',
                                            },
                                        ]}
                                    />
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Sergio Luis Bortolozzo',
                                                value: '791',
                                            },
                                        ]}
                                    />
                                </Row>
                            </div>
                        </>
                    }
                />

                {/* Production Risk & Field Data */}
                <ReportCard
                    title="Production Risk & Field Data"
                    items={[
                        {
                            label: 'Total Agricultural Area',
                            value: 90,
                        },
                        {
                            label: 'Owned Land Ratio',
                            value: 90,
                        },
                        {
                            label: 'Productivity - Average for last 3 seasons (Soybean)',
                            value: 49,
                        },
                        {
                            label: 'Productivity - Average for last 3 seasons (Corn)',
                            value: 90,
                        },
                        {
                            label: 'Productivity - Average for last 3 seasons (Cotton)',
                            value: 90,
                        },
                        {
                            label: 'Irrigation Area',
                            value: 49,
                        },
                    ]}
                    badgeText={'86'}
                    collapsedContent={
                        <>
                            <div className="block-summary">
                                <header>Reasoning for Rating</header>
                                <p className="text-small">
                                    100% owned land with a successful farming
                                    history of 35 years. <br />
                                    Very stable production due to constant soil
                                    enhancement & conservation – even in
                                    difficult season 2015-2016 (cotton).
                                    <br />
                                    State-of-the-art crop production practices
                                    (equipment, technology, inputs).
                                    <br />
                                    Diversified Crops in contiguous areas.
                                    <br />
                                    Outperforming the regional average on all
                                    crop types.
                                </p>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">NVDI</h4>
                                <Row>
                                    <Col xs={24} md={24}>
                                        <img
                                            className="img-center"
                                            src={nvdi}
                                            alt="NVDI"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">Productivity</h4>
                                <Row>
                                    <Col xs={24} md={8}>
                                        <ChartGroupedColumnPlot
                                            data={[
                                                {
                                                    name: 'Soybean in bags/hectare',
                                                    year: '2020/21',
                                                    value: 61,
                                                },
                                                {
                                                    name: 'Soybean (CONAB) in bags/hectare',
                                                    year: '2020/21',
                                                    value: 54,
                                                },
                                                {
                                                    name: 'Soybean in bags/hectare',
                                                    year: '2021/22',
                                                    value: 64,
                                                },
                                                {
                                                    name: 'Soybean (CONAB) in bags/hectare',
                                                    year: '2021/22',
                                                    value: 59,
                                                },
                                                {
                                                    name: 'Soybean in bags/hectare',
                                                    year: '2022/23',
                                                    value: 65,
                                                },
                                                {
                                                    name: 'Soybean (CONAB) in bags/hectare',
                                                    year: '2022/23',
                                                    value: 61,
                                                },
                                                {
                                                    name: 'Soybean in bags/hectare',
                                                    year: '2023/24',
                                                    value: 65,
                                                },
                                                {
                                                    name: 'Soybean (CONAB) in bags/hectare',
                                                    year: '2023/24',
                                                },
                                            ]}
                                            title="Productivity - Soybean"
                                        />
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <ChartGroupedColumnPlot
                                            data={[
                                                {
                                                    name: 'Corn in bags/hectare',
                                                    year: '2020/21',
                                                    value: 8.5,
                                                },
                                                {
                                                    name: 'Corn (CONAB) in bags/hectare',
                                                    year: '2020/21',
                                                    value: 3,
                                                },
                                                {
                                                    name: 'Corn in bags/hectare',
                                                    year: '2021/22',
                                                    value: 9.1,
                                                },
                                                {
                                                    name: 'Corn (CONAB) in bags/hectare',
                                                    year: '2021/22',
                                                    value: 4.9,
                                                },
                                                {
                                                    name: 'Corn in bags/hectare',
                                                    year: '2022/23',
                                                    value: 10.8,
                                                },
                                                {
                                                    name: 'Corn (CONAB) in bags/hectare',
                                                    year: '2022/23',
                                                    value: 4.9,
                                                },
                                                {
                                                    name: 'Corn in bags/hectare',
                                                    year: '2023/24',
                                                    value: 10.8,
                                                },
                                                {
                                                    name: 'Corn (CONAB) in bags/hectare',
                                                    year: '2023/24',
                                                },
                                            ]}
                                            title="Productivity - Corn"
                                        />
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <ChartGroupedColumnPlot
                                            data={[
                                                {
                                                    name: 'Cotton in @/hectare',
                                                    year: '2020/21',
                                                    value: 344,
                                                },
                                                {
                                                    name: 'Cotton (CONAB) in @/hectare',
                                                    year: '2020/21',
                                                    value: 307,
                                                },
                                                {
                                                    name: 'Cotton in @/hectare',
                                                    year: '2021/22',
                                                    value: 302,
                                                },
                                                {
                                                    name: 'Cotton (CONAB) in @/hectare',
                                                    year: '2021/22',
                                                    value: 272,
                                                },
                                                {
                                                    name: 'Cotton in @/hectare',
                                                    year: '2022/23',
                                                    value: 350,
                                                },
                                                {
                                                    name: 'Cotton (CONAB) in @/hectare',
                                                    year: '2022/23',
                                                    value: 276,
                                                },
                                                {
                                                    name: 'Cotton in @/hectare',
                                                    year: '2023/24',
                                                    value: 350,
                                                },
                                                {
                                                    name: 'Cotton (CONAB) in @/hectare',
                                                    year: '2023/24',
                                                    value: 0,
                                                },
                                            ]}
                                            title="Productivity - Cotton"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }
                />

                {/* Natural Hazard Risk */}
                <ReportCard
                    title="Natural Hazard Risk"
                    items={[
                        {
                            label: 'Precipation Stress',
                            value: 49,
                        },
                        {
                            label: 'Heat Stress',
                            value: 90,
                        },
                        {
                            label: 'River Flood',
                            value: 90,
                        },
                        {
                            label: 'Flash Flood',
                            value: 49,
                        },
                        {
                            label: 'Hail',
                            value: 80,
                        },
                        {
                            label: 'Wildfire',
                            value: 90,
                        },
                        {
                            label: 'Tornado',
                            value: 90,
                        },
                        {
                            label: 'Extratropical Storm',
                            value: 90,
                        },
                        {
                            label: 'Tropical Cyclone',
                            value: 90,
                        },
                        {
                            label: 'Max. Temperatures sustained by crop',
                            value: 90,
                        },
                        {
                            label: 'Annual Rainfall',
                            value: 90,
                        },
                    ]}
                    badgeText={'80'}
                    collapsedContent={
                        <>
                            <div className="block-summary">
                                <header>Reasoning for Rating</header>
                                <p className="text-small">
                                    Very suitable area for one-season crop
                                    production.
                                    <br />
                                    Minor exposure to precipitation & heat
                                    stress (outside of the growing season for
                                    cotton).
                                </p>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">
                                    Climate Risk Distribution
                                </h4>
                                <div className="pl24 pr24">
                                    <TableCustom />
                                </div>

                                <Row justify={'center'}>
                                    <Col xs={24} md={18}>
                                        <img
                                            src={climateRisk2}
                                            alt="risk distribution"
                                        />
                                        <img
                                            src={climateRisk3}
                                            alt="risk distribution"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">
                                    Interactive Risk Map
                                </h4>
                                <Row justify="center">
                                    <Col xs={24} md={16}>
                                        <img
                                            className="img-responsive"
                                            src={productRiskInteractive}
                                            alt="Productivity 1"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </>
                    }
                />

                {/* ESG */}
                <ReportCard
                    title="ESG"
                    items={[
                        {
                            label: 'Biodiversity Protection Zones (IBAT)',
                            value: 90,
                        },
                        {
                            label: 'MSCI Check™️',
                            value: 90,
                        },
                        {
                            label: 'RepRisk™️',
                            value: 90,
                        },
                        {
                            label: 'ISS™️',
                            value: 90,
                        },
                        {
                            label: 'Sanctions Check™️',
                            value: 90,
                        },
                        {
                            label: 'Brain/Serasa™️',
                            value: 90,
                        },
                    ]}
                    badgeText={'OK'}
                    collapsedContent={
                        <>
                            <div className="block-summary">
                                <header>Reasoning for Rating</header>
                                <p className="text-small">
                                    Very good performance regarding ESG for both
                                    company and individual owners.
                                    <br />
                                    No red flags (deforestation, slavery, child
                                    labor, criminal record, IBAT Biodiversity
                                    Layer, soy moratorium).
                                    <br />
                                    Minor irregularities are timely addressed
                                    with governmental entities (for 2 ha of land
                                    there is an obligation to reforestation)
                                </p>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">
                                    Farm Area and preserved areas, IBAT
                                    Biodiversity
                                </h4>
                                <Row gutter={16}>
                                    <Col xs={24} md={8}>
                                        <img
                                            className="img-responsive img-rounded img-shadow"
                                            src={esg1}
                                            alt="ESG 1"
                                        />
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <img
                                            className="img-responsive img-rounded img-shadow"
                                            src={esg2}
                                            alt="ESG 2"
                                        />
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <img
                                            className="img-responsive img-rounded img-shadow"
                                            src={esg3}
                                            alt="ESG 3"
                                            style={{
                                                width: 'fit-content',
                                                height: 'fit-content',
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <h4 className="pt24 pb8">ESG Checkpoints</h4>
                                <ListScore
                                    items={[
                                        {
                                            label: 'Deforestation',
                                            value: 90,
                                        },
                                        {
                                            label: 'Biodiversity Protection Zones️',
                                            value: 90,
                                        },
                                        {
                                            label: 'Embargoed Areas️',
                                            value: 90,
                                        },
                                        {
                                            label: 'Worker’s Health & Safety',
                                            value: 90,
                                        },
                                        {
                                            label: 'Fair Salary️',
                                            value: 90,
                                        },
                                        {
                                            label: 'Soy Moratorium',
                                            value: 90,
                                        },
                                        {
                                            label: 'Green Grain Protocol',
                                            value: 90,
                                        },
                                        {
                                            label: 'Better Cotton Initiative',
                                            value: 90,
                                        },
                                    ]}
                                />
                            </div>
                        </>
                    }
                />

                {/* Security */}
                <ReportCard
                    title="Security"
                    items={[
                        {
                            label: 'CPR',
                            value: 90,
                        },
                        {
                            label: 'Land Real Estate Guarantee',
                            value: 90,
                        },
                        {
                            label: 'Personal Guarantee',
                            value: 90,
                        },
                        {
                            label: 'Crop Insurance - Coverage',
                        },
                    ]}
                    badgeText={'OK'}
                    collapsedContent={
                        <>
                            <div className="block-summary">
                                <header>Reasoning for Rating</header>
                                <p className="text-small">
                                    Very strong guarantees: 153% land collateral
                                    + personal guarantees (aval).
                                    <br />
                                    Consequently, no crop insurance is required.
                                </p>
                            </div>
                            <div className="block">
                                <h4 className="pt24 pb8">Security KPIs</h4>
                                <Row>
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Land Guarantee Value',
                                                value: '$ 4,119,792',
                                            },
                                        ]}
                                    />
                                    <BlockInfo
                                        items={[
                                            {
                                                label: 'Over-Collateralization',
                                                value: '153%<',
                                            },
                                        ]}
                                    />
                                </Row>
                            </div>
                        </>
                    }
                />
            </div>
        </div>
    );
}
