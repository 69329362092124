import { Button, Col, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Header({
    title,
    subTitle,
    button = {},
    actionButtons = null,
    back = false,
}) {
    const { t } = useTranslation(['site']);
    const navigate = useNavigate();

    return (
        <header>
            <h2 className="pt24 pb24 fw-400 color-green-medium">
                {t('site:admin_dashboard_title')}
            </h2>

            <div className="display-flex justify-content-space-between align-items-center pb16">
                <h1 className="pt16">
                    {back && (
                        <a href="#" onClick={() => navigate(-1)}>
                            {title}
                        </a>
                    )}
                    {!back && title}
                </h1>
                <div>
                    {actionButtons &&
                        actionButtons.map((item, index) => {
                            return (
                                <Link key={index} to={item.url} className="m8">
                                    {item.Icon}
                                </Link>
                            );
                        })}
                </div>
            </div>

            {(subTitle || button) && (
                <Row justify="space-between">
                    {subTitle && (
                        <Col>
                            <p className="text-large pb16 fw-400 font-secondary">
                                {subTitle}
                            </p>
                        </Col>
                    )}

                    {button && button.to && button.label && (
                        <Col>
                            <Link to={button.to}>
                                <Button
                                    type="primary"
                                    size="large"
                                    className="mb16"
                                >
                                    {button.label}
                                </Button>
                            </Link>
                        </Col>
                    )}
                </Row>
            )}
        </header>
    );
}
