import api from '../../../Services/HttpService';
import ExceptionHandler from '../../Services/ExceptionHandler';

const API_URL = 'report-blocks';

const ReportBlocksRepository = {
    load: async (source) => {
        try {
            const response = await api.get(`${API_URL}-load`, {
                cancelToken: source.token,
            });
            return response.data;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },

    prepareItems: (items) => {
        return items.map((item) => {
            if (item.type === 'score') {
                return {
                    label: item.title,
                    value: Math.floor(Math.random() * 100), // mock to test dynamic report
                    extraInfo: '8%', // mock to test dynamic report
                };
            }
        });
    },
};

export default ReportBlocksRepository;
