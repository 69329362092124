import ProgressBar from '../../../../../Components/ProgressBar/ProgressBar';
import { Table } from 'antd';

const data = [
    {
        risk_factor: 'Precipitation Stress',
        scale: 5000,
        exposure: 'Medium Exposure',
        land: '100%',
        comment: 'El Nino influence',
    },
    {
        risk_factor: 'Heat Stress',
        scale: 2140,
        exposure: 'No/Very Low Exposure',
        land: '100%',
        comment: 'Corrected - Heat not relevant for this crop',
    },
    {
        risk_factor: 'River Flood',
        scale: 4567,
        exposure: 'No/Very Low Exposure',
        land: '96%',
    },
    {
        risk_factor: 'River Flood',
        scale: 9875,
        exposure: 'Very High Exposure',
        land: '1%',
    },
    {
        risk_factor: 'River Flood',
        scale: 7894,
        exposure: 'Medium Exposure',
        land: '2%',
    },
    {
        risk_factor: 'Tropical Cyclone',
        scale: 1234,
        exposure: 'Medium Exposure',
        land: '100%',
    },
    {
        risk_factor: 'Flash Flood',
        scale: 4321,
        exposure: 'Medium Exposure',
        land: '100%',
    },
    {
        risk_factor: 'Wildfire',
        scale: 6666,
        exposure: 'Medium Exposure',
        land: '100%',
    },
    {
        risk_factor: 'Wildfire',
        scale: 7777,
        exposure: 'Medium Exposure',
        land: '100%',
    },
    {
        risk_factor: 'Hail',
        scale: 8888,
        exposure: 'Low Exposure',
        land: '100%',
    },
    {
        risk_factor: 'Extratropical Storm',
        scale: 9999,
        exposure: 'No/Very Low Exposure',
        land: '100%',
    },
];

/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function TableCustom() {
    const columns = [
        {
            title: 'Risk Factor',
            dataIndex: 'risk_factor',
            key: 'risk_factor',
            render: (text) => text,
        },
        {
            title: 'Scale',
            dataIndex: 'scale',
            key: 'scale',
            width: '30%',
            render: (value) => ProgressBar({ value, size: 'small' }),
        },
        {
            title: 'Exposure',
            dataIndex: 'exposure',
            key: 'exposure',
            render: (text) => text,
        },
        {
            title: '% of Land',
            dataIndex: 'land',
            key: 'land',
            render: (text) => text,
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            render: (text) => text,
        },
    ];

    return (
        <Table
            dataSource={data}
            columns={columns}
            pagination={{
                pageSize: 100,
                hideOnSinglePage: true,
            }}
            className="mb24 pb24"
        />
    );
}
