import Header from '../../Components/Header';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import List from '../../Components/List';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import BadgeStatus from '../../../Components/BadgeStatus/BadgeStatus';
import QuickScoreRepository from './QuickScoreRepository';

export default function QuickScoreList() {
    const { t } = useTranslation(['site']);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text) => text,
        },
        {
            title: t('site:admin_cpf_cnpj'),
            dataIndex: 'code',
            key: 'code',
            render: (text) => text,
        },
        {
            title: t('site:admin_status'),
            dataIndex: 'status',
            key: 'status',
            render: (text) => BadgeStatus({ text }),
        },
        {
            title: t('site:admin_quickScore'),
            dataIndex: 'score',
            key: 'score',
            render: (value) => {
                value = value * 10;
                return ProgressBar({ value, size: 'small' });
            },
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date) =>
                date.substring(0, 10) + ' ' + date.substring(11, 16),
        },
        {
            title: t('site:admin_action'),
            key: 'action',
            render: (_, record) => (
                <div className="display-flex align-items-center fs-20">
                    <Link to={`/app/admin/quick-scores/${record.id}/edit`}>
                        <EyeOutlined
                            className="fs-28"
                            style={{ color: '#444036' }}
                        />
                    </Link>
                </div>
            ),
            width: 170,
            fixed: 'right',
        },
    ];

    return (
        <>
            <Header
                title={t('site:admin_quickScore_title')}
                subTitle={t('site:admin_quickScore_subtitle')}
                button={{
                    to: '/app/admin/quick-scores/create',
                    label: 'Create',
                }}
            />
            <List
                fetchDataMethod={QuickScoreRepository.list}
                columns={columns}
                pageSize={100}
            />
        </>
    );
}
