import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/**
 * @param {number} applicationId
 * @param {number} nextStep
 * @returns {JSX.Element}
 * @constructor
 */
export default function FooterCreditApplicationForm({
    applicationId,
    nextStep,
}) {
    const { t } = useTranslation(['site']);
    const navigate = useNavigate();

    /**
     * @param {number} nextStep
     */
    const saveAndNext = (nextStep) => {
        navigate(`/app/credit-applications/${applicationId}/${nextStep}-of-5`);
    };

    /**
     * @param {number} backStep
     */
    const goBack = (backStep) => {
        navigate(
            `/app/credit-applications/${applicationId}/${backStep - 2}-of-5`,
        );
    };

    return (
        <footer>
            <Row gutter={24}>
                <Col xs={24} md={6}>
                    <Button
                        className="mt16"
                        block
                        size="large"
                        // onClick={() => navigate(-1)}
                        onClick={() => goBack(nextStep)}
                    >
                        {t('site:ca_back')}
                    </Button>
                </Col>
                <Col xs={24} md={6}>
                    <Button
                        className="mt16"
                        block
                        size="large"
                        onClick={() => saveAndNext(nextStep)}
                    >
                        {t('site:ca_saveAndContinue')}
                    </Button>
                </Col>
                <Col xs={24} md={12}>
                    <Button
                        className="mt16"
                        block
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        {t('site:ca_next')}
                    </Button>
                </Col>
            </Row>
        </footer>
    );
}
