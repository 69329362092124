import { useEffect, useState } from 'react';
import ReportHeader from '../../Components/Report/ReportHeader';
import DynamicReportBlocksRepository from './DynamicReportBlocksRepository';
import http from '../../../Services/HttpService';
import ImageGallery from './Components/ImageGallery/ImageGallery';
import MultipleInputValue from './Components/MultipleInputValue/MultipleInputValue';
import OneChart from './Components/OneChart/OneChart';
import Map from './Components/Map/Map';
import MultipleCharts from './Components/MultipleCharts/MultipleCharts';
import ReportCard from '../../Components/Report/ReportCard';
import { useTranslation } from 'react-i18next';

export default function DynamicReport() {
    const { t } = useTranslation(['site']);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();

    const fetchData = async (source) => {
        const data = await DynamicReportBlocksRepository.load(source);
        setData(data);
        setLoading(false);
    };

    useEffect(() => {
        const CancelToken = http.CancelToken;
        const source = CancelToken.source();

        fetchData(source);

        return () => {
            source.cancel('cancelling request');
        };
    }, []);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <div className="report-wrapper mt24">
            <ReportHeader
                id="2023B2NS02"
                borrower="Central Agrícola Example Ltda"
                borrowerId="XX01"
                totalCurrentOutstanding="USD 0"
                numberTransactions="0"
                CNPJ="00.123.444/0000-22"
                companyType="Limited Liability Company"
                ownership="Family-Owned"
                owners="Jeanette P. Ortiz, Richard N. Wertz, Robert J. O'Connor"
                location="Uruçuí - Piauí"
                newLoanAmount="USD 2.000.000"
                interestRate="12%"
                session="2023/24"
                loanTerm="Up to 14 months"
                typeContract="Fertilizer"
                crop="Cotton"
                salesChannel="A1 (20% Co-Invest)"
                inputSeller="-"
                offTaker="-"
                collateral="fCPR (Land + Personal Guarantee)"
                score="75"
            />

            <div className="card-list">
                {data.map((item) => {
                    // mock to test dynamic report
                    item['notes'] =
                        "Canel is a professionally managed farm. They use state-of-the-art technology and machinery•Own repair shop on farm•Precision ag farming using different farm management systems (solinftec)•Farm workers live on farm•Worker's security level very high (equipment, regular trainings, and adherence to security guidelines)•On-site farm storage (2 silos with 36,000 tons and 12,000 tons each)•Drying facilities for corn and soybean•Ginning and additional storage facilities for cotton•Own transportation equipment (i.e. trucks) and access to well established infrastructure (decent roads with asphalt)•Feed processing, chicken and cattle fattening";

                    return (
                        <ReportCard
                            key={item.id}
                            title={item.title}
                            items={DynamicReportBlocksRepository.prepareItems(
                                item.report_block_item,
                            )}
                            badgeText={Math.floor(Math.random() * 100)} // mock to test dynamic report
                            collapsedContent={
                                <>
                                    <div className="block-summary">
                                        <header>
                                            {t('site:report_reason')}
                                        </header>
                                        <p className="text-small">
                                            {item.notes}
                                        </p>
                                    </div>

                                    {item.report_block_detail.map((detail) => {
                                        switch (detail.type) {
                                            case 'one_chart':
                                                // mock to test dynamic report
                                                detail['titleChart'] =
                                                    'NVDI data for farm';
                                                detail['typeChart'] =
                                                    'multiLine';
                                                detail['data'] = [
                                                    {
                                                        year: '2019-01',
                                                        value: 0.6,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2019-01',
                                                        value: 0.5,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2019-03',
                                                        value: 0.78,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2019-03',
                                                        value: 0.95,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2019-05',
                                                        value: 0.98,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2019-05',
                                                        value: 0.95,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2019-07',
                                                        value: 0.88,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2019-07',
                                                        value: 0.85,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2019-09',
                                                        value: 0.78,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2019-09',
                                                        value: 0.75,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2019-11',
                                                        value: 0.78,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2019-11',
                                                        value: 0.75,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2020-01',
                                                        value: 0.6,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2020-01',
                                                        value: 0.5,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2020-03',
                                                        value: 0.98,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2020-03',
                                                        value: 0.65,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2020-05',
                                                        value: 0.58,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2020-05',
                                                        value: 0.45,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2020-07',
                                                        value: 0.38,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2020-07',
                                                        value: 0.25,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2020-09',
                                                        value: 0.18,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2020-09',
                                                        value: 0.75,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2020-11',
                                                        value: 0.78,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2020-11',
                                                        value: 0.78,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2021-01',
                                                        value: 0.66,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2021-01',
                                                        value: 0.55,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2021-03',
                                                        value: 0.77,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2021-03',
                                                        value: 0.4,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2021-05',
                                                        value: 0.9,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2021-05',
                                                        value: 0.3,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2021-07',
                                                        value: 0.4,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2021-07',
                                                        value: 0.5,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2021-09',
                                                        value: 0.8,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2021-09',
                                                        value: 0.5,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2021-11',
                                                        value: 0.8,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2021-11',
                                                        value: 0.7,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2022-01',
                                                        value: 0.2,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2022-01',
                                                        value: 0.1,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2022-03',
                                                        value: 0.78,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2022-03',
                                                        value: 0.75,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2022-05',
                                                        value: 0.78,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2022-05',
                                                        value: 0.75,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2022-07',
                                                        value: 0.35,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2022-07',
                                                        value: 0.25,
                                                        category: 'Talhão',
                                                    },
                                                    {
                                                        year: '2022-09',
                                                        value: 0.85,
                                                        category: 'Região',
                                                    },
                                                    {
                                                        year: '2022-09',
                                                        value: 0.5,
                                                        category: 'Talhão',
                                                    },
                                                ];

                                                return (
                                                    <OneChart
                                                        key={detail.id}
                                                        {...detail}
                                                    />
                                                );

                                            case 'multiple_charts':
                                                // mock to test dynamic report
                                                detail['items'] = [
                                                    {
                                                        titleChart:
                                                            'Productivity - Soybean',
                                                        typeChart:
                                                            'groupedColumnPlot',
                                                        data: [
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2020/21',
                                                                value: 61,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2020/21',
                                                                value: 54,
                                                            },
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2021/22',
                                                                value: 64,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2021/22',
                                                                value: 59,
                                                            },
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2022/23',
                                                                value: 65,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2022/23',
                                                                value: 61,
                                                            },
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2023/24 (Forecast)',
                                                                value: 65,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2023/24 (Forecast)',
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        titleChart:
                                                            'Productivity - Corn',
                                                        typeChart:
                                                            'groupedColumnPlot',
                                                        data: [
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2020/21',
                                                                value: 61,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2020/21',
                                                                value: 54,
                                                            },
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2021/22',
                                                                value: 64,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2021/22',
                                                                value: 59,
                                                            },
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2022/23',
                                                                value: 65,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2022/23',
                                                                value: 61,
                                                            },
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2023/24 (Forecast)',
                                                                value: 65,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2023/24 (Forecast)',
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        titleChart:
                                                            'Productivity - Cotton',
                                                        typeChart:
                                                            'groupedColumnPlot',
                                                        data: [
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2020/21',
                                                                value: 61,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2020/21',
                                                                value: 54,
                                                            },
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2021/22',
                                                                value: 64,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2021/22',
                                                                value: 59,
                                                            },
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2022/23',
                                                                value: 65,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2022/23',
                                                                value: 61,
                                                            },
                                                            {
                                                                name: 'Soybean in bags/hectare',
                                                                year: '2023/24 (Forecast)',
                                                                value: 65,
                                                            },
                                                            {
                                                                name: 'Soybean (CONAB) in bags/hectare',
                                                                year: '2023/24 (Forecast)',
                                                            },
                                                        ],
                                                    },
                                                ];

                                                return (
                                                    <MultipleCharts
                                                        key={detail.id}
                                                        {...detail}
                                                    />
                                                );

                                            case 'image_gallery':
                                                // mock to test dynamic report
                                                detail['items'] = [
                                                    {
                                                        label: 'Farm',
                                                        image: 'https://dev-agrifin.eisbach-partners.com/static/media/farm-basic-1.ebde6c609f3616603043.png',
                                                    },
                                                    {
                                                        label: 'Farm Size in ha',
                                                        image: 'https://dev-agrifin.eisbach-partners.com/static/media/farm-basic-2.e028e13814af4bc35bb7.png',
                                                    },
                                                    {
                                                        label: 'Cotton in ha',
                                                        image: 'https://dev-agrifin.eisbach-partners.com/static/media/farm-basic-3.b76f6705597789ad2474.png',
                                                    },
                                                ];
                                                detail['legend'] =
                                                    'Images taken on the farm by AgriFin employees. Machinery, Crop Processing, farm buildings including silos (left to right)';

                                                return (
                                                    <ImageGallery
                                                        key={detail.id}
                                                        {...detail}
                                                    />
                                                );

                                            case 'map':
                                                // mock to test dynamic report
                                                detail['coordinates'] = [
                                                    -7.229801, -44.523826,
                                                ];

                                                return (
                                                    <Map
                                                        key={detail.id}
                                                        {...detail}
                                                    />
                                                );

                                            case 'multiple_input_value':
                                                // mock to test dynamic report
                                                detail['items'] = [
                                                    {
                                                        label: 'Total Arable Land in ha',
                                                        value: '12,601',
                                                    },
                                                    {
                                                        label: 'Farm Size in ha',
                                                        value: '12,601',
                                                    },
                                                    {
                                                        label: 'Cotton in ha',
                                                        value: '3,367',
                                                    },
                                                ];
                                                return (
                                                    <MultipleInputValue
                                                        key={detail.id}
                                                        {...detail}
                                                    />
                                                );

                                            default:
                                                return null;
                                        }
                                    })}
                                </>
                            }
                        />
                    );
                })}
            </div>
        </div>
    );
}
