import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    notification,
    Radio,
    Row,
    Select,
    Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
    InfoCircleOutlined,
    MinusCircleOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import FooterCreditApplicationForm from './Components/FooterCreditApplicationForm';
import HeaderCardCreditApplication from './Components/HeaderCardCreditApplication';
import { useNavigate, useParams } from 'react-router-dom';
import CreditApplicationRepository from './CreditApplicationRepository';

export default function CreditApplication1of5() {
    const { t } = useTranslation(['site']);
    const [companyType, setCompanyType] = useState('');
    const [initialParameters, setInitialParameters] = useState({
        company_owners: [''],
    });
    const navigate = useNavigate();
    const { applicationId } = useParams();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (applicationId != 'new') {
            console.log('load data from another creditApplication');
        } else {
            console.log('it is a new application, welcome!');
        }
    }, []);

    const onFinish = async (values) => {
        // when finishing with success, it'll redirect the user to the next form
        // including the application id in the url
        const item = await CreditApplicationRepository.create(values);

        if (item) {
            api.success({
                message: 'Item created',
                description: 'Item successfully created',
            });

            navigate(`/app/credit-applications/${item.id}/2-of-5`);
        }
    };

    const onChangeCompanyType = (value) => {
        setCompanyType(value);
    };

    return (
        <Card bordered={false} className="form form-card">
            {contextHolder}

            <HeaderCardCreditApplication
                title={t('site:ca1_5_title')}
                step="1/4"
            />

            <Form onFinish={onFinish} initialValues={initialParameters}>
                <p className="text-large pt32 pb16 display-flex align-items-center">
                    {t('site:ca1_5_typeTitle')}
                    <Tooltip
                        className="pl8"
                        title={t('site:ca1_5_typeTitleTooltip')}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>
                <Form.Item
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: t('site:ca1_5_profile'),
                        },
                    ]}
                >
                    <Radio.Group
                        options={[
                            {
                                label: t('site:ca1_5_profileFarmOwner'),
                                value: 'individual',
                                checked: true,
                                defaultChecked: true,
                            },
                            {
                                label: t('site:ca1_5_profileRepresentative'),
                                value: 'company',
                            },
                        ]}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </Form.Item>

                <p className="text-large pt16 pb16 display-flex align-items-center">
                    {t('site:ca1_5_ownershipTitle')}
                    <Tooltip
                        className="pl8"
                        title={t('site:ca1_5_ownershipTitleTooltip')}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="company_type"
                            rules={[
                                {
                                    required: true,
                                    message: t('site:ca1_5_companyTypeMessage'),
                                },
                            ]}
                        >
                            <Select
                                size="large"
                                placeholder={t('site:ca1_5_companyType')}
                                options={[
                                    {
                                        value: 'individual',
                                        label: t(
                                            'site:ca1_5_companyTypeIndividual',
                                        ),
                                    },
                                    {
                                        value: 'limited',
                                        label: t(
                                            'site:ca1_5_companyTypeLimited',
                                        ),
                                    },
                                    {
                                        value: 'partnership',
                                        label: t(
                                            'site:ca1_5_companyTypePartnership',
                                        ),
                                    },
                                    {
                                        value: 'cooperative',
                                        label: t(
                                            'site:ca1_5_companyTypeCooperative',
                                        ),
                                    },
                                    {
                                        value: 'other',
                                        label: t('site:ca1_5_companyTypeOther'),
                                    },
                                ]}
                                onChange={onChangeCompanyType}
                                value={companyType}
                            />
                        </Form.Item>
                    </Col>
                    {companyType !== '' && companyType !== 'individual' && (
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: t('site:ca1_5_cnpjMessage'),
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete="one-time-code"
                                    size="large"
                                    placeholder={t('site:ca1_5_cnpj')}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>

                <p className="text-medium pb8">{t('site:ca1_5_ownersList')}</p>
                <Form.List name="company_owners">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row key={key} gutter={24}>
                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(
                                                        'site:ca1_5_ownersListNameMessage',
                                                    ),
                                                },
                                            ]}
                                        >
                                            <Input
                                                autoComplete="one-time-code"
                                                size="large"
                                                placeholder={t(
                                                    'site:ca1_5_ownersListName',
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={7}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'shares']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(
                                                        'site:ca1_5_ownersListShareMessage',
                                                    ),
                                                },
                                            ]}
                                        >
                                            <Input
                                                autoComplete="one-time-code"
                                                size="large"
                                                placeholder={t(
                                                    'site:ca1_5_ownersListShare',
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={7}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'cpf']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(
                                                        'site:ca1_5_ownersListCPFMessage',
                                                    ),
                                                },
                                            ]}
                                        >
                                            <Input
                                                autoComplete="one-time-code"
                                                size="large"
                                                placeholder={t(
                                                    'site:ca1_5_ownersListCPF',
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={2} className="pt16 remove">
                                        <MinusCircleOutlined
                                            style={{ fontSize: '25px' }}
                                            onClick={() => remove(name)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                            <Row>
                                <Col xs={24} md={24}>
                                    <Form.Item className="display-flex justify-content-end">
                                        <Button
                                            icon={
                                                <UserAddOutlined
                                                    style={{
                                                        fontSize: '24px',
                                                        color: '#fff',
                                                    }}
                                                />
                                            }
                                            type="primary"
                                            size="large"
                                            onClick={() => add()}
                                        >
                                            {t('site:ca1_5_ownersListAdd')}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>

                <p className="text-large pt16 pb24 display-flex align-items-center">
                    {t('site:ca1_5_typeFinance')}
                    <Tooltip
                        className="pl8"
                        title={t('site:ca1_5_typeFinanceTooltip')}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>
                <Form.Item
                    name="type_of_contract"
                    rules={[
                        {
                            required: true,
                            message: t('site:ca1_5_typeFinanceMessage'),
                        },
                    ]}
                >
                    <Checkbox.Group
                        className="checkbox-button"
                        options={[
                            {
                                label: t('site:ca1_5_typeFinanceFertilizer'),
                                value: 'fertilizer',
                            },
                            {
                                label: t('site:ca1_5_typeFinanceSeeds'),
                                value: 'seeds',
                            },
                            {
                                label: t('site:ca1_5_typeFinanceCrop'),
                                value: 'cropProtection',
                            },
                        ]}
                    />
                </Form.Item>

                <p className="text-large pt16 pb24">
                    {t('site:ca1_5_additional')}
                </p>
                <Row gutter={24}>
                    <Col xs={24} md={12} className="pb16">
                        <Form.Item
                            name="crop_type"
                            rules={[
                                {
                                    required: true,
                                    message: t('site:ca1_5_cropTypeMessage'),
                                },
                            ]}
                        >
                            <Select
                                size="large"
                                placeholder={t('site:ca1_5_cropType')}
                                options={[
                                    {
                                        value: 'soybean',
                                        label: t('site:ca1_5_cropTypeSoybean'),
                                    },
                                    {
                                        value: 'corn',
                                        label: t('site:ca1_5_cropTypeCorn'),
                                    },
                                    {
                                        value: 'cotton',
                                        label: t('site:ca1_5_cropTypeCotton'),
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} className="pb16">
                        <Form.Item
                            name="season"
                            rules={[
                                {
                                    required: true,
                                    message: t('site:ca1_5_seasonMessage'),
                                },
                            ]}
                        >
                            <Select
                                size="large"
                                placeholder={t('site:ca1_5_season')}
                                options={[
                                    {
                                        value: '2023/2024',
                                        label: '2023/2024',
                                    },
                                    {
                                        value: '2024/2025',
                                        label: '2024/2025',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="new_loan_amount"
                            /* credit_amount_needed */
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'site:ca1_5_creditAmountMessage',
                                    ),
                                },
                            ]}
                        >
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca1_5_creditAmount')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} className="pb16">
                        <Form.Item
                            name="loan_term"
                            // financing_period
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'site:ca1_5_financingPeriodMessage',
                                    ),
                                },
                            ]}
                        >
                            <Select
                                size="large"
                                placeholder={t('site:ca1_5_financingPeriod')}
                                options={[
                                    {
                                        value: '6',
                                        label: t('site:ca1_5_financingPeriod6'),
                                    },
                                    {
                                        value: '12',
                                        label: t(
                                            'site:ca1_5_financingPeriod12',
                                        ),
                                    },
                                    {
                                        value: '18',
                                        label: t(
                                            'site:ca1_5_financingPeriod18',
                                        ),
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="input_seller"
                            rules={[
                                {
                                    message: t('site:ca1_5_sellerMessage'),
                                },
                            ]}
                        >
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca1_5_seller')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="off_taker"
                            rules={[
                                {
                                    message: t('site:ca1_5_offTakerMessage'),
                                },
                            ]}
                        >
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca1_5_offTaker')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <footer>
                    <Row gutter={24}>
                        <Col xs={24} md={6}>
                            <Button
                                className="mt16"
                                block
                                size="large"
                                onClick={() => navigate(-1)}
                            >
                                {t('site:ca_back')}
                            </Button>
                        </Col>

                        <Col xs={24} md={12}>
                            <Button
                                className="mt16"
                                block
                                type="primary"
                                size="large"
                                htmlType="submit"
                            >
                                {t('site:ca_next')}
                            </Button>
                        </Col>
                    </Row>
                </footer>

                {/*<FooterCreditApplicationForm nextStep={2} /> */}
            </Form>
        </Card>
    );
}
