import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    Radio,
    Row,
    Tooltip,
} from 'antd';
import BadgeScore from '../../Components/BadgeScore/BadgeScore';
import BlockInfo from '../../Admin/Components/BlockInfo/BlockInfo';
import Badge from '../../Admin/Components/Badge/Badge';
import ProgressBarCustom from '../../Admin/Components/ProgressBarCustom/ProgressBarCustom';
import ReportCard from '../../Admin/Components/Report/ReportCard';
import ProgressBar from '../../Components/ProgressBar/ProgressBar';
import ProgressCircle from '../../Components/ProgressCircle/ProgressCircle';
import {
    InfoCircleOutlined,
    MinusCircleOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ChartMultiLine from '../../Admin/Components/ChartMultiLine/ChartMultiLine';
import ChartGroupedColumnPlot from '../../Admin/Components/ChartGroupedColumnPlot/ChartGroupedColumnPlot';

export default function StyleGuide() {
    const { t } = useTranslation(['site']);

    const dataChartGroupedColumnPlot = [
        {
            name: 'Soybean in bags/hectare',
            year: '2020/21',
            value: 61,
        },
        {
            name: 'Soybean (CONAB) in bags/hectare',
            year: '2020/21',
            value: 54,
        },
        {
            name: 'Soybean in bags/hectare',
            year: '2021/22',
            value: 64,
        },
        {
            name: 'Soybean (CONAB) in bags/hectare',
            year: '2021/22',
            value: 59,
        },
        {
            name: 'Soybean in bags/hectare',
            year: '2022/23',
            value: 65,
        },
        {
            name: 'Soybean (CONAB) in bags/hectare',
            year: '2022/23',
            value: 61,
        },
        {
            name: 'Soybean in bags/hectare',
            year: '2023/24 (Forecast)',
            value: 65,
        },
        {
            name: 'Soybean (CONAB) in bags/hectare',
            year: '2023/24 (Forecast)',
        },
    ];

    const dataChartMultiLine = [
        {
            year: '2019-01',
            value: 0.6,
            category: 'Região',
        },
        {
            year: '2019-01',
            value: 0.5,
            category: 'Talhão',
        },
        {
            year: '2019-03',
            value: 0.78,
            category: 'Região',
        },
        {
            year: '2019-03',
            value: 0.95,
            category: 'Talhão',
        },
        {
            year: '2019-05',
            value: 0.98,
            category: 'Região',
        },
        {
            year: '2019-05',
            value: 0.95,
            category: 'Talhão',
        },
        {
            year: '2019-07',
            value: 0.88,
            category: 'Região',
        },
        {
            year: '2019-07',
            value: 0.85,
            category: 'Talhão',
        },
        {
            year: '2019-09',
            value: 0.78,
            category: 'Região',
        },
        {
            year: '2019-09',
            value: 0.75,
            category: 'Talhão',
        },
        {
            year: '2019-11',
            value: 0.78,
            category: 'Região',
        },
        {
            year: '2019-11',
            value: 0.75,
            category: 'Talhão',
        },
        {
            year: '2020-01',
            value: 0.6,
            category: 'Região',
        },
        {
            year: '2020-01',
            value: 0.5,
            category: 'Talhão',
        },
        {
            year: '2020-03',
            value: 0.98,
            category: 'Região',
        },
        {
            year: '2020-03',
            value: 0.65,
            category: 'Talhão',
        },
        {
            year: '2020-05',
            value: 0.58,
            category: 'Região',
        },
        {
            year: '2020-05',
            value: 0.45,
            category: 'Talhão',
        },
        {
            year: '2020-07',
            value: 0.38,
            category: 'Região',
        },
        {
            year: '2020-07',
            value: 0.25,
            category: 'Talhão',
        },
        {
            year: '2020-09',
            value: 0.18,
            category: 'Região',
        },
        {
            year: '2020-09',
            value: 0.75,
            category: 'Talhão',
        },
        {
            year: '2020-11',
            value: 0.78,
            category: 'Região',
        },
        {
            year: '2020-11',
            value: 0.78,
            category: 'Talhão',
        },
        {
            year: '2021-01',
            value: 0.66,
            category: 'Região',
        },
        {
            year: '2021-01',
            value: 0.55,
            category: 'Talhão',
        },
        {
            year: '2021-03',
            value: 0.77,
            category: 'Região',
        },
        {
            year: '2021-03',
            value: 0.4,
            category: 'Talhão',
        },
        {
            year: '2021-05',
            value: 0.9,
            category: 'Região',
        },
        {
            year: '2021-05',
            value: 0.3,
            category: 'Talhão',
        },
        {
            year: '2021-07',
            value: 0.4,
            category: 'Região',
        },
        {
            year: '2021-07',
            value: 0.5,
            category: 'Talhão',
        },
        {
            year: '2021-09',
            value: 0.8,
            category: 'Região',
        },
        {
            year: '2021-09',
            value: 0.5,
            category: 'Talhão',
        },
        {
            year: '2021-11',
            value: 0.8,
            category: 'Região',
        },
        {
            year: '2021-11',
            value: 0.7,
            category: 'Talhão',
        },
        {
            year: '2022-01',
            value: 0.2,
            category: 'Região',
        },
        {
            year: '2022-01',
            value: 0.1,
            category: 'Talhão',
        },
        {
            year: '2022-03',
            value: 0.78,
            category: 'Região',
        },
        {
            year: '2022-03',
            value: 0.75,
            category: 'Talhão',
        },
        {
            year: '2022-05',
            value: 0.78,
            category: 'Região',
        },
        {
            year: '2022-05',
            value: 0.75,
            category: 'Talhão',
        },
        {
            year: '2022-07',
            value: 0.35,
            category: 'Região',
        },
        {
            year: '2022-07',
            value: 0.25,
            category: 'Talhão',
        },
        {
            year: '2022-09',
            value: 0.85,
            category: 'Região',
        },
        {
            year: '2022-09',
            value: 0.5,
            category: 'Talhão',
        },
    ];

    return (
        <>
            <h1 className="pb16">Style Guide</h1>
            <Row gutter={16}>
                <Col span={7}>
                    <Card bordered={false} className="mt16">
                        <h2 className="pb16">Typography</h2>
                        <h2 className="pt16">- Fonts</h2>
                        <p className="text-large font-primary">Roboto font</p>
                        <p className="text-large font-secondary">
                            Sansation font
                        </p>

                        <h2 className="pt32">- Titles</h2>
                        <h1>Title H1</h1>
                        <h2 className="pt8">Title H2</h2>
                        <h3 className="pt8">Title H3</h3>

                        <h2 className="pt32">- Texts</h2>
                        <p className=" text-large">text large, "text-large"</p>
                        <p className="pt8 text-medium">
                            text medium size, "text-medium"
                        </p>
                        <p className="pt8 text-small">
                            text small size, "text-small"
                        </p>
                    </Card>
                    <Card bordered={false} className="mt16">
                        <h2 className="pb8">Tooltip</h2>
                        <p className="display-flex align-items-center">
                            <span>Default tooltip with Icon</span>
                            <Tooltip
                                className="pl8"
                                title="Tooltip Default tooltip with Icon"
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </p>
                    </Card>
                    <Card bordered={false} className="mt16">
                        <h2 className="pb8">Report table</h2>
                        <BlockInfo
                            items={[
                                { label: 'Loan amount', value: '$ 2,000,000' },
                                { label: 'Cashflow', value: '$ 5,660,159' },
                                { label: 'Guarantee', value: '$ 4,119,792' },
                            ]}
                        />
                        <BlockInfo
                            items={[
                                { label: 'Loan amount', value: '$ 2,000,000' },
                                { label: 'Cashflow', value: '$ 5,660,159' },
                            ]}
                            size="large"
                        />
                    </Card>
                    <Card bordered={false} className="mt16">
                        <h2>Progress Scale Circle</h2>
                        <div className="display-flex">
                            <div className="pr16">
                                <p className="text-medium pt8 pb8">
                                    Score = 95
                                </p>
                                <ProgressCircle value={95} />
                            </div>
                            <div className="pr16">
                                <p className="text-medium pt8 pb8">
                                    Score = 85
                                </p>
                                <ProgressCircle value={85} />
                            </div>
                            <div className="pr16">
                                <p className="text-medium pt8 pb8">
                                    Score = 78
                                </p>
                                <ProgressCircle value={78} />
                            </div>
                        </div>
                        <div className="display-flex pt16">
                            <div className="pr16">
                                <p className="text-medium pt8 pb8">
                                    Score = 67
                                </p>
                                <ProgressCircle value={67} />
                            </div>
                            <div className="pr16">
                                <p className="text-medium pt8 pb8">
                                    Score = 58
                                </p>
                                <ProgressCircle value={58} />
                            </div>
                            <div className="pr16">
                                <p className="text-medium pt8 pb8">
                                    Score = 49
                                </p>
                                <ProgressCircle value={49} />
                            </div>
                        </div>
                        <div className="display-flex pt16">
                            <div className="pr16">
                                <p className="text-medium pt8 pb8">
                                    Score = 32
                                </p>
                                <ProgressCircle value={32} />
                            </div>
                            <div className="pr16">
                                <p className="text-medium pt8 pb8">
                                    Score = 14
                                </p>
                                <ProgressCircle value={14} />
                            </div>
                            <div className="pr16">
                                <p className="text-medium pt8 pb8">
                                    Score = OK
                                </p>
                                <ProgressCircle value="OK" />
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col span={13}>
                    <Card bordered={false} className="form form-card mt16">
                        <Form initialValues={{ owners: [''] }}>
                            <p className="text-large pb8">
                                Radio button selection
                            </p>
                            <Form.Item
                                name="profile"
                                rules={[
                                    {
                                        required: true,
                                        message: t('site:ca1_5_profile'),
                                    },
                                ]}
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: t(
                                                'site:ca1_5_profileFarmOwner',
                                            ),
                                            value: 'individual',
                                            checked: true,
                                            defaultChecked: true,
                                        },
                                        {
                                            label: t(
                                                'site:ca1_5_profileRepresentative',
                                            ),
                                            value: 'company',
                                        },
                                    ]}
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </Form.Item>

                            <p className="text-large pt8 pb8">
                                Checkbox button for multi selection
                            </p>
                            <Form.Item
                                name="typeFinance"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'site:ca1_5_typeFinanceMessage',
                                        ),
                                    },
                                ]}
                            >
                                <Checkbox.Group
                                    className="checkbox-button"
                                    options={[
                                        {
                                            label: t(
                                                'site:ca1_5_typeFinanceFertilizer',
                                            ),
                                            value: 'fertilizer',
                                        },
                                        {
                                            label: t(
                                                'site:ca1_5_typeFinanceSeeds',
                                            ),
                                            value: 'seeds',
                                        },
                                        {
                                            label: t(
                                                'site:ca1_5_typeFinanceCrop',
                                            ),
                                            value: 'cropProtection',
                                        },
                                    ]}
                                />
                            </Form.Item>

                            <p className="text-large pb8 pt8">
                                Form with dynamic fields
                            </p>
                            <p className="text-medium pb8">
                                {t('site:ca1_5_ownersList')}
                            </p>
                            <Form.List name="owners">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(
                                            ({ key, name, ...restField }) => (
                                                <Row key={key} gutter={24}>
                                                    <Col xs={24} md={8}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                'name',
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        'site:ca1_5_ownersListNameMessage',
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                size="large"
                                                                placeholder={t(
                                                                    'site:ca1_5_ownersListName',
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={24} md={7}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                'share_in',
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        'site:ca1_5_ownersListShareMessage',
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                size="large"
                                                                placeholder={t(
                                                                    'site:ca1_5_ownersListShare',
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={24} md={7}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'cpf']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        'site:ca1_5_ownersListCPFMessage',
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                size="large"
                                                                placeholder={t(
                                                                    'site:ca1_5_ownersListCPF',
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={24}
                                                        md={2}
                                                        className="pt16 remove"
                                                    >
                                                        <MinusCircleOutlined
                                                            style={{
                                                                fontSize:
                                                                    '25px',
                                                            }}
                                                            onClick={() =>
                                                                remove(name)
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            ),
                                        )}
                                        <Row>
                                            <Col xs={24} md={24}>
                                                <Form.Item className="display-flex justify-content-end">
                                                    <Button
                                                        icon={
                                                            <UserAddOutlined
                                                                style={{
                                                                    fontSize:
                                                                        '24px',
                                                                    color: '#fff',
                                                                }}
                                                            />
                                                        }
                                                        type="primary"
                                                        size="large"
                                                        onClick={() => add()}
                                                    >
                                                        {t(
                                                            'site:ca1_5_ownersListAdd',
                                                        )}
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Form.List>
                        </Form>
                    </Card>
                    <Card bordered={false} className="mt16">
                        <h2>Progress Scale Bar</h2>
                        <p className="text-large pt16">Small Size:</p>
                        <ProgressBar value={1} size="small" />
                        <ProgressBar value={2500} size="small" />
                        <ProgressBar value={5000} size="small" />
                        <ProgressBar value={7500} size="small" />
                        <ProgressBar value={10000} size="small" />

                        <p className="text-large pt16">Large Size:</p>
                        <p className="text-medium pt8 pb8">Score = 1</p>
                        <ProgressBar
                            value={1}
                            size="large"
                            label="Very bad chances"
                        />
                        <p className="text-medium pt8 pb8">Score = 2500</p>
                        <ProgressBar
                            value={2500}
                            size="large"
                            label="Bad chances"
                        />
                        <p className="text-medium pt8 pb8">Score = 5000</p>
                        <ProgressBar
                            value={5000}
                            size="large"
                            label="Medium chances"
                        />
                        <p className="text-medium pt8 pb8">Score = 7500</p>
                        <ProgressBar
                            value={7500}
                            size="large"
                            label="Good chances"
                        />
                        <p className="text-medium pt8 pb8">Score = 10000</p>
                        <ProgressBar
                            value={10000}
                            size="large"
                            label="Excelent chances"
                        />
                    </Card>
                    <Card bordered={false} className="mt16">
                        <h2>Report Card Collapsible with bullet list</h2>
                        <div className="card-list">
                            <ReportCard
                                title={'Farm Financials'}
                                items={[
                                    {
                                        label: 'Free Cash Flow from Crop Production - Current Year',
                                        value: '18% of crop-related revenue',
                                    },
                                    {
                                        label: 'Expected Free Cash Flow from Crop Production - Next Year',
                                        value: '8%',
                                        type: 'warning',
                                    },
                                    {
                                        label: 'Total Debt / Total Land Assets',
                                        value: '11%',
                                    },
                                    {
                                        label: 'Debt / Operational Result',
                                        value: '1.4x',
                                        type: 'warning',
                                    },
                                    {
                                        label: 'Operational Result / (Principal & Interest current year)',
                                        value: '18.1x',
                                    },
                                    {
                                        label: 'Bankruptcy / Chapter 11',
                                        value: 'None',
                                    },
                                ]}
                                badgeText={'73'}
                                collapsedContent={
                                    <>
                                        <div className="block-summary">
                                            <header>
                                                Reasoning for Rating
                                            </header>
                                            <p>
                                                Mid-sized farm with c. USD XXX m
                                                revenues. Farm with solid
                                                operations yielding >10%
                                                remaining cash for current and
                                                next year cycle, further
                                                protected by crop
                                                diversification (soy, corn,
                                                cotton). The loan-to-value ratio
                                                below 25% (11%). The overall
                                                farm debt is at a low level (c.
                                                factor 1.2 of operational result
                                                in (cycle 22/23))
                                            </p>
                                        </div>
                                        <div className="block">
                                            <h4>Financial Numbers</h4>
                                            <Row>
                                                <BlockInfo
                                                    items={[
                                                        {
                                                            label: 'Total Revenue',
                                                            value: '$ 32,143,215',
                                                        },
                                                    ]}
                                                />
                                                <BlockInfo
                                                    items={[
                                                        {
                                                            label: 'Total Revenue per ha',
                                                            value: '$ 2,876',
                                                        },
                                                    ]}
                                                />
                                                <BlockInfo
                                                    items={[
                                                        {
                                                            label: 'Operational Result',
                                                            value: '$ 8,042,956',
                                                        },
                                                    ]}
                                                />
                                            </Row>
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    </Card>
                </Col>
                <Col span={4}>
                    <Card bordered={false} className="mt16">
                        <h2 className="pb8">Badges</h2>
                        <div className="pb8">
                            <Badge value={95} />
                        </div>
                        <div className="pb8">
                            <Badge value={85} />
                        </div>
                        <div className="pb8">
                            <Badge value={78} />
                        </div>
                        <div className="pb8">
                            <Badge value={67} />
                        </div>
                        <div className="pb8">
                            <Badge value={58} />
                        </div>
                        <div className="pb8">
                            <Badge value={49} />
                        </div>
                        <div className="pb8">
                            <Badge value={32} />
                        </div>
                        <div className="pb8">
                            <Badge value={14} />
                        </div>
                        <div className="pb8">
                            <Badge value="ok" />
                        </div>
                        <div className="pb8">
                            <Badge value="not ok" />
                        </div>
                        <div className="pb8">
                            <Badge value="-" />
                        </div>
                    </Card>
                    <Card bordered={false} className="mt16">
                        <h2 className="pb8">Badges Scale</h2>
                        <div className="pb8">
                            <BadgeScore value="-" />
                        </div>
                        <div className="pb8">
                            <BadgeScore value={95} />
                        </div>
                        <div className="pb8">
                            <BadgeScore value={95} />
                        </div>
                        <div className="pb8">
                            <BadgeScore value={78} />
                        </div>
                        <div className="pb8">
                            <BadgeScore value={67} />
                        </div>
                        <div className="pb8">
                            <BadgeScore value={58} />
                        </div>
                        <div className="pb8">
                            <BadgeScore value={49} />
                        </div>
                        <div className="pb8">
                            <BadgeScore value={32} />
                        </div>
                        <div className="pb8">
                            <BadgeScore value={14} />
                        </div>
                    </Card>
                </Col>
            </Row>

            <h2 className="pb16 pt32">Charts</h2>
            <Row gutter={16}>
                <Col span={24}>
                    <Card bordered={false} className="mt16">
                        <ChartMultiLine
                            data={dataChartMultiLine}
                            title="Charts: Multi Line"
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Card bordered={false} className="mt16">
                        <ChartGroupedColumnPlot
                            data={dataChartGroupedColumnPlot}
                            title="Charts: Grouped column plot"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false} className="mt16">
                        <h2>Progress Scale Bar</h2>
                        <p className="text-large pt8 pb8">Score = 1200</p>
                        <ProgressBarCustom
                            value={1200}
                            label="very bad chances"
                        />
                        <p className="text-large pt8 pb8">Score = 2500</p>
                        <ProgressBarCustom value={2500} label="bad chances" />
                        <p className="text-large pt8 pb8">Score = 5000</p>
                        <ProgressBarCustom value={5000} label="mid chances" />
                        <p className="text-large pt8 pb8">Score = 7500</p>
                        <ProgressBarCustom value={7500} label="good chances" />
                        <p className="text-large pt8 pb8">Score = 8950</p>
                        <ProgressBarCustom
                            value={8950}
                            label="excelent chances"
                        />
                    </Card>
                </Col>
            </Row>
        </>
    );
}
