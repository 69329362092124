import { Link, useParams } from 'react-router-dom';
import List from '../../../Components/List';
import Header from '../../../Components/Header';
import { EditOutlined } from '@ant-design/icons';
import ReportBlockDetailsRepository from './ReportBlockDetailsRepository';

export default function ReportBlockDetailsList() {
    const { reportBlockId } = useParams();

    const columns = [
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
            render: (text) => text,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text) => text,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text) => text,
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className="display-flex align-items-center fs-20">
                    <Link
                        to={`/app/admin/report-blocks/${reportBlockId}/details/${record.id}/edit`}
                        className="ml8"
                    >
                        <EditOutlined />
                    </Link>
                </div>
            ),
            width: 100,
            fixed: 'right',
        },
    ];

    return (
        <>
            <Header
                title="Details"
                subTitle="You can manage report blocks details here"
                button={{
                    to:
                        '/app/admin/report-blocks/' +
                        reportBlockId +
                        '/details/create',
                    label: 'Create detail',
                }}
            />
            <List
                fetchDataMethod={
                    ReportBlockDetailsRepository.setReportBlock(reportBlockId)
                        .list
                }
                columns={columns}
                pageSize={100}
            />
        </>
    );
}
