import { Progress } from 'antd';

/**
 * @param {number} label
 * @param {number|null} value
 * @param {string|null} size
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProgressBar({ value, label = null, size = null }) {
    const convertValue = value / 100;

    let strokeColor;

    if (convertValue <= 25) {
        strokeColor = {
            '0%': '#F00',
            '50%': '#FFA400',
        };
    } else if (convertValue > 25 && convertValue <= 50) {
        strokeColor = {
            '0%': '#F00',
            '45%': '#FFA400',
            '90%': '#FFC100',
        };
    } else {
        strokeColor = {
            '0%': '#F00',
            '25%': '#FFA400',
            '50%': '#FFC100',
            '100%': '#90EA00',
        };
    }

    if (size === 'small') {
        size = ['100%', 10];
    } else if (size === 'medium') {
        size = ['100%', 24];
    } else if (size === 'large') {
        size = ['100%', 80];
    }

    return (
        <>
            {label && <p className="text-large pb8">{label}</p>}
            <Progress
                showInfo={false}
                percent={convertValue}
                strokeColor={strokeColor}
                size={size}
            />
        </>
    );
}
