import Header from '../../Components/Header';
import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import ProgressList from './Components/ProgressList/ProgressList';

export default function ApplicationsFarmerCreate() {
    const { t } = useTranslation(['site']);

    const items1 = [
        {
            title: t('site:admin_applicationsFarmerProgress1'),
            status: 'active',
        },
        {
            title: t('site:admin_applicationsFarmerProgress2'),
            status: 'empty',
        },
        {
            title: t('site:admin_applicationsFarmerProgress3'),
            status: 'empty',
        },
        {
            title: t('site:admin_applicationsFarmerProgress4'),
            status: 'empty',
        },
        {
            title: t('site:admin_applicationsFarmerProgress5'),
            status: 'empty',
        },
    ];

    return (
        <>
            <Header
                title={t('site:admin_applicationsFarmerTitle')}
                subTitle={t('site:admin_applicationsFarmerSubTitle')}
            />
            <Row gutter={24}>
                <Col sm={24} md={12}>
                    <ProgressList
                        title={t('site:admin_applicationsFarmerProgress')}
                        items={items1}
                        button={{
                            title: t(
                                'site:admin_applicationsFarmerProgressBtn',
                            ),
                            url: '/app/credit-applications/new/1-of-5',
                        }}
                        score={{
                            value: 7500,
                            label: 'good chages',
                            title: 'Your Quick Score',
                            tooltip: 'Your Quick Score',
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}
