import { Button, Card, Col, Form, Input, Radio, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import { notification } from 'antd';
import Terms from '../Components/Terms';
import AuthRepository from './AuthRepository';
import useAuth from '../Hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function RegisterPage() {
    const [api, contextHolder] = notification.useNotification();
    const [profile, setProfile] = useState('');
    const { setToken } = useAuth();
    const { t } = useTranslation(['site']);
    const navigate = useNavigate();
    const FARMER = 'individual';
    const ANALYST = 'company';

    const onFinish = async (values) => {
        try {
            const item = await AuthRepository.register(values);
            setToken(item.data.token);
            navigate('/app/admin');
        } catch (e) {
            const error = e.response.data;
            api.error({
                message: `Error`,
                description: error.message,
            });
        }
    };

    const onChangeProfile = ({ target: { value } }) => {
        setProfile(value);
    };

    return (
        <>
            {contextHolder}

            <Card bordered={false} className="form form-card">
                <h1 className="pb16 text-large font-secondary">
                    {t('site:register_title')}
                </h1>
                <p className="display-flex text-large fw-700 pb16">
                    {t('site:register_description')}
                    <Tooltip className="pl8" title={t('site:register_tooltip')}>
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>

                <Form onFinish={onFinish}>
                    <Form.Item name="type" className="pt8">
                        <Radio.Group
                            options={[
                                {
                                    label: t(`site:register_label${FARMER}`),
                                    value: FARMER,
                                },
                                {
                                    label: t(`site:register_label${ANALYST}`),
                                    value: ANALYST,
                                },
                            ]}
                            onChange={onChangeProfile}
                            value={profile}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Form.Item>

                    {profile !== '' && (
                        <>
                            <p className="text-large pb16 pt8">
                                {t('site:register_info' + profile)}
                            </p>
                            <Row gutter={24}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="first_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'site:register_firstNameDescription',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={t(
                                                'site:register_firstName',
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="last_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'site:register_lastNameDescription',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={t(
                                                'site:register_lastName',
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {profile === ANALYST && (
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="company_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t(
                                                        'site:register_companyNameDescription',
                                                    ),
                                                },
                                            ]}
                                        >
                                            <Input
                                                size="large"
                                                placeholder={t(
                                                    'site:register_companyName',
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}

                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'site:register_phoneDescription',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={t(
                                                'site:register_phone',
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                type: 'email',
                                                message: t(
                                                    'site:register_emailDescription',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={t(
                                                'site:register_email',
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="email_confirmation"
                                        dependencies={['email']}
                                        rules={[
                                            {
                                                required: true,
                                                type: 'email',
                                                message: t(
                                                    'site:register_emailConfirmationDescription',
                                                ),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (
                                                        !value ||
                                                        getFieldValue(
                                                            'email',
                                                        ) === value
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            t(
                                                                'site:register_emailsDidntMatch',
                                                            ),
                                                        ),
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={t(
                                                'site:register_confirmEmail',
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                min: 8,
                                                message: t(
                                                    'site:register_passwordMin8Chars',
                                                ),
                                            },
                                            {
                                                required: true,
                                                message: t(
                                                    'site:register_passwordDescription',
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            size="large"
                                            placeholder={t(
                                                'site:register_password',
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="password_confirmation"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'site:register_passwordConfirmationDescription',
                                                ),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (
                                                        !value ||
                                                        getFieldValue(
                                                            'password',
                                                        ) === value
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'The two passwords that you entered do not match',
                                                        ),
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            size="large"
                                            placeholder={t(
                                                'site:register_passwordConfirmation',
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/*To remove "profile === ANALYST" when we complete this part in backend*/}
                            {/*CPF field is to remove for ANALYST*/}
                            {profile === ANALYST && (
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="cpf"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input
                                                size="large"
                                                placeholder="CPF (to remove)"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}

                            {profile === FARMER && (
                                <>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item
                                                name="cpf"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'site:register_cpfDescription',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    size="large"
                                                    placeholder={t(
                                                        'site:register_cpf',
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <p className="display-flex text-large fw-700 pb16">
                                        {t('site:register_codeDescription')}
                                        <Tooltip
                                            className="pl8"
                                            title={t(
                                                'site:register_code_tooltip',
                                            )}
                                        >
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </p>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item name="code">
                                                <Input
                                                    size="large"
                                                    placeholder={t(
                                                        'site:register_code',
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Terms />

                            <Form.Item>
                                <Button
                                    block
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                >
                                    {t('site:register_registerButton')}
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form>
            </Card>
        </>
    );
}
