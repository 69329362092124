import { Checkbox, Form } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Terms() {
    const { t } = useTranslation(['site']);

    return (
        <Form.Item
            name="terms"
            valuePropName="checked"
            rules={[
                {
                    validator: (_, value) =>
                        value
                            ? Promise.resolve()
                            : Promise.reject(new Error(t('site:terms_error'))),
                },
            ]}
        >
            <Checkbox>
                <div className="fs-16 color-green-medium">
                    {t('site:terms_text1')}{' '}
                    <Link
                        to="/terms"
                        target="_blank"
                        className="color-green-medium text-underline"
                    >
                        {t('site:terms_text2')}
                    </Link>
                </div>
            </Checkbox>
        </Form.Item>
    );
}
