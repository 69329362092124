import { useState } from 'react';
import { Card, Col, Row } from 'antd';
import Collapsible from '../Collapsible/Collapsible';
import ProgressCircle from '../../../Components/ProgressCircle/ProgressCircle';
import ListScore from '../ListScore/ListScore';

/**
 * @param {string} title
 * @param [{string: label, string: value, string|null: type}] items
 * @param {string} content
 * @param {string} badgeText
 * @param {string|null} collapsedContent
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReportCard({
    title,
    items,
    content,
    badgeText,
    collapsedContent = null,
}) {
    const [isCollapsed, setIsCollapsed] = useState(false);

    return (
        <Card bordered={false}>
            <Row>
                <Col xs={20} md={22}>
                    <header>{title}</header>
                </Col>
                <Col className="position-absolute right-8">
                    <ProgressCircle value={badgeText} />
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={24}>
                    {content}
                    <ListScore items={items} isCollapsed={isCollapsed} />
                </Col>
            </Row>

            {collapsedContent !== null ? (
                <Collapsible
                    className="full-width"
                    setIsCollapsed={setIsCollapsed}
                >
                    {collapsedContent}
                </Collapsible>
            ) : null}
        </Card>
    );
}
