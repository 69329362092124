import Header from '../../Components/Header';
import React from 'react';
import List from '../../Components/List';
import AccountsRepository from './AccountsRepository';
import { Link } from 'react-router-dom';
import { UserOutlined, EyeOutlined } from '@ant-design/icons';

export default function AccountsList() {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => text,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text) => text,
        },
        {
            title: 'Code (CNPJ/CPF)',
            dataIndex: 'code',
            key: 'code',
            render: (text) => text,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className="display-flex align-items-center fs-20">
                    <Link to={`/app/admin/accounts/${record.id}/latest-score`}>
                        <EyeOutlined />
                    </Link>

                    <Link
                        to={`/app/admin/accounts/${record.id}/users`}
                        className="ml8"
                    >
                        <UserOutlined />
                    </Link>
                </div>
            ),
            width: 100,
            fixed: 'right',
        },
    ];

    return (
        <>
            <Header title="Accounts" subTitle="You can see accounts here" />

            <List
                fetchDataMethod={AccountsRepository.list}
                columns={columns}
                pageSize={100}
            />
        </>
    );
}
