import Header from '../../Components/Header';
import List from '../../Components/List';
import React from 'react';
import ReportRepository from './ReportRepository';

export default function AuthorizationAuditReportList() {
    const columns = [
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (_, data) =>
                data.user.first_name + ' ' + data.user.last_name,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text) => text,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text) => text,
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
            render: (text) => JSON.stringify(text, null, 2),
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => text,
        },
    ];

    return (
        <>
            <Header
                title="ACL Audit Report"
                subTitle="You can see ACL audit report here"
            />
            <List
                fetchDataMethod={ReportRepository.authorizationAudit}
                columns={columns}
                pageSize={100}
            />
        </>
    );
}
