import { useTranslation } from 'react-i18next';
import useLanguage from '../Hooks/useLanguage';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React from 'react';

export default function LanguageSelector() {
    const { i18n } = useTranslation();
    const { t } = useTranslation(['site']);
    const { saveLanguage } = useLanguage();
    let location = useLocation();

    const changeLanguage = (lng) => {
        saveLanguage(lng);
        i18n.changeLanguage(lng);
    };

    const items = [
        {
            key: '1',
            label: (
                <Link
                    className={
                        'display-flex' +
                        (i18n.language === 'en' ? ' active' : '')
                    }
                    to={location.pathname}
                    onClick={() => changeLanguage('en')}
                >
                    <img
                        className="mr8"
                        src="/vendor/flag-icon-css/flags/1x1/us.svg"
                        alt="English (US)"
                        width="16"
                    />
                    <span>English (US)</span>
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link
                    className={
                        'display-flex' +
                        (i18n.language === 'pt-BR' ? ' active' : '')
                    }
                    to={location.pathname}
                    onClick={() => changeLanguage('pt-BR')}
                >
                    <img
                        className="mr8"
                        src="/vendor/flag-icon-css/flags/1x1/br.svg"
                        alt="Português (BR)"
                        width="16"
                    />
                    <span>Português (BR)</span>
                </Link>
            ),
        },
    ];

    return (
        <Dropdown
            menu={{
                items,
            }}
        >
            <div onClick={(e) => e.preventDefault()}>
                <Space>
                    {t('site:menu_language')}
                    <DownOutlined />
                </Space>
            </div>
        </Dropdown>
    );
}
