import './badge-score.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

/**
 * @param {number} value
 * @returns {JSX.Element}
 * @constructor
 */
export default function BadgeScore({ value }) {
    const { t } = useTranslation(['site']);
    let gradeScore = 'A+';

    if (value > 0 && value <= 20) {
        gradeScore = 'E';
    } else if (value > 20 && value <= 40) {
        gradeScore = 'D';
    } else if (value > 40 && value <= 50) {
        gradeScore = 'C';
    } else if (value > 50 && value <= 60) {
        gradeScore = 'C+';
    } else if (value > 60 && value <= 70) {
        gradeScore = 'B';
    } else if (value > 70 && value <= 80) {
        gradeScore = 'B+';
    } else if (value > 80 && value <= 90) {
        gradeScore = 'A';
    } else if (value === '-') {
        gradeScore = '-';
    }

    const gradeClass = classNames({
        'badge-score': true,
        'badge-score--b': gradeScore === 'B' || gradeScore === 'B+',
        'badge-score--c': gradeScore === 'C' || gradeScore === 'C+',
        'badge-score--d': gradeScore === 'D',
        'badge-score--e': gradeScore === 'E',
        'badge-score--empty': value === '-',
    });

    return (
        <div className={gradeClass}>
            <div className="badge-score-item">
                <p>{gradeScore}</p>
                <p> {t('site:badge_grade')}</p>
            </div>
            <div className="badge-score-item">
                <p>{value}</p>
                <p> {t('site:badge_score')}</p>
            </div>
        </div>
    );
}
