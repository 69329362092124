import { Card, Form, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FooterCreditApplicationForm from './Components/FooterCreditApplicationForm';
import HeaderCardCreditApplication from './Components/HeaderCardCreditApplication';
import ProductivityForm from './Components/ProductivityForm';
import { useParams } from 'react-router-dom';
import CreditApplicationRepository from './CreditApplicationRepository';
import http from '../../../Services/HttpService';

export default function CreditApplication3of5() {
    const { t } = useTranslation(['site']);
    const { applicationId } = useParams();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const [api, contextHolder] = notification.useNotification();

    const fetchData = async (source) => {
        const data = await CreditApplicationRepository.showEditForm(
            applicationId,
            source,
        );
        setInitialValues(data);
        setLoading(false);
    };

    useEffect(() => {
        const CancelToken = http.CancelToken;
        const source = CancelToken.source();

        fetchData(source);

        return () => {
            source.cancel('cancelling request');
        };
    }, []);

    const onFinish = async (values) => {
        const item = await CreditApplicationRepository.update(
            applicationId,
            values,
        );

        if (item) {
            api.success({
                message: 'Item updated',
                description: 'Credit Application updated',
            });
        }
    };

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <Card bordered={false} className="form form-card">
            <HeaderCardCreditApplication
                title={t('site:ca3_5_title')}
                step="3/4"
            />

            {contextHolder}

            <Form onFinish={onFinish} initialValues={initialValues}>
                <ProductivityForm type="soybean" required />

                <ProductivityForm type="corn" required />

                <ProductivityForm type="cotton" required />

                <ProductivityForm
                    type="other"
                    select={[
                        {
                            value: 'cropType1',
                            label: t('site:ca3_5_cropType1'),
                        },
                        {
                            value: 'cropType2',
                            label: t('site:ca3_5_cropType2'),
                        },
                        {
                            value: 'cropType3',
                            label: t('site:ca3_5_cropType3'),
                        },
                    ]}
                />

                <FooterCreditApplicationForm
                    applicationId={applicationId}
                    nextStep={4}
                />
            </Form>
        </Card>
    );
}
