import homepage from '../Assets/homepage.webp';

export default function HomePage() {
    return (
        <img
            src={homepage}
            alt="Homepage"
            style={{ position: 'absolute', top: '100px', zIndex: '-1' }}
        />
    );
}
