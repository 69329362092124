import React from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import LayoutSiteAndSystem from '../Components/LayoutSiteAndSystem';
import LayoutAdmin from '../Admin/Components/LayoutAdmin/LayoutAdmin';
import HomePage from '../Site/HomePage';
import LoginPage from '../Auth/LoginPage';
import AboutPage from '../Site/AboutPage';
import ForgotPasswordPage from '../Auth/ForgotPasswordPage';
import PrivateRoutes from './privateRoutes';
import RegisterPage from '../Auth/RegisterPage';
import ErrorPage from '../ErrorPage';
import Terms from '../System/Pages/Terms';
import Report from '../Admin/Pages/Report/Report';
import CreditApplication1of5 from '../System/Pages/CreditApplication/CreditApplication1of5';
import CreditApplication2of5 from '../System/Pages/CreditApplication/CreditApplication2of5';
import CreditApplication3of5 from '../System/Pages/CreditApplication/CreditApplication3of5';
import CreditApplication4of5 from '../System/Pages/CreditApplication/CreditApplication4of5';
import CreditApplication5of5 from '../System/Pages/CreditApplication/CreditApplication5of5';
import LogoutPage from '../Auth/LogoutPage';
import ResetPasswordPage from '../Auth/ResetPasswordPage';
import PermissionsList from '../Admin/Pages/Permissions/PermissionsList';
import RolesList from '../Admin/Pages/Roles/RolesList';
import PermissionsCreate from '../Admin/Pages/Permissions/PermissionsCreate';
import PermissionsEdit from '../Admin/Pages/Permissions/PermissionsEdit';
import RolesCreate from '../Admin/Pages/Roles/RolesCreate';
import RolesEdit from '../Admin/Pages/Roles/RolesEdit';
import AuthorizationAuditReportList from '../Admin/Pages/Reports/AuthorizationAuditReportList';
import StyleGuide from '../System/Pages/StyleGuide';
import AccountsList from '../Admin/Pages/Accounts/AccountsList';
import AccountLatestScore from '../Admin/Pages/Accounts/AccountLatestScore';
import AccountsUsersList from '../Admin/Pages/Accounts/AccountsUsersList';
import UserSettings from '../Admin/Pages/UserSettings/UserSettings';
import UserRemovedPage from '../Admin/Pages/UserSettings/UserRemovedPage';
import UsersList from '../Admin/Pages/Users/UsersList';
import UsersCreate from '../Admin/Pages/Users/UsersCreate';
import UsersEdit from '../Admin/Pages/Users/UsersEdit';
import Portfolio from '../Admin/Pages/Portfolio/Portfolio';
import ApplicationsFarmerCreate from '../Admin/Pages/Applications/ApplicationsFarmerCreate';
import VerifyEmailPage from '../Auth/VerifyEmailPage';
import ReportBlocksList from '../Admin/Pages/ReportBlocks/ReportBlocksList';
import ReportBlocksCreate from '../Admin/Pages/ReportBlocks/ReportBlocksCreate';
import ReportBlocksEdit from '../Admin/Pages/ReportBlocks/ReportBlocksEdit';
import ReportBlockItemsCreate from '../Admin/Pages/ReportBlocks/ReportBlockItems/ReportBlockItemsCreate';
import ReportBlockItemsEdit from '../Admin/Pages/ReportBlocks/ReportBlockItems/ReportBlockItemsEdit';
import ReportBlockDetailsCreate from '../Admin/Pages/ReportBlocks/ReportBlockDetails/ReportBlockDetailsCreate';
import ReportBlockDetailsEdit from '../Admin/Pages/ReportBlocks/ReportBlockDetails/ReportBlockDetailsEdit';
import ApplicationsEdit from '../Admin/Pages/Applications/ApplicationsEdit';
import ApplicationsHistory from '../Admin/Pages/Applications/ApplicationsHistory';
import QuickScoreList from '../Admin/Pages/QuickScore/QuickScoreList';
import QuickScoreCreate from '../Admin/Pages/QuickScore/QuickScoreCreate';
import QuickScoreShow from '../Admin/Pages/QuickScore/QuickScoreShow';
import PrepareData from '../Admin/Pages/PrepareData/PrepareData';
import DynamicReport from '../Admin/Pages/DynamicReport/DynamicReport';
import MonitoringList from '../Admin/Pages/Monitoring/MonitoringList';

const router = createBrowserRouter([
    {
        path: '/',
        element: <LayoutSiteAndSystem />,
        errorElement: <ErrorPage />,
        children: [
            { index: true, element: <HomePage /> },
            { path: 'about', element: <AboutPage /> },
            { path: 'terms', element: <Terms /> },
            { path: 'verify-email/:id/:hash', element: <VerifyEmailPage /> },
            { path: 'user-removed', element: <UserRemovedPage /> },
            {
                path: 'auth',
                children: [
                    { path: 'register', element: <RegisterPage /> },
                    { path: 'login', element: <LoginPage /> },
                    { path: 'logout', element: <LogoutPage /> },
                    {
                        path: 'forgot-password',
                        element: <ForgotPasswordPage />,
                    },
                    {
                        path: 'reset-password/:tokenId',
                        element: <ResetPasswordPage />,
                    },
                ],
            },
            { path: 'style-guide', element: <StyleGuide /> },
        ],
    },
    {
        path: '/app/prepare-data',
        element: <PrepareData />,
        errorElement: <ErrorPage />,
    },
    // we can change the layout here if needed
    // see the Admin dashboard
    {
        path: '/app',
        element: <Outlet />,
        errorElement: <ErrorPage />,
        children: [
            {
                element: <PrivateRoutes />,
                children: [
                    // common dashboard
                    {
                        element: <LayoutSiteAndSystem />,
                        children: [
                            {
                                path: 'credit-applications/:applicationId/1-of-5',
                                element: <CreditApplication1of5 />,
                            },
                            {
                                path: 'credit-applications/:applicationId/2-of-5',
                                element: <CreditApplication2of5 />,
                            },
                            {
                                path: 'credit-applications/:applicationId/3-of-5',
                                element: <CreditApplication3of5 />,
                            },
                            {
                                path: 'credit-applications/:applicationId/4-of-5',
                                element: <CreditApplication4of5 />,
                            },
                            {
                                path: 'credit-applications/:applicationId/5-of-5',
                                element: <CreditApplication5of5 />,
                            },
                        ],
                    },

                    // admin dashboard
                    {
                        element: <LayoutAdmin />,
                        path: 'admin',
                        children: [
                            {
                                path: 'accounts',
                                element: <AccountsList />,
                            },
                            {
                                path: 'accounts/:accountId/latest-score',
                                element: <AccountLatestScore />,
                            },
                            {
                                path: 'accounts/:accountId/users',
                                element: <AccountsUsersList />,
                            },
                            {
                                path: 'report-blocks',
                                element: <ReportBlocksList />,
                            },
                            {
                                path: 'report-blocks/create',
                                element: <ReportBlocksCreate />,
                            },
                            {
                                path: 'report-blocks/:reportBlockId/edit',
                                element: <ReportBlocksEdit />,
                            },
                            {
                                path: 'report-blocks/:reportBlockId/items/create',
                                element: <ReportBlockItemsCreate />,
                            },
                            {
                                path: 'report-blocks/:reportBlockId/items/:reportBlockItemId/edit',
                                element: <ReportBlockItemsEdit />,
                            },
                            {
                                path: 'report-blocks/:reportBlockId/details/create',
                                element: <ReportBlockDetailsCreate />,
                            },
                            {
                                path: 'report-blocks/:reportBlockId/details/:reportBlockDetailId/edit',
                                element: <ReportBlockDetailsEdit />,
                            },
                            {
                                path: 'quick-scores',
                                element: <QuickScoreList />,
                            },
                            {
                                path: 'quick-scores/create',
                                element: <QuickScoreCreate />,
                            },
                            {
                                path: 'quick-scores/:quickScoreId/edit',
                                element: <QuickScoreShow />,
                            },
                            {
                                path: 'applications/create',
                                element: <ApplicationsFarmerCreate />,
                            },
                            {
                                path: 'applications',
                                element: <ApplicationsHistory />,
                            },
                            {
                                path: 'applications/detail',
                                element: <ApplicationsEdit />,
                            },
                            {
                                path: 'users',
                                element: <UsersList />,
                            },
                            {
                                path: 'users/create',
                                element: <UsersCreate />,
                            },
                            {
                                path: 'users/:userId/edit',
                                element: <UsersEdit />,
                            },
                            {
                                path: 'roles',
                                element: <RolesList />,
                            },
                            {
                                path: 'roles/create',
                                element: <RolesCreate />,
                            },
                            {
                                path: 'roles/:roleId/edit',
                                element: <RolesEdit />,
                            },
                            {
                                path: 'permissions',
                                element: <PermissionsList />,
                            },
                            {
                                path: 'permissions/create',
                                element: <PermissionsCreate />,
                            },
                            {
                                path: 'permissions/:permissionId/edit',
                                element: <PermissionsEdit />,
                            },
                            {
                                path: 'reports/report',
                                element: <Report />,
                            },
                            {
                                path: 'reports/dynamic-report',
                                element: <DynamicReport />,
                            },
                            {
                                path: 'reports/authorization-audit',
                                element: <AuthorizationAuditReportList />,
                            },
                            {
                                path: 'portfolio',
                                element: <Portfolio />,
                            },
                            {
                                path: 'monitoring',
                                element: <MonitoringList />,
                            },
                            {
                                path: 'users-settings',
                                element: <UserSettings />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);

export default router;
