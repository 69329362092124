import Header from '../../Components/Header';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Col, Button, Input, notification, Select } from 'antd';
import UsersRepository from './UsersRepository';
import { useTranslation } from 'react-i18next';
import RolesRepository from '../Roles/RolesRepository';
import http from '../../../Services/HttpService';
import qs from 'qs';

export default function UsersCreate() {
    const navigate = useNavigate();
    const { form } = Form.useForm();
    const formRef = React.useRef(null);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const { t } = useTranslation(['site']);
    const [roles, setRoles] = useState();

    const onFinish = async (values) => {
        setButtonLoading(true);

        const item = await UsersRepository.create(values);

        if (item) {
            api.success({
                message: 'Item created',
                description: 'Item successfully created',
            });
        }

        navigate(`/app/admin/users`);
    };

    const fetchRoles = async (source) => {
        const response = await RolesRepository.list(
            qs.stringify({
                has_pagination: false,
            }),
            source,
        );

        setRoles(
            response.map((item) => ({
                value: item.name,
                label: item.name,
            })),
        );

        setLoading(false);
    };

    useEffect(() => {
        let CancelToken = http.CancelToken;
        let source = CancelToken.source();
        fetchRoles(source);
    }, []);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <>
            {contextHolder}

            <Header
                title="Users"
                subTitle="You can create users here"
                button={{ to: '/app/admin/users', label: 'Back' }}
            />

            <Form
                form={form}
                name="users"
                onFinish={onFinish}
                layout="vertical"
                scrollToFirstError
                ref={formRef}
            >
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="first_name"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'site:admin_settings_firstNameDescription',
                                    ),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder={t('site:admin_settings_firstName')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="last_name"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'site:admin_settings_lastNameDescription',
                                    ),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder={t('site:admin_settings_lastName')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'site:admin_settings_phoneDescription',
                                    ),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder={t('site:admin_settings_phone')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item name="email">
                            <Input
                                size="large"
                                placeholder={t('site:admin_settings_email')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="cpf"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'site:admin_settings_cpfDescription',
                                    ),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder={t('site:admin_settings_cpf')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    min: 8,
                                    message: t(
                                        'site:register_passwordMin8Chars',
                                    ),
                                },
                            ]}
                        >
                            <Input.Password
                                size="large"
                                placeholder={t('site:register_password')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="password_confirmation"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue('password') === value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                'The two passwords that you entered do not match',
                                            ),
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                size="large"
                                placeholder={t(
                                    'site:register_passwordConfirmation',
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item
                            name="role"
                            label="Role"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the role',
                                },
                            ]}
                        >
                            <Select size={'large'} options={roles} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button
                        loading={buttonLoading}
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
