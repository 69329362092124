import './block-info.scss';
import classNames from 'classnames';

/**
 * @param [{string: label, string: value}] items
 * @param {string} size
 * @returns {JSX.Element}
 * @constructor
 */
export default function BlockInfo({ items = [], size = null }) {
    const variation = classNames({
        'block-info-item-large': size === 'large',
    });

    return (
        <div className="block-info">
            {items.map((item, index) => (
                <div key={index} className="block-info-item">
                    <p>{item.label}</p>
                    <p className={variation}>{item.value}</p>
                </div>
            ))}
        </div>
    );
}
