import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function OtherBlock() {
    const { t } = useTranslation(['site']);

    return (
        <>
            <p className="text-large pt16 pb16">
                {t('site:admin_quickScore_other')}
            </p>
            <Button block type="primary" size="large" className="mb16">
                <Link
                    to="https://ondemand.risksuite.munichre.com"
                    target="_blank"
                >
                    {t('site:admin_quickScore_other1')}
                    <RightOutlined style={{ color: '#fff' }} />
                </Link>
            </Button>
            <Button block type="primary" size="large" className="mb16">
                <Link
                    to="https://apps.sentinel-hub.com/eo-browser/?zoom=11&lat=-7.54493&lng=-44.97185&themeId=AG[…]59%3A59.999Z&layerId=1_TRUE-COLOR&demSource3D=%22MAPZEN%22"
                    target="_blank"
                >
                    {t('site:admin_quickScore_other2')}
                    <RightOutlined style={{ color: '#fff' }} />
                </Link>
            </Button>
            <Button block type="primary" size="large">
                <Link
                    to="https://www.smartcredit.agr.br/plataforma"
                    target="_blank"
                >
                    {t('site:admin_quickScore_other3')}
                    <RightOutlined style={{ color: '#fff' }} />
                </Link>
            </Button>
        </>
    );
}
