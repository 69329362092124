import { Space } from 'antd';

export default function HeaderCardCreditApplication({ title, step = null }) {
    return (
        <Space
            direction="horizontal"
            align="top"
            style={{ display: 'flex', justifyContent: 'space-between' }}
        >
            <h1 className="pr32">{title}</h1>
            {step && <span className="h1"> {step}</span>}
        </Space>
    );
}
