import Header from '../../Components/Header';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, notification } from 'antd';
import http from '../../../Services/HttpService';
import AccountsRepository from './AccountsRepository';

export default function AccountLatestScore() {
    const { accountId } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [api, contextHolder] = notification.useNotification();

    const fetchData = async (source) => {
        const response = await AccountsRepository.latestScore(
            accountId,
            source,
        );
        setData(response);
        setLoading(false);
    };

    useEffect(() => {
        let CancelToken = http.CancelToken;
        let source = CancelToken.source();

        fetchData(source);
    }, []);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <>
            {contextHolder}

            <Header
                title="Accounts - Latest Score"
                button={{ to: '/app/admin/accounts', label: 'Back' }}
            />

            {data.datasets.map((item) => {
                return (
                    <>
                        <Row>
                            <Col>Status: {item.status}</Col>
                        </Row>
                        <Row>
                            <Col>Type: {item.type}</Col>
                        </Row>

                        {item.results.map((result) => {
                            return (
                                <>
                                    <Row>
                                        <Col>Document: {result.document}</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            Reference Date:{' '}
                                            {result.reference_date}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>Score: {result.score}</Col>
                                    </Row>
                                </>
                            );
                        })}
                    </>
                );
            })}
        </>
    );
}
