import 'leaflet/dist/leaflet.css';
import { Circle, LayersControl, MapContainer, TileLayer } from 'react-leaflet';

/**
 * @param {number} zoom
 * @param {Array} center
 * @param {Array} circles
 * @param {Object} style
 * @returns {JSX.Element}
 * @constructor
 */
export default function MapInteractive({
    zoom = 13,
    center,
    circles = [],
    style,
}) {
    if (!style) {
        style = {
            height: 'calc(100vh - 40em)',
            minHeight: '400px',
            width: '100%',
            minWidth: '200px',
        };
    }

    return (
        <MapContainer style={style} center={center} zoom={zoom}>
            <LayersControl>
                <LayersControl.BaseLayer name="Google Maps">
                    <TileLayer
                        attribution="Google Maps"
                        url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer checked name="Google Maps Satellite">
                    <TileLayer
                        attribution="Google Maps Satellite"
                        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                    />
                </LayersControl.BaseLayer>
            </LayersControl>

            {circles.map((item, index) => (
                <Circle
                    key={index}
                    center={item}
                    radius={750}
                    pathOptions={{ color: '#90EA0082' }}
                />
            ))}
        </MapContainer>
    );
}
