import React from 'react';
import Header from '../../Components/Header';
import { Card, Col, Row } from 'antd';
import BlockInfo from '../../Components/BlockInfo/BlockInfo';
import Badge from '../../Components/Badge/Badge';
import { AlertOutlined } from '@ant-design/icons';
import { Column, Pie } from '@ant-design/plots';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import MapInteractive from '../../Components/Map/MapInteractive';
import ChartMultiLine from '../../Components/ChartMultiLine/ChartMultiLine';

const DemoPie = () => {
    const data = [
        {
            type: 'Cotton',
            value: 33,
        },
        {
            type: 'Corn',
            value: 34,
        },
        {
            type: 'Soybean',
            value: 33,
        },
    ];

    const config = {
        appendPadding: 50,
        data,
        angleField: 'value',
        colorField: 'type',
        legend: {
            position: 'bottom',
        },
        color: ['#FFB200', '#FF3A29', '#2F7346'],
    };

    return <Pie {...config} />;
};

const DemoPie2 = () => {
    const data = [
        {
            type: '1',
            value: 7,
        },
        {
            type: '2',
            value: 26,
        },
        {
            type: '3',
            value: 7,
        },
        {
            type: '4',
            value: 22,
        },
        {
            type: '5',
            value: 38,
        },
    ];

    const config = {
        appendPadding: 30,
        data,
        angleField: 'value',
        colorField: 'type',
        innerRadius: 0.7,
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            },
        },
        color: ['#E31D1C', '#D78D00', '#EAB613', '#9AC842', '#90EA00'],
        legend: false,
        label: {
            content: '{name}\n{percentage}',
        },
    };
    return <Pie {...config} />;
};

const DemoPie3 = () => {
    const data = [
        {
            type: '1',
            value: 7,
        },
        {
            type: '2',
            value: 26,
        },
        {
            type: '3',
            value: 7,
        },
        {
            type: '4',
            value: 22,
        },
        {
            type: '5',
            value: 38,
        },
    ];

    const config = {
        appendPadding: 30,
        data,
        angleField: 'value',
        colorField: 'type',
        innerRadius: 0.7,
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            },
        },
        color: ['#90EA00', '#9AC842', '#90EA00', '#90EA00', '#EAB613'],
        legend: false,
        label: {
            content: '{name}\n{percentage}',
        },
    };
    return <Pie {...config} />;
};

const DemoColumn = () => {
    const data = [
        {
            type: 'D',
            value: 5,
        },
        {
            type: 'C',
            value: 25,
        },
        {
            type: 'C+',
            value: 30,
        },
        {
            type: 'B',
            value: 45,
        },
        {
            type: 'B+',
            value: 80,
        },
        {
            type: 'A',
            value: 90,
        },
        {
            type: 'A+',
            value: 40,
        },
    ];

    const config = {
        data,
        xField: 'type',
        yField: 'value',
        label: false,
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        meta: {
            type: {
                alias: 'scale',
            },
            value: {
                alias: 'total',
            },
        },
        angleField: 'value',
        colorField: 'type',
        color: ({ type }) => {
            if (type === 'D') {
                return '#F45800';
            } else if (type === 'C' || type === 'C+') {
                return '#FFB200';
            } else if (type === 'B' || type === 'B+') {
                return '#E3FCBB';
            }

            return '#90EA00';
        },
    };

    return <Column {...config} />;
};
const DemoColumn2 = () => {
    const data = [
        {
            type: 'Jan',
            value: 2,
        },
        {
            type: 'Fev',
            value: 10,
        },
        {
            type: 'Mar',
            value: 10,
        },
        {
            type: 'Apr',
            value: 15,
        },
        {
            type: 'May',
            value: 25,
        },
        {
            type: 'Jun',
            value: 30,
        },
        {
            type: 'Jul',
            value: 15,
        },
        {
            type: 'Aug',
            value: 13,
        },
        {
            type: 'Sep',
            value: 10,
        },
        {
            type: 'Oct',
            value: 10,
        },
        {
            type: 'Nov',
            value: 5,
        },
        {
            type: 'Dec',
            value: 2,
        },
    ];

    const config = {
        data,
        xField: 'type',
        yField: 'value',
        meta: {
            type: {
                alias: 'scale',
            },
            value: {
                alias: 'total',
            },
        },
        label: false,
        angleField: 'value',
        colorField: 'type',
        color: ({ type }) => {
            if (type === 'Jan' || type === 'Nov' || type === 'Dec') {
                return '#F45800';
            } else if (
                type === 'Fev' ||
                type === 'Mar' ||
                type === 'Sep' ||
                type === 'Oct'
            ) {
                return '#FFB200';
            } else if (type === 'Apr' || type === 'Jul' || type === 'Aug') {
                return '#E3FCBB';
            }

            return '#90EA00';
        },
    };

    return <Column {...config} />;
};

const dataChartMultiLine = [
    {
        year: '2019-01',
        value: 6,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2019-01',
        value: 5,
        category: 'Portfolio',
    },
    {
        year: '2019-03',
        value: 78,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2019-03',
        value: 95,
        category: 'Portfolio',
    },
    {
        year: '2019-05',
        value: 98,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2019-05',
        value: 95,
        category: 'Portfolio',
    },
    {
        year: '2019-07',
        value: 88,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2019-07',
        value: 85,
        category: 'Portfolio',
    },
    {
        year: '2019-09',
        value: 78,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2019-09',
        value: 75,
        category: 'Portfolio',
    },
    {
        year: '2019-11',
        value: 78,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2019-11',
        value: 75,
        category: 'Portfolio',
    },
    {
        year: '2020-01',
        value: 6,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2020-01',
        value: 5,
        category: 'Portfolio',
    },
    {
        year: '2020-03',
        value: 98,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2020-03',
        value: 65,
        category: 'Portfolio',
    },
    {
        year: '2020-05',
        value: 58,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2020-05',
        value: 45,
        category: 'Portfolio',
    },
    {
        year: '2020-07',
        value: 38,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2020-07',
        value: 25,
        category: 'Portfolio',
    },
    {
        year: '2020-09',
        value: 18,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2020-09',
        value: 75,
        category: 'Portfolio',
    },
    {
        year: '2020-11',
        value: 78,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2020-11',
        value: 78,
        category: 'Portfolio',
    },
    {
        year: '2021-01',
        value: 66,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2021-01',
        value: 55,
        category: 'Portfolio',
    },
    {
        year: '2021-03',
        value: 77,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2021-03',
        value: 4,
        category: 'Portfolio',
    },
    {
        year: '2021-05',
        value: 9,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2021-05',
        value: 3,
        category: 'Portfolio',
    },
    {
        year: '2021-07',
        value: 4,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2021-07',
        value: 5,
        category: 'Portfolio',
    },
    {
        year: '2021-09',
        value: 8,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2021-09',
        value: 5,
        category: 'Portfolio',
    },
    {
        year: '2021-11',
        value: 8,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2021-11',
        value: 7,
        category: 'Portfolio',
    },
    {
        year: '2022-01',
        value: 2,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2022-01',
        value: 1,
        category: 'Portfolio',
    },
    {
        year: '2022-03',
        value: 78,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2022-03',
        value: 75,
        category: 'Portfolio',
    },
    {
        year: '2022-05',
        value: 78,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2022-05',
        value: 75,
        category: 'Portfolio',
    },
    {
        year: '2022-07',
        value: 35,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2022-07',
        value: 25,
        category: 'Portfolio',
    },
    {
        year: '2022-09',
        value: 85,
        category: 'Vanguard Total Stock Market Index',
    },
    {
        year: '2022-09',
        value: 5,
        category: 'Portfolio',
    },
];

export default function Portfolio() {
    return (
        <>
            <Header
                title="Your Portfolio at a glance"
                subTitle="Find performance and risk factors of your current investment "
            />
            <Row gutter={24}>
                <Col xs={24} md={8}>
                    <Card bordered={false} className="rounded">
                        <p className="text-large pb16">Overview</p>
                        <Row gutter={0}>
                            <BlockInfo
                                items={[
                                    {
                                        label: 'Total Portfolio Value',
                                        value: '$ 480,112,792',
                                    },
                                ]}
                                size="large"
                            />
                            <BlockInfo
                                items={[
                                    {
                                        label: 'No. of Borrowers ',
                                        value: '83',
                                    },
                                ]}
                                size="large"
                            />
                        </Row>
                    </Card>
                    <Card bordered={false} className="mt24 rounded">
                        <p className="text-large pb16">Farm Diversity</p>
                        <Row gutter={0}>
                            <BlockInfo
                                items={[
                                    {
                                        label: 'Covered Regions',
                                        value: '5',
                                    },
                                ]}
                                size="large"
                            />
                            <BlockInfo
                                items={[
                                    {
                                        label: 'Average Farm Size in ha',
                                        value: '8,002',
                                    },
                                ]}
                                size="large"
                            />
                        </Row>
                        <p className="text-large text-center fw-400 pt16">
                            Crop Diversity
                        </p>
                        <DemoPie />
                    </Card>
                </Col>
                <Col xs={24} md={8}>
                    <Card bordered={false} className="rounded">
                        <p className="text-large pb16">Credit Risk</p>
                        <Row gutter={0}>
                            <BlockInfo
                                items={[
                                    {
                                        label: 'Default Rate',
                                        value: '0.5%',
                                    },
                                ]}
                                size="large"
                            />
                            <BlockInfo
                                items={[
                                    {
                                        label: 'Ongoing Security Enforcements',
                                        value: '2',
                                    },
                                ]}
                                size="large"
                            />
                        </Row>

                        <p className="text-large fw-400 pt16 pb8">
                            Late Payments: 4 (4,8%)
                        </p>
                        <ProgressBar value={9000} size="medium" />

                        <p className="text-large fw-400 pt16 pb16">
                            Grading Distribution
                        </p>
                        <DemoColumn />

                        <div className="pt24">
                            <BlockInfo
                                items={[
                                    {
                                        label: 'Average Credit Risk Score',
                                        value: '79 | B+',
                                    },
                                ]}
                                size="large"
                            />
                        </div>
                    </Card>
                </Col>
                <Col xs={24} md={8}>
                    <Card bordered={false} className="rounded">
                        <p className="text-large pb16">Geo Distribution</p>
                        <Row gutter={24} className="list fs-16">
                            <Col
                                xs={24}
                                md={12}
                                className="list-item list-item--c"
                            >
                                Biodiversity Layer
                            </Col>
                            <Col
                                xs={24}
                                md={12}
                                className="list-item list-item--a"
                            >
                                Farms
                            </Col>
                        </Row>
                        <Row className="pt24">
                            <MapInteractive
                                center={[-7.229801, -44.523826]}
                                circles={[
                                    [-7.239701, -44.523826],
                                    [-7.249801, -44.533826],
                                    [-7.239801, -44.543826],
                                    [-7.251801, -44.513826],
                                    [-7.230801, -44.513826],
                                    [-7.220801, -44.543826],
                                    [-7.220801, -44.553826],
                                ]}
                            />
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} md={8}>
                    <Card bordered={false} className="mt24 rounded">
                        <div className="display-flex align-items-center justify-content-space-between">
                            <p className="text-large">
                                ESG
                                <span className="text-small">
                                    {' '}
                                    (last checked: Sep 23)
                                </span>
                            </p>
                            <Badge value="OK" />
                        </div>
                    </Card>
                    <Card bordered={false} className="mt24 rounded">
                        <p className="text-large">Weather</p>
                        <div className="background-color-champaign p8 mt16">
                            <div className="display-flex justify-content-space-between">
                                <p className="text-large">Monthly Report</p>
                                <AlertOutlined
                                    className="fs-28"
                                    style={{ color: '#444036' }}
                                />
                            </div>
                            <p className="text-small pt16">
                                Regional Warning for strong winds and hail in
                                Piaui, Manaus and Fortress
                            </p>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} md={16}>
                    <Card bordered={false} className="mt24 rounded">
                        <p className="text-large pb16">Financial Performance</p>
                        <BlockInfo
                            items={[
                                {
                                    label: 'Average Credit Value',
                                    value: '$ 1,804,114',
                                },
                            ]}
                            size="large"
                        />
                        <p className="text-large fw-400 pt16">
                            Cummulative Return
                        </p>
                        <ChartMultiLine data={dataChartMultiLine} />

                        <p className="text-large fw-400 pt24 mt16 pb16">
                            Maturity Distribution
                        </p>
                        <DemoColumn2 />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col xs={24} md={24}>
                    <Card bordered={false} className="mt24 rounded">
                        <p className="text-large pb16">Climate Risk Exposure</p>
                        <Row gutter={24}>
                            <Col xs={24} md={6}>
                                <DemoPie2 />
                            </Col>
                            <Col xs={24} md={6}>
                                <DemoPie3 />
                            </Col>
                            <Col xs={24} md={6}>
                                <DemoPie2 />
                            </Col>
                            <Col xs={24} md={6}>
                                <DemoPie2 />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col xs={24} md={6}>
                                <DemoPie2 />
                            </Col>
                            <Col xs={24} md={6}>
                                <DemoPie2 />
                            </Col>
                            <Col xs={24} md={6}>
                                <DemoPie2 />
                            </Col>
                            <Col xs={24} md={6}>
                                <DemoPie2 />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
