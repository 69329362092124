import './badge-status.scss';
import classNames from 'classnames';

/**
 * @param {number|string} value
 * @returns {JSX.Element}
 * @constructor
 */
export default function BadgeStatus({ text }) {
    const statusClass = classNames({
        'badge-status': true,
        'badge-status--new': text === 'new' || text === 'seeding',
        'badge-status--decision': text === 'ready for decision',
        'badge-status--in-progress':
            text === 'in progress' || text === 'growing',
        'badge-status--rejected': text === 'rejected',
        'badge-status--approved':
            text === 'approved' || text === 'done' || text === 'harvesting',
    });

    return (
        <div className={statusClass}>
            <span className="badge-status-item">{text}</span>
        </div>
    );
}
