import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { UserAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CollapsibleEye from './CollapsibleEye';
import GetCurrentYearAndLastThree from '../../../../Admin/Pages/Applications/Utils/GetCurrentYearAndLastThree';

export default function ProductivityForm({ type, required = false }) {
    const { t } = useTranslation(['site']);
    const items = ['Productivity', 'TotalProduction', 'Cost'];
    const {
        currentYear,
        currentYearLessOne,
        currentYearLessTwo,
        currentYearLessThree,
    } = GetCurrentYearAndLastThree();

    return (
        <CollapsibleEye step={3} type={type}>
            <>
                {items.map((item) => (
                    <div key={item}>
                        <p className="text-medium pb8">
                            {t(`site:ca3_5_${type}${item}`)}
                        </p>
                        <Row gutter={24}>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name={`${item}${type}${currentYearLessThree}`}
                                    rules={[
                                        {
                                            required,
                                            message: t('site:ca3_5_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="one-time-code"
                                        size="large"
                                        placeholder={currentYearLessThree}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name={`${item}${type}${currentYearLessTwo}`}
                                    rules={[
                                        {
                                            required,
                                            message: t('site:ca3_5_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="one-time-code"
                                        size="large"
                                        placeholder={currentYearLessTwo}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name={`${item}${type}${currentYearLessOne}`}
                                    rules={[
                                        {
                                            required,
                                            message: t('site:ca3_5_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="one-time-code"
                                        size="large"
                                        placeholder={currentYearLessOne}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item
                                    name={`${item}${type}${currentYear}`}
                                    rules={[
                                        {
                                            required,
                                            message: t('site:ca3_5_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="one-time-code"
                                        size="large"
                                        placeholder={`${t(
                                            'site:ca3_5_forecast',
                                        )} ${currentYear}`}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                ))}

                {type && type === 'other' && (
                    <Row>
                        <Col xs={24} md={24}>
                            <Form.Item className="display-flex justify-content-end">
                                <Button
                                    icon={
                                        <UserAddOutlined
                                            style={{
                                                fontSize: '24px',
                                                color: '#fff',
                                            }}
                                        />
                                    }
                                    type="primary"
                                    size="large"
                                >
                                    {t('site:ca3_5_addCrop')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </>
        </CollapsibleEye>
    );
}
