import { Button, Card, Form, Input, notification } from 'antd';
import AuthRepository from './AuthRepository';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ResetPasswordPage() {
    const [api, contextHolder] = notification.useNotification();
    const { t } = useTranslation(['site']);
    const { tokenId } = useParams();

    const onFinish = async (values) => {
        values['token'] = tokenId;

        try {
            const item = await AuthRepository.resetPassword(values);

            if (undefined !== item.data.errors) {
                api.error({
                    message: `Error`,
                    description: item.data.errors['email'],
                });
            } else {
                api.success({
                    message: `Success`,
                    description: item.data.status,
                });
            }
        } catch (e) {
            const error = e.data;
            api.error({
                message: `Error`,
                description: error.message,
            });
        }
    };

    return (
        <>
            {contextHolder}

            <Card bordered={false} className="form form-card">
                <h1 className="pb16">{t('site:resetPassword_title')}</h1>
                <p className="text-large pb16">
                    {t('site:resetPassword_description')}
                </p>

                <Form onFinish={onFinish}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: t('site:resetPassword_email_message'),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder={t('site:resetPassword_email')}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'site:resetPassword_password_message',
                                ),
                            },
                        ]}
                    >
                        <Input.Password
                            size="large"
                            placeholder={t('site:resetPassword_password')}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password_confirmation"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: t(
                                    'site:resetPassword_password_confirmation_message',
                                ),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            t(
                                                'site:resetPassword_password_confirmation_error',
                                            ),
                                        ),
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            size="large"
                            placeholder={t(
                                'site:resetPassword_password_confirmation',
                            )}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            block
                            type="primary"
                            size="large"
                            htmlType="submit"
                        >
                            {t('site:resetPassword_btn')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
}
