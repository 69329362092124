const unhandledErrors = ['CanceledError', 'TypeError'];

// todo: add future specific handlers for each errors
// const handlers = []

const ExceptionHandler = {
    catch: (err) => {
        if (err instanceof Error) {
            // console.log(err.name, err.message)
            if (!unhandledErrors.includes(err.name)) {
                if (err.name === 'AxiosError') {
                    alert(err.response.data.message);
                } else {
                    alert(err.message);
                }
            }
        }
    },
};

export default ExceptionHandler;
