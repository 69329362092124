import Header from '../../Components/Header';
import React, { useState } from 'react';
import ReportHeader from '../../Components/Report/ReportHeader';
import { Button, Card, Col, Form, Row } from 'antd';
import ProgressCircle from '../../../Components/ProgressCircle/ProgressCircle';
import Badge from '../../Components/Badge/Badge';
import {
    AlertOutlined,
    BookOutlined,
    DatabaseOutlined,
    EditOutlined,
    EyeOutlined,
    MailOutlined,
    PlusCircleOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';

export default function ApplicationsEdit() {
    const { t } = useTranslation(['site']);
    const [editToggle, setEditToggle] = useState(false);

    const onFinish = async (values) => {
        console.log(values);
    };

    return (
        <>
            <Header
                title="<< 22509"
                actionButtons={[
                    {
                        Icon: (
                            <EyeOutlined
                                className="fs-28"
                                style={{ color: '#444036' }}
                            />
                        ),
                        url: '/app/admin/reports/report',
                    },
                    {
                        Icon: (
                            <EditOutlined
                                className="fs-28"
                                style={{ color: '#444036' }}
                            />
                        ),
                        url: '#',
                    },
                    {
                        Icon: (
                            <BookOutlined
                                className="fs-28"
                                style={{ color: '#444036' }}
                            />
                        ),
                        url: '#',
                    },
                    {
                        Icon: (
                            <MailOutlined
                                className="fs-28"
                                style={{ color: '#444036' }}
                            />
                        ),
                        url: '#',
                    },
                ]}
                back
            />

            <div className="report-wrapper ml48 mb48">
                <ReportHeader
                    id="2023A1MR99"
                    borrower="Central Agrícola Example Ltda"
                    borrowerId="XX01"
                    totalCurrentOutstanding="USD 0"
                    numberTransactions="0"
                    CNPJ="00.123.444/0000-22"
                    companyType="Limited Liability Company"
                    ownership="Family-Owned"
                    owners="Jeanette P. Ortiz, Richard N. Wertz, Robert J. O'Connor"
                    location="Uruçuí - Piauí"
                    newLoanAmount="USD 2.000.000"
                    interestRate="12%"
                    session="2023/24"
                    loanTerm="Up to 14 months"
                    typeContract="Fertilizer"
                    crop="Cotton"
                    salesChannel="A1 (20% Co-Invest)"
                    inputSeller="-"
                    offTaker="-"
                    collateral="fCPR (Land + Personal Guarantee)"
                    score="-"
                />

                <div className="card-list">
                    <Row gutter={24}>
                        <Col xs={12} md={4}>
                            <Card bordered={false} className="text-center">
                                <p className="text-medium pb16 pt8 height-50">
                                    Farm Conditions
                                </p>
                                <ProgressCircle value="n/a" />
                            </Card>
                        </Col>
                        <Col xs={12} md={4}>
                            <Card bordered={false} className="text-center">
                                <p className="text-medium pb16 pt8 height-50">
                                    Farm Financials
                                </p>
                                <ProgressCircle value={73} />
                            </Card>
                        </Col>
                        <Col xs={12} md={4}>
                            <Card bordered={false} className="text-center">
                                <p className="text-medium pb16 pt8 height-50">
                                    Counter Party
                                </p>
                                <ProgressCircle value={100} />
                            </Card>
                        </Col>
                        <Col xs={12} md={4}>
                            <Card bordered={false} className="text-center">
                                <p className="text-medium pb16 pt8 height-50">
                                    Production Risk & Field Data
                                </p>
                                <ProgressCircle value={86} />
                            </Card>
                        </Col>
                        <Col xs={12} md={4}>
                            <Card bordered={false} className="text-center">
                                <p className="text-medium pb16 pt8 height-50">
                                    Climate Risk
                                </p>
                                <ProgressCircle value="n/a" />
                            </Card>
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col span={24}>
                                    <Card
                                        bordered={false}
                                        className="text-center"
                                    >
                                        <div className="display-flex align-items-center flex-direction-column full-width">
                                            <p className="text-medium pb8">
                                                ESG
                                            </p>
                                            <Badge value="ok" />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card
                                        bordered={false}
                                        className="text-center"
                                    >
                                        <div className="display-flex align-items-center flex-direction-column full-width">
                                            <p className="text-medium pb8">
                                                Securities
                                            </p>
                                            <Badge value="ok" />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} md={24}>
                            <Card bordered={false}>
                                <div className="display-flex justify-content-space-between">
                                    <div className="display-flex">
                                        <p className="text-large font-secondary pr8">
                                            {t(
                                                'site:admin_applicationsAnalystWorkspace',
                                            )}
                                        </p>
                                        <Button
                                            type="text"
                                            icon={
                                                <EditOutlined
                                                    className="fs-20"
                                                    style={{ color: '#000' }}
                                                />
                                            }
                                            onClick={() =>
                                                setEditToggle(!editToggle)
                                            }
                                        ></Button>
                                    </div>
                                    <Badge value="In progress" />
                                </div>

                                <div className="background-color-clay p8 mt16">
                                    <div className="display-flex justify-content-space-between">
                                        <p className="text-large">
                                            {t(
                                                'site:admin_applicationsAnalystNotes',
                                            )}
                                        </p>
                                        <DatabaseOutlined
                                            className="fs-28"
                                            style={{ color: '#444036' }}
                                        />
                                    </div>
                                    <p className="text-small pt16">
                                        {t(
                                            'site:admin_applicationsAnalystNotesText',
                                        )}
                                    </p>

                                    {editToggle && (
                                        <Row>
                                            <Col xs={24} md={12}>
                                                <Card
                                                    bordered={false}
                                                    className="form form-card mt16 full-width"
                                                >
                                                    <Form onFinish={onFinish}>
                                                        <Form.Item
                                                            name="notes"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        'site:admin_applicationsAnalystNotesInput',
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <TextArea
                                                                placeholder={t(
                                                                    'site:admin_applicationsAnalystNotesInput',
                                                                )}
                                                                showCount
                                                                maxLength={250}
                                                                style={{
                                                                    height: 100,
                                                                    resize: 'none',
                                                                }}
                                                            />
                                                        </Form.Item>

                                                        <Form.Item>
                                                            <Button
                                                                type="primary"
                                                                size="large"
                                                                htmlType="submit"
                                                                icon={
                                                                    <SaveOutlined
                                                                        className=" fs-28"
                                                                        style={{
                                                                            color: '#fff',
                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                {t(
                                                                    'site:admin_applicationsAnalystNotesBtn',
                                                                )}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}
                                </div>

                                <div className="background-color-champaign p8 mt16">
                                    <div className="display-flex justify-content-space-between">
                                        <p className="text-large">
                                            {t(
                                                'site:admin_applicationsAnalystAction',
                                            )}
                                        </p>
                                        <AlertOutlined
                                            className="fs-28"
                                            style={{ color: '#444036' }}
                                        />
                                    </div>
                                    <p className="text-small pt16">
                                        {t(
                                            'site:admin_applicationsAnalystActionText',
                                        )}
                                    </p>
                                    {editToggle && (
                                        <Row>
                                            <Col xs={24} md={12}>
                                                <Card
                                                    bordered={false}
                                                    className="form form-card mt16 full-width"
                                                >
                                                    <Form onFinish={onFinish}>
                                                        <Form.Item
                                                            name="action"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        'site:admin_applicationsAnalystActionInput',
                                                                    ),
                                                                },
                                                            ]}
                                                        >
                                                            <TextArea
                                                                placeholder={t(
                                                                    'site:admin_applicationsAnalystActionInput',
                                                                )}
                                                                showCount
                                                                maxLength={250}
                                                                style={{
                                                                    height: 100,
                                                                    resize: 'none',
                                                                }}
                                                            />
                                                        </Form.Item>

                                                        <Form.Item>
                                                            <Button
                                                                className="mt16"
                                                                type="primary"
                                                                size="large"
                                                                htmlType="submit"
                                                                icon={
                                                                    <PlusCircleOutlined
                                                                        className=" fs-28"
                                                                        style={{
                                                                            color: '#fff',
                                                                        }}
                                                                    />
                                                                }
                                                            >
                                                                {t(
                                                                    'site:admin_applicationsAnalystButton',
                                                                )}
                                                            </Button>
                                                        </Form.Item>
                                                    </Form>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} md={24}>
                            <Card bordered={false}>
                                <div className="display-flex justify-content-space-between">
                                    <p className="text-large font-secondary">
                                        {t(
                                            'site:admin_applicationsAnalystSubmitData',
                                        )}
                                    </p>
                                </div>
                                <div>
                                    <p>...</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
