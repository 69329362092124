import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../Hooks/useAuth';

const PrivateRoutes = () => {
    const { token } = useAuth();

    if (!token) return <Navigate to="/auth/login" />;

    return <Outlet />;
};

export default PrivateRoutes;
