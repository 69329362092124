import { Tooltip } from 'antd';
import React, { useState } from 'react';
import {
    EyeInvisibleOutlined,
    EyeOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

/**
 * @param {number} step
 * @param {string} type
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
export default function CollapsibleEye({ step, type, children }) {
    const { t } = useTranslation(['site']);
    const [eyeToggle, setEyeToggle] = useState(true);

    return (
        <>
            <div className="display-flex align-items-center justify-content-space-between">
                <p className="text-large pt16 pb16 display-flex align-items-center">
                    {t(`site:ca${step}_5_${type}`)}
                    <Tooltip
                        className="pl8"
                        title={t(`site:ca${step}_5_${type}Tooltip`)}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>

                {eyeToggle ? (
                    <EyeOutlined
                        onClick={() => setEyeToggle(!eyeToggle)}
                        className="fs-28"
                    />
                ) : (
                    <EyeInvisibleOutlined
                        onClick={() => setEyeToggle(!eyeToggle)}
                        className="fs-28"
                    />
                )}
            </div>

            {eyeToggle && children}
        </>
    );
}
