import api from '../../../Services/HttpService';
import ExceptionHandler from '../../Services/ExceptionHandler';

const API_URL = 'user-settings';

const UserSettingsRepository = {
    show: async (source) => {
        try {
            const response = await api.get(API_URL + '/info', {
                cancelToken: source.token,
            });
            return response.data;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },

    update: async (itemData) => {
        try {
            await api.patch(API_URL, itemData);
            return true;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },

    destroy: async () => {
        try {
            await api.delete(API_URL);
            return true;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },
};

export default UserSettingsRepository;
