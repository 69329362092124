import AuthRepository from './AuthRepository';
import useAuth from '../Hooks/useAuth';
import { useEffect, useState } from 'react';

export default function LogoutPage() {
    const { token, logout } = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (token) {
            AuthRepository.logout();
            logout();
        }
        setLoading(false);
    }, []);

    if (loading) {
        return <>logging out</>;
    }

    window.location = '/';
}
