import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleEye from './CollapsibleEye';

export default function FinancialForm({ type, required = false }) {
    const { t } = useTranslation(['site']);
    const itemsType1 = ['Debt', 'InterestRate'];

    const itemsType2 = [
        'PrincipleDue2324',
        'PrincipleDue2425',
        'PrincipleDue2526',
    ];

    return (
        <CollapsibleEye
            step={4}
            type={type}
            children={
                <>
                    <Row gutter={12}>
                        {itemsType1.map((item, index) => (
                            <Col xs={24} md={8} key={index}>
                                <Form.Item
                                    name={type + item}
                                    rules={[
                                        {
                                            required,
                                            message: t('site:ca4_5_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="one-time-code"
                                        size="large"
                                        placeholder={t(
                                            `site:ca4_5_${type}${item}`,
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                    <Row gutter={12}>
                        {itemsType2.map((item, index) => (
                            <Col xs={24} md={8} key={index}>
                                <Form.Item
                                    name={type + item}
                                    rules={[
                                        {
                                            required,
                                            message: t('site:ca4_5_required'),
                                        },
                                    ]}
                                >
                                    <Input
                                        autoComplete="one-time-code"
                                        size="large"
                                        placeholder={t(
                                            `site:ca4_5_${type}${item}`,
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                </>
            }
        />
    );
}
