import { Card, Col, Form, message, notification, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { useTranslation } from 'react-i18next';
import FooterCreditApplicationForm from './Components/FooterCreditApplicationForm';
import HeaderCardCreditApplication from './Components/HeaderCardCreditApplication';
import FinancialForm from './Components/FinancialForm';
import FinancialKeyFiguresForm from './Components/FinancialKeyFiguresForm';
import { useParams } from 'react-router-dom';
import CreditApplicationRepository from './CreditApplicationRepository';
import http from '../../../Services/HttpService';

export default function CreditApplication4of5() {
    const { t } = useTranslation(['site']);
    const { applicationId } = useParams();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const [api, contextHolder] = notification.useNotification();

    const fetchData = async (source) => {
        const data = await CreditApplicationRepository.showEditForm(
            applicationId,
            source,
        );
        setInitialValues(data);
        setLoading(false);
    };

    useEffect(() => {
        const CancelToken = http.CancelToken;
        const source = CancelToken.source();

        fetchData(source);

        return () => {
            source.cancel('cancelling request');
        };
    }, []);

    const onFinish = async (values) => {
        console.log(values);
        const item = await CreditApplicationRepository.update(
            applicationId,
            values,
        );

        if (item) {
            api.success({
                message: 'Item updated',
                description: 'Credit Application updated',
            });
        }
    };

    if (loading) {
        return <>Loading...</>;
    }

    const props = {
        name: 'file',
        multiple: true,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(
                    `${info.file.name} file uploaded successfully.`,
                );
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <Card bordered={false} className="form form-card">
            <HeaderCardCreditApplication
                title={t('site:ca4_5_title')}
                step="4/4"
            />

            {contextHolder}

            <Form onFinish={onFinish} initialValues={initialValues}>
                <FinancialForm type="bankDebt" required />
                <FinancialForm type="sellerDebt" required />
                <FinancialForm type="landPurchase" required />
                <FinancialForm type="otherDebt" required />

                <Row gutter={24}>
                    <Col md={24}>
                        <p className="text-large pt16 pb16 display-flex align-items-center">
                            {t('site:ca4_5_latestIncome')}
                            <Tooltip
                                className="pl8"
                                title={t('site:ca4_5_latestIncomeTooltip')}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </p>
                        <Form.Item
                            name="latest_income"
                            rules={[
                                {
                                    required: false,
                                    message: t(
                                        'site:ca4_5_latestIncomeMessage',
                                    ),
                                },
                            ]}
                        >
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t('site:ca4_5_latestIncomeText1')}
                                </p>
                                <p className="ant-upload-hint">
                                    {t('site:ca4_5_latestIncomeText2')}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <p className="text-large pt16 pb16 display-flex align-items-center">
                    {t('site:ca4_5_latestBalance')}
                    <Tooltip
                        className="pl8"
                        title={t('site:ca4_5_latestBalanceTooltip')}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>
                <Row gutter={24}>
                    <Col md={8}>
                        <Form.Item
                            name="latest_balance_2023"
                            rules={[
                                {
                                    required: false,
                                    message: t(
                                        'site:ca4_5_latestBalanceMessage',
                                    ),
                                },
                            ]}
                        >
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t('site:ca4_5_latestBalanceText1')}
                                </p>
                                <p className="ant-upload-hint">
                                    {t('site:ca4_5_latestBalanceText2')}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item name="latest_balance_2022">
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t('site:ca4_5_latestBalanceText12')}
                                </p>
                                <p className="ant-upload-hint">
                                    {t('site:ca4_5_latestBalanceText2')}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item name="latest_balance_2021">
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t('site:ca4_5_latestBalanceText13')}
                                </p>
                                <p className="ant-upload-hint">
                                    {t('site:ca4_5_latestBalanceText2')}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <p className="text-large pt16 pb16 display-flex align-items-center">
                    {t('site:ca4_5_taxDeclarations')}
                    <Tooltip
                        className="pl8"
                        title={t('site:ca4_5_taxDeclarationsTooltip')}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>
                <Row gutter={24}>
                    <Col md={8}>
                        <Form.Item
                            name="tax_declarations_2023"
                            rules={[
                                {
                                    required: false,
                                    message: t(
                                        'site:ca4_5_taxDeclarationsMessage',
                                    ),
                                },
                            ]}
                        >
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t('site:ca4_5_taxDeclarationsText1')}
                                </p>
                                <p className="ant-upload-hint">
                                    {t('site:ca4_5_taxDeclarationsText2')}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item name="tax_declarations_2022">
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t('site:ca4_5_taxDeclarationsText12')}
                                </p>
                                <p className="ant-upload-hint">
                                    {t('site:ca4_5_taxDeclarationsText2')}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item name="tax_declarations_2021">
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t('site:ca4_5_taxDeclarationsText13')}
                                </p>
                                <p className="ant-upload-hint">
                                    {t('site:ca4_5_taxDeclarationsText2')}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <FinancialKeyFiguresForm type="financialKey" />

                <FooterCreditApplicationForm
                    applicationId={applicationId}
                    nextStep={5}
                />
            </Form>
        </Card>
    );
}
