import { Button, Card, Form, Input, notification } from 'antd';
import AuthRepository from './AuthRepository';
import { useTranslation } from 'react-i18next';

export default function ForgotPasswordPage() {
    const [api, contextHolder] = notification.useNotification();
    const { t } = useTranslation(['site']);

    const onFinish = async (values) => {
        try {
            const item = await AuthRepository.forgotPassword(values);

            api.success({
                message: `Success`,
                description: item.data.status,
            });
        } catch (e) {
            const error = e.response.data;
            api.error({
                message: `Error`,
                description: error.message,
            });
        }
    };

    return (
        <>
            {contextHolder}

            <Card bordered={false} className="form form-card">
                <h1 className="pb16">{t('site:forgotPassword_title')}</h1>
                <p className="text-large pb16">
                    {t('site:forgotPassword_info')}
                </p>

                <Form onFinish={onFinish}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: t(
                                    'site:forgotPassword_emailDescription',
                                ),
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            placeholder={t('site:forgotPassword_email')}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            block
                            type="primary"
                            size="large"
                            htmlType="submit"
                        >
                            {t('site:forgotPassword_button')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
}
