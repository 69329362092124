export const DEFAULT_VALUES = [
    {
        value: 'one_chart',
        label: 'One chart',
    },
    {
        value: 'multiple_charts',
        label: 'Multiple charts',
    },
    {
        value: 'image_gallery',
        label: 'Image gallery',
    },
    {
        value: 'map',
        label: 'Map',
    },
    {
        value: 'one_input_value',
        label: 'One Input Value',
    },
    {
        value: 'multiple_input_value_with_spaces',
        label: 'Multiple input values with spacing',
    },

    {
        value: 'multiple_input_value',
        label: 'Multiple input value',
    },
    {
        value: 'html_for_replacement',
        label: 'HTML For replacement',
    },
];
