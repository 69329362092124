import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
} from 'antd';
import Header from '../../../Components/Header';
import ReportBlockItemsRepository from './ReportBlockItemsRepository';
import React, { useState } from 'react';
import { DEFAULT_VALUES } from './ReportBlockItemTypes';

export default function ReportBlockItemsCreate() {
    const navigate = useNavigate();
    const { reportBlockId } = useParams();
    const { form } = Form.useForm();
    const formRef = React.useRef(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const onFinish = async (values) => {
        setButtonLoading(true);

        const item = await ReportBlockItemsRepository.create(
            reportBlockId,
            values,
        );

        if (item) {
            api.success({
                message: 'Item create',
                description: 'Item successfully created',
            });
        }

        navigate(`/app/admin/report-blocks/${reportBlockId}/edit`);
    };

    return (
        <>
            {contextHolder}

            <Header
                title="Report Block Items"
                subTitle="You can create an item here"
                button={{
                    to: `/app/admin/report-blocks/${reportBlockId}/edit`,
                    label: 'Back',
                }}
            />

            <Form
                form={form}
                name="report-blocks"
                onFinish={onFinish}
                layout="vertical"
                scrollToFirstError
                ref={formRef}
            >
                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item
                            name="order"
                            label="Display order"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the order',
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the title',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item
                            name="type"
                            label="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the type',
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                options={DEFAULT_VALUES}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item name="label" label="Label">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item
                            name="name_on_form"
                            label="Name on form (HTML)"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button
                        loading={buttonLoading}
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
