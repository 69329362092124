import './progress-list.scss';
import { Button, Card } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import ProgressBarCustom from '../../../../Components/ProgressBarCustom/ProgressBarCustom';
import classNames from 'classnames';

/**
 * @param {string} title
 * @param {Array} [{string: title, string: status}] items
 * @param {string: label, string: url} [button]
 * @param {string: label, string: value, string: title, string: tooltip} [score]
 * @returns {JSX.Element}
 */
export default function ProgressList({
    title,
    items = [],
    button = null,
    score = null,
}) {
    return (
        <Card bordered={false} className="ant-card--rounded mt32">
            <p className="text-large fw-700 pb8 font-secondary">{title}</p>
            <ul className="progress">
                {items.map((item, index) => {
                    const progressClass = classNames({
                        'progress-item': true,
                        'progress-item--full': item.status === 'full',
                        'progress-item--active': item.status === 'active',
                        'progress-item--empty': item.status === 'empty',
                    });

                    return (
                        <li key={index} className={progressClass}>
                            {item.title}
                        </li>
                    );
                })}
            </ul>

            {button && (
                <div className="pt16">
                    <Button type="primary" size="large">
                        <Link to={button.url}>{button.title}</Link>
                    </Button>
                </div>
            )}

            {score && (
                <div className="pt32">
                    <ProgressBarCustom {...score} />
                </div>
            )}
        </Card>
    );
}
