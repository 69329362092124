import { Button, Card, Checkbox, Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderCardCreditApplication from './Components/HeaderCardCreditApplication';
import Terms from '../../../Components/Terms';
import { useNavigate, useParams } from 'react-router-dom';
import CreditApplicationRepository from './CreditApplicationRepository';
import http from '../../../Services/HttpService';

export default function CreditApplication5of5() {
    const { t } = useTranslation(['site']);
    const { applicationId } = useParams();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const navigate = useNavigate();

    const fetchData = async (source) => {
        const data = await CreditApplicationRepository.showEditForm(
            applicationId,
            source,
        );
        setInitialValues(data);
        setLoading(false);
    };

    const onFinish = (values) => {
        console.log(values);
    };

    useEffect(() => {
        const CancelToken = http.CancelToken;
        const source = CancelToken.source();

        fetchData(source);

        return () => {
            source.cancel('cancelling request');
        };
    }, []);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <Card bordered={false} className="form form-card">
            <HeaderCardCreditApplication title={t('site:ca5_5_title')} />
            <Form onFinish={onFinish}>
                <p className="text-large pt32 pb16">
                    {t('site:ca5_5_subtitle')}
                </p>

                <p className="text-medium pb8">
                    <strong>New Loan Amount:</strong> USD 2,000,000
                </p>
                <p className="text-medium pb8">
                    <strong>Interest Rate:</strong> 12%
                </p>
                <p className="text-medium pb8">
                    <strong>Season:</strong> 2023/24
                </p>
                <p className="text-medium pb8">
                    <strong>Loan Term:</strong> Up to 18 months
                </p>
                <p className="text-medium pb8">
                    <strong>Type of Contract:</strong> Fertilizer
                </p>
                <p className="text-medium pb8">
                    <strong>Crop:</strong> Cotton
                </p>
                <p className="text-medium pb8">
                    <strong>Input Seller:</strong> -
                </p>
                <p className="text-medium pb8">
                    <strong>Off-taker:</strong> -
                </p>

                <Form.Item
                    className="pt16 mb8"
                    name="dataExternalSources"
                    valuePropName="checked"
                >
                    <Checkbox>
                        <div className="fs-16 color-green-medium">
                            {t('site:ca5_5_dataExternalSources')}
                        </div>
                    </Checkbox>
                </Form.Item>

                <Terms />

                <footer>
                    <Row gutter={24}>
                        <Col xs={24} md={12}>
                            <Button
                                className="mt16"
                                block
                                size="large"
                                onClick={() => navigate(-1)}
                            >
                                {t('site:ca_back')}
                            </Button>
                        </Col>
                        <Col xs={24} md={12}>
                            <Button
                                className="mt16"
                                block
                                type="primary"
                                size="large"
                                htmlType="submit"
                            >
                                {t('site:ca_submit')}
                            </Button>
                        </Col>
                    </Row>
                </footer>
            </Form>
        </Card>
    );
}
