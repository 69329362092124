import api from '../Services/HttpService';

const API_URL = 'auth';

const AuthRepository = {
    register: async (values) => {
        values['device_name'] = 'web';
        return await api.post(`${API_URL}/register`, values);
    },
    login: async (values) => {
        values['device_name'] = 'web';
        return await api.post(`${API_URL}/login`, values);
    },
    logout: async () => {
        try {
            return await api.get(`${API_URL}/logout`);
        } catch (e) {
            //
        }
    },
    forgotPassword: async (values) => {
        try {
            return await api.post(`${API_URL}/forgot-password`, values);
        } catch (e) {
            //
        }
    },
    resetPassword: async (values) => {
        return await api.post(`${API_URL}/reset-password`, values);
    },
    verifyEmail: async (id, hash, expires, signature) => {
        return await api.get(
            `/verify-email/${id}/${hash}?expires=${expires}&signature=${signature}`,
        );
    },
};

export default AuthRepository;
