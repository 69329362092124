import './Styles/main.scss';
import { RouterProvider } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import router from './Routing/routes';
import { ConfigProvider } from 'antd';

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: localStorage.getItem('language') || 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });

function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    fontFamily: "'Roboto', sans-serif",
                    colorPrimary: '#155029',
                    colorPrimaryText: '#155029',
                    colorLink: '#155029',
                    colorLinkActive: '#155029',
                    colorLinkHover: '#155029',
                },
                components: {
                    Table: {
                        headerBg: 'transparent',
                        cellPaddingInline: 12,
                    },
                },
            }}
        >
            <RouterProvider router={router} />
        </ConfigProvider>
    );
}

export default App;
