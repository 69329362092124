import ExceptionHandler from '../../Services/ExceptionHandler';
import api from '../../../Services/HttpService';

const API_URL = 'reports';

const CancelToken = api.CancelToken;
const source = CancelToken.source();

const ReportRepository = {
    authorizationAudit: async () => {
        try {
            const response = await api.get(`${API_URL}/authorization-audit`, {
                cancelToken: source.token,
            });
            return response.data;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },
};

export default ReportRepository;
