import {
    Checkbox,
    Card,
    Col,
    Form,
    Input,
    message,
    Row,
    Tooltip,
    notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { useTranslation } from 'react-i18next';
import FooterCreditApplicationForm from './Components/FooterCreditApplicationForm';
import HeaderCardCreditApplication from './Components/HeaderCardCreditApplication';
import { useParams } from 'react-router-dom';
import http from '../../../Services/HttpService';
import CreditApplicationRepository from './CreditApplicationRepository';

export default function CreditApplication2of5() {
    const { t } = useTranslation(['site']);
    const { applicationId } = useParams();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const [api, contextHolder] = notification.useNotification();

    const fetchData = async (source) => {
        const data = await CreditApplicationRepository.showEditForm(
            applicationId,
            source,
        );
        setInitialValues(data);
        setLoading(false);
    };

    useEffect(() => {
        const CancelToken = http.CancelToken;
        const source = CancelToken.source();

        fetchData(source);

        return () => {
            source.cancel('cancelling request');
        };
    }, []);

    const onFinish = async (values) => {
        const item = await CreditApplicationRepository.update(
            applicationId,
            values,
        );

        if (item) {
            api.success({
                message: 'Item updated',
                description: 'Credit Application updated',
            });
        }
    };

    const props = {
        name: 'file',
        multiple: true,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(
                    `${info.file.name} file uploaded successfully.`,
                );
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <Card bordered={false} className="form form-card">
            <HeaderCardCreditApplication
                title={t('site:ca2_5_title')}
                step="2/4"
            />

            {contextHolder}

            <Form onFinish={onFinish} initialValues={initialValues}>
                <p className="text-large pt16 pb16 display-flex align-items-center">
                    {t('site:ca2_5_location')}
                </p>
                <Row gutter={24}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: t('site:ca2_5_addressMessage'),
                                },
                            ]}
                        >
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_address')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="municipality"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'site:ca2_5_municipalityMessage',
                                    ),
                                },
                            ]}
                        >
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_municipality')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="state"
                            rules={[
                                {
                                    required: true,
                                    message: t('site:ca2_5_stateMessage'),
                                },
                            ]}
                        >
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_state')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <p className="text-large pt16 pb16 display-flex align-items-center">
                    {t('site:ca2_5_size')}
                </p>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="totalSize"
                            rules={[
                                {
                                    required: true,
                                    message: t('site:ca2_5_totalSizeMessage'),
                                },
                            ]}
                        >
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_totalSize')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="totalArable"
                            rules={[
                                {
                                    required: true,
                                    message: t('site:ca2_5_totalArableMessage'),
                                },
                            ]}
                        >
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_totalArable')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="ownedLand"
                            rules={[
                                {
                                    required: true,
                                    message: t('site:ca2_5_ownedLandMessage'),
                                },
                            ]}
                        >
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_ownedLand')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item name="leasedLand">
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_leasedLand')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item name="cultivatedPartnerships">
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t(
                                    'site:ca2_5_cultivatedPartnerships',
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item name="nonArable">
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_nonArable')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item name="legalReserveArea">
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_legalReserveArea')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item name="irrigatedArea">
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_irrigatedArea')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <p className="text-medium pb8">
                    {t('site:ca2_5_currentAreaUsed')}
                </p>
                <Row gutter={24}>
                    <Col xs={24} md={6}>
                        <Form.Item name="currentAreaSoybean">
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_currentAreaSoybean')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item name="currentAreaCorn">
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_currentAreaCorn')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item name="currentAreaCotton">
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_currentAreaCotton')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item name="currentAreaOther">
                            <Input //quick hack
                                autoComplete="one-time-code"
                                size="large"
                                placeholder={t('site:ca2_5_currentAreaOther')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col md={24}>
                        <p className="text-large pb16 pt16 display-flex align-items-center">
                            {t('site:ca2_5_advancedTechnology')}
                            <Tooltip
                                className="pl8"
                                title={t(
                                    'site:ca2_5_advancedTechnologyTooltip',
                                )}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </p>
                        <Form.Item name="advancedTechnology">
                            <Checkbox.Group
                                options={[
                                    { label: 'ISOBUS', value: 'ISOBUS' },
                                    {
                                        label: 'Precision Farming',
                                        value: 'PrecisionFarming',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col md={12}>
                        <p className="text-large pb16 pt16 display-flex align-items-center">
                            {t('site:ca2_5_certifications')}
                            <Tooltip
                                className="pl8"
                                title={t('site:ca2_5_certificationsTooltip')}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </p>
                        <Form.Item name="certifications">
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t('site:ca2_5_certificationsMessage')}
                                </p>
                                <p className="ant-upload-hint">
                                    {t('site:ca2_5_certificationsMessage2')}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <p className="text-large pb16 pt16 display-flex align-items-center">
                            {t('site:ca2_5_cropPlan')}
                            <Tooltip
                                className="pl8"
                                title={t('site:ca2_5_cropPlanTooltip')}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </p>
                        <Form.Item name="cropPlan">
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t('site:ca2_5_cropPlanMessage')}
                                </p>
                                <p className="ant-upload-hint">
                                    {t('site:ca2_5_cropPlanMessage2')}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                </Row>
                <p className="text-large pb16 pt16 display-flex align-items-center">
                    {t('site:ca2_5_shapeFiles')}
                    <Tooltip
                        className="pl8"
                        title={t('site:ca2_5_shapeFilesTooltip')}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>
                <Row gutter={24}>
                    <Col md={12}>
                        <p className="text-medium pb16">
                            {t('site:ca2_5_shapeFilesTooltip')}
                        </p>
                        <Form.Item
                            name="shapeFilesFarm"
                            rules={[
                                {
                                    required: false,
                                    message: t(
                                        'site:ca2_5_shapeFilesFarmMessage3',
                                    ),
                                },
                            ]}
                        >
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t('site:ca2_5_shapeFilesFarmMessage')}
                                </p>
                                <p className="ant-upload-hint">
                                    {t('site:ca2_5_shapeFilesFarmMessage2')}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <p className="text-medium pb16">
                            {t('site:ca2_5_shapeFiles2Cultivated')}
                        </p>
                        <Form.Item name="shapeFiles">
                            <Dragger {...props}>
                                <p className="ant-upload-text">
                                    {t(
                                        'site:ca2_5_shapeFilesCultivatedMessage',
                                    )}
                                </p>
                                <p className="ant-upload-hint">
                                    {t(
                                        'site:ca2_5_shapeFilesCultivatedMessage2',
                                    )}
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <FooterCreditApplicationForm nextStep={3} />
            </Form>
        </Card>
    );
}
