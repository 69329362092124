import { Link } from 'react-router-dom';
import List from '../../Components/List';
import ReportBlocksRepository from './ReportBlocksRepository';
import Header from '../../Components/Header';
import { EditOutlined } from '@ant-design/icons';

export default function ReportBlocksList() {
    const columns = [
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
            render: (text) => text,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text) => text,
        },
        {
            title: 'Is visible',
            dataIndex: 'is_visible',
            key: 'is_visible',
            render: (text) => {
                return <p>{text === true ? 'true' : 'false'}</p>;
            },
        },
        {
            title: 'Weightage',
            dataIndex: 'weightage',
            key: 'weightage',
            render: (text) => text,
        },
        {
            title: 'Score type',
            dataIndex: 'score_type',
            key: 'score_type',
            render: (text) => text,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className="display-flex align-items-center fs-20">
                    <Link
                        to={`/app/admin/report-blocks/${record.id}/edit`}
                        className="ml8"
                    >
                        <EditOutlined />
                    </Link>
                </div>
            ),
            width: 100,
            fixed: 'right',
        },
    ];

    return (
        <>
            <Header
                title="Report Blocks"
                subTitle="You can manage report blocks here"
                button={{
                    to: '/app/admin/report-blocks/create',
                    label: 'Create',
                }}
            />
            <List
                fetchDataMethod={ReportBlocksRepository.list}
                columns={columns}
                pageSize={100}
            />
        </>
    );
}
