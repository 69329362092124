import Header from '../../Components/Header';
import React from 'react';
import { Card, notification, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function UserSettings() {
    const [api, contextHolder] = notification.useNotification();

    return (
        <>
            {contextHolder}

            <Header
                title="User removed"
                subTitle="Was a pleasure have you on board"
            />

            <Card bordered={false} className="form form-card mt16">
                <p className="display-flex text-large pb16">
                    User removed
                    <Tooltip
                        className="pl8"
                        title="Was a pleasure have you on board"
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>
            </Card>
        </>
    );
}
