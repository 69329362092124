import React from 'react';
import { isRouteErrorResponse, NavLink, useRouteError } from 'react-router-dom';
import { Content, Header } from 'antd/lib/layout/layout';
import logo from './Assets/logo.svg';
import { Layout } from 'antd';

const ErrorPage = () => {
    const error = useRouteError();

    return (
        <Layout>
            <Header className="header">
                <NavLink to="/" title="Logo">
                    <img src={logo} alt="Logo" />
                </NavLink>
            </Header>
            <Content>
                <h1 className="pb16">Oops</h1>
                <div>
                    {isRouteErrorResponse(error)
                        ? 'Not found. This page does not exist.'
                        : 'An unexpected error occurred.'}
                </div>
            </Content>
        </Layout>
    );
};

export default ErrorPage;
