import { Row } from 'antd';
import BlockInfo from '../../../../Components/BlockInfo/BlockInfo';

export default function MultipleInputValue({ title, items = [] }) {
    return (
        <>
            <div className="block">
                <h4 className="pt24 pb8">{title}</h4>
                <Row>
                    <BlockInfo items={items} />
                </Row>
            </div>
        </>
    );
}
