import './layoutAdmin.scss';
import React, { useState } from 'react';
import {
    TeamOutlined,
    LockOutlined,
    ContainerOutlined,
    IdcardOutlined,
    LineChartOutlined,
    FileSearchOutlined,
    FileAddOutlined,
    HistoryOutlined,
    BarChartOutlined,
    SettingOutlined,
    PieChartOutlined,
    UserSwitchOutlined,
    BuildOutlined,
    FlagOutlined,
} from '@ant-design/icons';
import { Divider, Layout, Menu } from 'antd';
import { Link, NavLink, Outlet } from 'react-router-dom';
import logo from '../../../Assets/logo.svg';
import { useTranslation } from 'react-i18next';
import useUserInfo from '../../../Hooks/useUserInfo';
import LanguageSelector from '../../../Components/LanguageSelector';
const { Content, Sider } = Layout;

const LayoutAdmin = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]); // Store the selected keys
    const { t } = useTranslation(['site']);
    const { getUserRole } = useUserInfo();

    const handleClick = (e) => {
        // Update the selected keys state when a menu item is clicked
        setSelectedKeys([e.key]);
    };

    const farmerRule = 'farmer';
    const adminRule = 'admin';
    const agrifinAnalystRule = 'agrifin-analyst';
    const thirdPartyPartAnalystRule = '3rd-party-analyst';
    const investorRule = 'investor';

    const items = [];

    if (getUserRole() === adminRule) {
        items.push({
            key: 'accounts',
            icon: <TeamOutlined />,
            label: (
                <Link to="/app/admin/accounts">
                    {t('site:admin_menuAccounts')}
                </Link>
            ),
        });
        items.push({
            key: 'users',
            icon: <UserSwitchOutlined />,
            label: (
                <Link to="/app/admin/users">{t('site:admin_menuUsers')}</Link>
            ),
        });
    }

    if (
        getUserRole() === adminRule ||
        getUserRole() === farmerRule ||
        getUserRole() === agrifinAnalystRule ||
        getUserRole() === thirdPartyPartAnalystRule
    ) {
        items.push({
            key: 'applications',
            icon: <ContainerOutlined />,
            label: t('site:admin_menuApplications'),
            children: [
                {
                    key: 'applications/create',
                    icon: <FileAddOutlined />,
                    label: (
                        <Link to="/app/admin/applications/create">
                            {t('site:admin_menuApplicationsFarmerNew')}
                        </Link>
                    ),
                },
                {
                    key: '/',
                    icon: <HistoryOutlined />,
                    label: (
                        <Link to="/app/admin/applications">
                            {t('site:admin_menuApplicationsHistory')}
                        </Link>
                    ),
                },
            ],
        });
    }

    if (getUserRole() === agrifinAnalystRule || getUserRole() === adminRule) {
        items.push({
            key: 'reports',
            icon: <LineChartOutlined />,
            label: t('site:admin_menuReport'),
            children: [
                {
                    key: 'report',
                    icon: <LineChartOutlined />,
                    label: (
                        <Link to={'/app/admin/reports/report'}>
                            {t('site:admin_menuReportSubItem')}
                        </Link>
                    ),
                },
                {
                    key: 'ReportBlocksList',
                    icon: <BarChartOutlined />,
                    path: '/report-blocks',
                    label: (
                        <Link to="/app/admin/report-blocks">
                            {t('site:admin_menuReportBlocks')}
                        </Link>
                    ),
                },
            ],
        });
    }

    if (
        getUserRole() === adminRule ||
        getUserRole() === agrifinAnalystRule ||
        getUserRole() === thirdPartyPartAnalystRule
    ) {
        items.push({
            key: 'quick-scores',
            icon: <BuildOutlined />,
            path: '/quick-scores',
            label: (
                <Link to="/app/admin/quick-scores">
                    {t('site:admin_menuQuickScore')}
                </Link>
            ),
        });
    }

    if (
        getUserRole() === adminRule ||
        getUserRole() === investorRule ||
        getUserRole() === agrifinAnalystRule
    ) {
        items.push(
            {
                key: 'monitoring',
                icon: <BarChartOutlined />,
                path: '/monitoring',
                label: (
                    <Link to="/app/admin/monitoring">
                        {t('site:admin_menuMonitoring')}
                    </Link>
                ),
            },
            {
                key: 'portfolio',
                icon: <PieChartOutlined />,
                path: '/portfolio',
                label: (
                    <Link to="/app/admin/portfolio">
                        {t('site:admin_menuPortfolio')}
                    </Link>
                ),
            },
        );
    }

    if (
        getUserRole() === investorRule ||
        getUserRole() === thirdPartyPartAnalystRule
    ) {
        items.push({
            key: 'reports',
            icon: <LineChartOutlined />,
            label: t('site:admin_menuReport'),
            children: [
                {
                    key: 'report',
                    icon: <LineChartOutlined />,
                    label: (
                        <Link to={'/app/admin/reports/report'}>
                            {t('site:admin_menuReportSubItem')}
                        </Link>
                    ),
                },
            ],
        });
    }

    if (getUserRole() === adminRule) {
        items.push({
            key: 'authorization',
            icon: <LockOutlined />,
            label: t('site:admin_menuAuthorization'),
            children: [
                {
                    key: 'permissions',
                    icon: <LockOutlined />,
                    label: (
                        <Link to="/app/admin/permissions">
                            {t('site:admin_menuAuthorizationPermissions')}
                        </Link>
                    ),
                },
                {
                    key: 'roles',
                    icon: <IdcardOutlined />,
                    path: '/roles',
                    label: (
                        <Link to="/app/admin/roles">
                            {t('site:admin_menuAuthorizationRoles')}
                        </Link>
                    ),
                },
                {
                    key: 'audit',
                    icon: <FileSearchOutlined />,
                    label: (
                        <Link to={'/app/admin/reports/authorization-audit'}>
                            {t('site:admin_menuReportAuditAcl')}
                        </Link>
                    ),
                },
            ],
        });
    }

    items.push({
        key: 'settings',
        icon: <SettingOutlined />,
        path: 'user-settings',
        label: (
            <Link to="/app/admin/users-settings">
                {t('site:admin_menuSettings')}
            </Link>
        ),
    });

    items.push({
        label: '',
        key: 'empty',
    });

    items.push({
        key: '#',
        icon: <FlagOutlined />,
        label: <LanguageSelector />,
    });

    items.push({
        key: '/auth/logout',
        icon: <LockOutlined />,
        label: <Link to="/auth/logout">{t('site:menu_logout')}</Link>,
    });

    return (
        <Layout
            style={{
                minHeight: '100vh',
                maxWidth: 'unset',
            }}
        >
            <Sider
                width={230}
                theme="light"
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                breakpoint="lg"
            >
                <NavLink to="/app/admin/" title="Logo">
                    <img src={logo} alt="Logo" className="pt16 pl16" />
                </NavLink>
                <Divider />
                <Menu
                    inlineIndent={12}
                    mode="inline"
                    items={items}
                    onClick={handleClick}
                    selectedKeys={selectedKeys}
                />
            </Sider>
            <Content>
                <div className="bg-page"></div>
                <div className="content-page">
                    <Outlet />
                </div>
            </Content>
        </Layout>
    );
};
export default LayoutAdmin;
