import { Line } from '@ant-design/plots';

/**
 * @param {string} title
 * @param {array} data
 * @returns {JSX.Element}
 * @constructor
 */
export default function ChartMultiLine({ title, data }) {
    const config = {
        data,
        xField: 'year',
        yField: 'value',
        seriesField: 'category',
        color: [
            'l(270) 0:#AABF5C 0.5:#689650 1:#2F7346',
            'l(0) 0:#E69D30 1:#F3D465',
        ],
        xAxis: {
            type: 'time',
        },
    };

    return (
        <>
            {title && <p className="text-medium text-center pb8">{title}</p>}
            <Line {...config} />
        </>
    );
}
