import Header from '../../Components/Header';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Divider, notification, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ProgressBarCustom from '../../Components/ProgressBarCustom/ProgressBarCustom';
import { useNavigate, useParams } from 'react-router-dom';
import QuickScoreRepository from './QuickScoreRepository';
import http from '../../../Services/HttpService';
import OtherBlock from './Components/OtherBlock';

const getLabel = (score) => {
    if (score > 7500) {
        return 'excellent chances';
    }

    if (score > 5000) {
        return 'good chances';
    }

    if (score > 2500) {
        return 'mid chances';
    }

    if (score > 1200) {
        return 'bad chances';
    }

    return 'Very bad chances';
};

export default function QuickScoreShow() {
    const { quickScoreId } = useParams();
    const { t } = useTranslation(['site']);
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(true);

    const fetchData = async (source) => {
        const response = await QuickScoreRepository.show(quickScoreId, source);
        setData(response);
        setLoading(false);
    };

    useEffect(() => {
        let CancelToken = http.CancelToken;
        let source = CancelToken.source();
        fetchData(source);
    }, []);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <>
            {contextHolder}

            <Header
                title={t('site:admin_quickScore_title')}
                subTitle={t('site:admin_quickScore_subtitle')}
            />
            <Card bordered={false} className="form form-card mt16">
                <p className="display-flex text-large pb16 justify-content-space-between">
                    {t('site:admin_quickScore_result')}
                    <Tooltip
                        className="pl8"
                        title={t('site:admin_quickScore_resultTooltip')}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>
                <Row gutter={24}>
                    <Col xs={24} md={24}>
                        <p className="text-medium pb16">
                            CPF: {data.code}
                        </p>
                        <ProgressBarCustom
                            label={getLabel(data.score * 10)}
                            value={data.score * 10}
                        />
                    </Col>
                </Row>
            </Card>

            <Button
                className="mb32"
                type="primary mt32"
                size="large"
                onClick={() => navigate('/app/admin/applications/create')}
            >
                {t('site:admin_quickScore_start')}
            </Button>

            <Divider />

            <div className="max-with-350">
                <OtherBlock />
            </div>

            <Divider />

            <Card
                title="Raw result"
                bordered={false}
                style={{
                    width: '100%',
                    overflowX: 'auto',
                }}
            >
                <pre>{JSON.stringify(data.raw_result)}</pre>
            </Card>
        </>
    );
}
