export default function Terms() {
    return (
        <>
            <h1 className="pb16">Terms Page</h1>
            <div>
                Dashboard content Posuere ac ut consequat semper. Tincidunt
                augue interdum velit euismod. Sit amet mattis vulputate enim
                nulla aliquet porttitor. Condimentum id venenatis a condimentum
                vitae sapien pellentesque habitant. Viverra aliquet eget sit
                amet tellus cras adipiscing enim. Molestie a iaculis at erat
                pellentesque adipiscing commodo elit. Quis varius quam quisque
                id diam vel quam. Vehicula ipsum a arcu cursus vitae congue
                mauris rhoncus aenean. Vel eros donec ac odio tempor orci
                dapibus ultrices. Porta lorem mollis aliquam ut porttitor leo a
                diam sollicitudin. Amet purus gravida quis blandit turpis.
                Hendrerit gravida rutrum quisque non tellus.
            </div>
        </>
    );
}
