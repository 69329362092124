import Header from '../../Components/Header';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Form, Input, notification, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import QuickScoreRepository from './QuickScoreRepository';
import OtherBlock from './Components/OtherBlock';

export default function QuickScoreCreate() {
    const navigate = useNavigate();
    const { t } = useTranslation(['site']);
    const [api, contextHolder] = notification.useNotification();
    const [buttonLoading, setButtonLoading] = useState(false);

    const onFinish = async (values) => {
        setButtonLoading(true);
        const item = await QuickScoreRepository.create(values);

        if (item) {
            api.success({
                message: 'Item created',
                description: 'Item successfully created',
            });
        }

        navigate(`/app/admin/quick-scores`);
    };

    return (
        <>
            {contextHolder}

            <Header
                title={t('site:admin_quickScore_title')}
                subTitle={t('site:admin_quickScore_subtitle')}
            />
            <Form onFinish={onFinish}>
                <p className="text-large pb16">
                    {t('site:admin_quickScore_contactInfo')}
                </p>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'site:admin_quickScore_codeDescription',
                                    ),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder={t('site:admin_quickScore_code')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            className="pb8 mb8"
                            name="privateInformation"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value
                                            ? Promise.resolve()
                                            : Promise.reject(
                                                  new Error(
                                                      t('site:terms_error'),
                                                  ),
                                              ),
                                },
                            ]}
                        >
                            <Checkbox>
                                <div className="fs-16 color-green-medium">
                                    {t('site:admin_quickScore_checkbox')}
                                </div>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item>
                            <Button
                                block
                                loading={buttonLoading}
                                type="primary"
                                size="large"
                                htmlType="submit"
                            >
                                {t('site:admin_quickScore_button')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

                <div className="max-with-350">
                    <OtherBlock />
                </div>
            </Form>
        </>
    );
}
