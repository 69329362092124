export const SCORE_TYPE_DEFAULT_VALUES = [
    {
        value: 'integer',
        label: 'Integer',
    },
    {
        value: 'boolean',
        label: 'Boolean',
    },
];
