import MapInteractive from '../../../../Components/Map/MapInteractive';

export default function Map({ title, coordinates = [] }) {
    return (
        <div className="block">
            <h4 className="pt24 pb8">{title}</h4>
            <MapInteractive
                center={coordinates}
                circles={[[-7.229801, -44.523826]]}
                zoom={16}
            />
        </div>
    );
}
