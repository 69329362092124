import './progress-bar-custom.scss';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';

/**
 * @param {number} value
 * @param {string|null} label
 * @param {string|null} title
 * @param {string|null} tooltip
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProgressBarCustom({
    value,
    label = null,
    title = null,
    tooltip = null,
}) {
    /**
     * @param {number} value
     * @returns {number}
     */
    const getScorePosition = (value) => {
        if (isMobile) {
            // 28 is
            return value / 4 / 10 - 28;
        }
        return value / 2 / 10 - 58;
    };

    return (
        <>
            {title && (
                <div className="display-flex align-items-center pb16">
                    <h2>{title}</h2>
                    {tooltip && (
                        <Tooltip className="pl8 fs-28" title={tooltip}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    )}
                </div>
            )}
            <div className="score-bar">
                <div
                    className="score-bar-value"
                    style={{ left: getScorePosition(value) }}
                >
                    {label}
                </div>
            </div>
        </>
    );
}
