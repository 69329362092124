import './list-score.scss';
import classNames from 'classnames';

/**
 * @param [{string: label, string: value, string|null: type}] items
 * @param {boolean} isCollapsed
 * @returns {JSX.Element}
 * @constructor
 */
export default function ListScore({ items = {}, isCollapsed = false }) {
    return (
        <ul className="list-score">
            {items.map(({ label, value, extraInfo }, index) => {
                let type = 'A+';
                let valueInt = parseInt(value);

                if (valueInt > 0 && valueInt <= 20) {
                    type = 'E';
                } else if (valueInt > 20 && valueInt <= 40) {
                    type = 'D';
                } else if (valueInt > 40 && valueInt <= 50) {
                    type = 'C';
                } else if (valueInt > 50 && valueInt <= 60) {
                    type = 'C+';
                } else if (valueInt > 60 && valueInt <= 70) {
                    type = 'B';
                } else if (valueInt > 70 && valueInt <= 80) {
                    type = 'B+';
                } else if (valueInt > 80 && valueInt <= 90) {
                    type = 'A';
                } else if (
                    valueInt === 0 ||
                    valueInt === null ||
                    valueInt === '' ||
                    value === undefined
                ) {
                    type = 'empty';
                }

                const itemClass = classNames({
                    'list-score-item': true,
                    'list-score-item--a': type === 'A' || type === 'A+',
                    'list-score-item--b': type === 'B' || type === 'B+',
                    'list-score-item--c': type === 'C' || type === 'C+',
                    'list-score-item--d': type === 'D',
                    'list-score-item--e': type === 'E',
                    'list-score-item--empty': type === 'empty',
                });

                return (
                    <li key={index} className={itemClass}>
                        <div>
                            {label}
                            {isCollapsed && extraInfo && (
                                <span> - {extraInfo}</span>
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}
