import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import AuthRepository from './AuthRepository';
import { useParams } from 'react-router-dom';

export default function VerifyEmailPage() {
    const [api, contextHolder] = notification.useNotification();
    const { id, hash } = useParams();
    const [status, setStatus] = useState('waiting');

    const verifyEmail = async () => {
        const params = new URLSearchParams(window.location.search);

        try {
            await AuthRepository.verifyEmail(
                id,
                hash,
                params.get('expires'),
                params.get('signature'),
            );

            setStatus('success');
        } catch (e) {
            console.log(e.message);
            setStatus('error');
        }
    };

    useEffect(() => {
        verifyEmail();
    }, []);

    // can replace for notifications instead or create right
    // HTML's for each statuses

    if (status === 'waiting') {
        return <>Waiting</>;
    }

    if (status === 'error') {
        return <>Error</>;
    }

    return (
        <>
            {contextHolder}
            Just confirmed ;)
        </>
    );
}
