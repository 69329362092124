import api from '../../../Services/HttpService';
import ExceptionHandler from '../../Services/ExceptionHandler';

const API_URL = 'report-blocks';

const ReportBlocksRepository = {
    show: async (id, source) => {
        try {
            const response = await api.get(`${API_URL}/${id}`, {
                cancelToken: source.token,
            });
            return response.data;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },

    create: async (itemData) => {
        try {
            const response = await api.post(API_URL, itemData);

            return response.data;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },

    update: async (id, itemData) => {
        try {
            await api.patch(`${API_URL}/${id}`, itemData);
            return true;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },

    destroy: async (id) => {
        try {
            await api.delete(`${API_URL}/${id}`);
            return true;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },

    list: async (params, source) => {
        try {
            const response = await api.get(`${API_URL}?${params}`, {
                cancelToken: source.token,
            });
            return response.data;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },
};

export default ReportBlocksRepository;
