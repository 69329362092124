import { useState } from 'react';

export default function useAuth() {
    const [token, setToken] = useState(() => localStorage.getItem('token'));

    const saveToken = (userToken) => {
        localStorage.setItem('token', userToken);
        setToken(userToken); // Update the state with the new token
    };

    const clearToken = () => {
        try {
            localStorage.removeItem('token');
        } catch (e) {}
        setToken(null); // Update the state to clear the token
    };

    return {
        setToken: saveToken,
        token,
        logout: clearToken,
    };
}
