import Header from '../../../Components/Header';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    notification,
    Divider,
    InputNumber,
    Select,
} from 'antd';
import http from '../../../../Services/HttpService';
import ReportBlockDetailsRepository from './ReportBlockDetailsRepository';
import { DEFAULT_VALUES } from './ReportBlockDetailTypes';

export default function ReportBlockDetailsEdit() {
    const navigate = useNavigate();
    const { form } = Form.useForm();
    const formRef = React.useRef(null);
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [api, contextHolder] = notification.useNotification();
    const { reportBlockId, reportBlockDetailId } = useParams();

    const onFinish = async (values) => {
        setButtonLoading(true);

        const item = await ReportBlockDetailsRepository.update(
            reportBlockId,
            reportBlockDetailId,
            values,
        );

        if (item) {
            api.success({
                message: 'Item updated',
                description: 'Item successfully updated',
            });
        }

        navigate(`/app/admin/report-blocks/${reportBlockId}/edit`);
    };

    const fetchData = async (source) => {
        const data = await ReportBlockDetailsRepository.show(
            reportBlockId,
            reportBlockDetailId,
            source,
        );
        setInitialValues(data);
        setLoading(false);
    };

    const removeItem = async () => {
        const item = await ReportBlockDetailsRepository.destroy(
            reportBlockId,
            reportBlockDetailId,
        );

        if (item) {
            api.destroy({
                message: 'Item removed',
                description: 'Item successfully removed',
            });
        }

        navigate(`/app/admin/report-blocks/${reportBlockId}/edit`);
    };

    useEffect(() => {
        const CancelToken = http.CancelToken;
        const source = CancelToken.source();

        fetchData(source);

        return () => {
            source.cancel('cancelling request');
        };
    }, []);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <>
            {contextHolder}

            <Header
                title="Report details"
                subTitle="You can edit report-blocks details here"
                button={{
                    to: '/app/admin/report-blocks/' + reportBlockId + '/edit',
                    label: 'Back',
                }}
            />

            <Form
                form={form}
                name="report-blocks"
                onFinish={onFinish}
                layout="vertical"
                scrollToFirstError
                ref={formRef}
                initialValues={initialValues}
            >
                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item
                            name="order"
                            label="Display order"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the order',
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the title',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item
                            name="type"
                            label="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the type',
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    width: '100%',
                                }}
                                options={DEFAULT_VALUES}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button
                        loading={buttonLoading}
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>

            <Divider />

            <h3 className="pb8">Remove item</h3>
            <Button
                danger
                size="large"
                htmlType="submit"
                onClick={() => removeItem()}
            >
                Remove
            </Button>
        </>
    );
}
