import api from '../../../../Services/HttpService';
import ExceptionHandler from '../../../Services/ExceptionHandler';

const API_URL = 'report-blocks';

const ReportBlockDetailsRepository = {
    reportBlock: null,

    setReportBlock: (reportBlock) => {
        ReportBlockDetailsRepository.reportBlock = reportBlock;
        return ReportBlockDetailsRepository;
    },

    getReportBlock: () => {
        return ReportBlockDetailsRepository.reportBlock;
    },

    show: async (reportBlockId, id, source) => {
        try {
            const response = await api.get(
                `${API_URL}/${reportBlockId}/details/${id}`,
                {
                    cancelToken: source.token,
                },
            );
            return response.data;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },

    create: async (reportBlockId, itemData) => {
        try {
            const response = await api.post(
                `${API_URL}/${reportBlockId}/details`,
                itemData,
            );

            return response.data;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },

    update: async (reportBlockId, id, itemData) => {
        try {
            await api.patch(
                `${API_URL}/${reportBlockId}/details/${id}`,
                itemData,
            );
            return true;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },

    destroy: async (reportBlockId, id) => {
        try {
            await api.delete(`${API_URL}/${reportBlockId}/details/${id}`);
            return true;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },
    list: async (params, source) => {
        try {
            const reportBlockId = ReportBlockDetailsRepository.getReportBlock();

            const response = await api.get(
                `${API_URL}/${reportBlockId}/details?${params}`,
                {
                    cancelToken: source.token,
                },
            );
            return response.data;
        } catch (error) {
            ExceptionHandler.catch(error);
        }
    },
};

export default ReportBlockDetailsRepository;
