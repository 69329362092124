import Header from '../../Components/Header';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Modal,
    notification,
    Row,
    Tooltip,
} from 'antd';
import UserSettingsRepository from './UserSettingsRepository';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import http from '../../../Services/HttpService';

export default function UserSettings() {
    const navigate = useNavigate();
    const { t } = useTranslation(['site']);
    const [api, contextHolder] = notification.useNotification();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({});

    const onFinish = async (values) => {
        setButtonLoading(true);

        const item = await UserSettingsRepository.update(values);

        if (item) {
            api.success({
                message: 'Item updated',
                description: 'Item successfully updated',
            });
        }

        navigate(`/app/admin`);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleRemoveAccount = async () => {
        setIsModalOpen(false);
        await UserSettingsRepository.destroy();
        navigate('/user-removed');
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchData = async (source) => {
        const response = await UserSettingsRepository.show(source);
        setInitialValues(response);
        setLoading(false);
    };

    useEffect(() => {
        let CancelToken = http.CancelToken;
        let source = CancelToken.source();

        fetchData(source);
    }, []);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <>
            {contextHolder}

            <Header
                title={t('site:admin_settings_title')}
                subTitle={t('site:admin_settings_subtitle')}
            />

            <Card bordered={false} className="form form-card mt16">
                <p className="display-flex text-large pb16">
                    {t('site:admin_settings_formTitle')}
                    <Tooltip
                        className="pl8"
                        title={t('site:admin_settings_formTitleTooltip')}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </p>

                <Form onFinish={onFinish} initialValues={initialValues}>
                    <p className="text-large pb16">
                        {t('site:admin_settings_contactInfo')}
                    </p>
                    <Row gutter={24}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'site:admin_settings_firstNameDescription',
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder={t(
                                        'site:admin_settings_firstName',
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'site:admin_settings_lastNameDescription',
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder={t(
                                        'site:admin_settings_lastName',
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'site:admin_settings_phoneDescription',
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder={t('site:admin_settings_phone')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} md={12}>
                            <Form.Item name="email">
                                <Input
                                    disabled={true}
                                    size="large"
                                    placeholder={t('site:admin_settings_email')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="cpf"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'site:admin_settings_cpfDescription',
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder={t('site:admin_settings_cpf')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        min: 8,
                                        message: t(
                                            'site:register_passwordMin8Chars',
                                        ),
                                    },
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    placeholder={t('site:register_password')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="password_confirmation"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('password') ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'The two passwords that you entered do not match',
                                                ),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    size="large"
                                    placeholder={t(
                                        'site:register_passwordConfirmation',
                                    )}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} md={12}>
                            <Form.Item>
                                <Button
                                    block
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    loading={buttonLoading}
                                >
                                    {t('site:admin_settings_button')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <div
                className="pt32 fs-16"
                onClick={showModal}
                style={{ cursor: 'pointer' }}
            >
                <DeleteOutlined style={{ color: '#e31d1c' }} />{' '}
                <span className="color-red">
                    {t('site:admin_settings_removeAccount')}
                </span>
            </div>
            <Modal
                title={t('site:admin_settings_modalRemoveAccountTitle')}
                centered
                open={isModalOpen}
                onOk={handleRemoveAccount}
                onCancel={handleCancel}
                okText={t('site:admin_settings_modalRemoveAccountOk')}
                cancelText={t('site:admin_settings_modalRemoveAccountCancel')}
            >
                <p>{t('site:admin_settings_modalRemoveAccountText')}</p>
            </Modal>
        </>
    );
}
