import { Column } from '@ant-design/plots';

/**
 * @param {string} title
 * @param {array} data
 * @returns {JSX.Element}
 * @constructor
 */
export default function ChartGroupedColumnPlot({ title, data }) {
    const config = {
        data,
        isGroup: true,
        isStack: true,
        xField: 'year',
        yField: 'value',
        seriesField: 'name',
        color: [
            'l(270) 0:#AABF5C 0.5:#689650 1:#2F7346',
            'l(0) 0:#E69D30 1:#F3D465',
        ],
        legend: {
            position: 'bottom',
        },
        label: {
            position: 'top',
            style: {
                fontWeight: 700,
            },
            layout: [
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
    };

    return (
        <>
            <p className="text-medium text-center pb8">{title}</p>
            <Column {...config} />
        </>
    );
}
