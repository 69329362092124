import Header from '../../Components/Header';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    notification,
    Modal,
    Select,
} from 'antd';
import UsersRepository from './UsersRepository';
import http from '../../../Services/HttpService';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import RolesRepository from '../Roles/RolesRepository';
import qs from 'qs';

export default function UsersEdit() {
    const { userId } = useParams();
    const navigate = useNavigate();
    const { form } = Form.useForm();
    const formRef = React.useRef(null);
    const { t } = useTranslation(['site']);
    const [roles, setRoles] = useState();
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [initialValues, setInitialValues] = useState({});

    const onFinish = async (values) => {
        setButtonLoading(true);

        const item = await UsersRepository.update(userId, values);

        if (item) {
            api.success({
                message: 'Item updated',
                description: 'Item successfully updated',
            });
        }

        navigate(`/app/admin/users`);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleRemoveAccount = async () => {
        setIsModalOpen(false);
        await UsersRepository.destroy(userId);
        navigate(`/app/admin/users`);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchData = async (source) => {
        const response = await UsersRepository.show(userId, source);

        const data = {
            ...response,
            role: response?.roles[0]?.name ?? 'farmer',
        };

        setInitialValues(data);
    };

    const fetchRoles = async (source) => {
        const response = await RolesRepository.list(
            qs.stringify({
                has_pagination: false,
            }),
            source,
        );

        setRoles(
            response.map((item) => ({
                value: item.name,
                label: item.name,
            })),
        );
    };

    useEffect(() => {
        let CancelToken = http.CancelToken;
        let source = CancelToken.source();

        Promise.all([fetchData(source), fetchRoles(source)]).then(async () => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <>Loading...</>;
    }

    return (
        <>
            {contextHolder}

            <Header
                title="Users"
                subTitle="You can change users here"
                button={{ to: '/app/admin/users', label: 'Back' }}
            />

            <Form
                form={form}
                name="users"
                onFinish={onFinish}
                layout="vertical"
                scrollToFirstError
                ref={formRef}
                initialValues={initialValues}
            >
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="first_name"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'site:admin_settings_firstNameDescription',
                                    ),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder={t('site:admin_settings_firstName')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="last_name"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'site:admin_settings_lastNameDescription',
                                    ),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder={t('site:admin_settings_lastName')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name="phone">
                            <Input
                                size="large"
                                placeholder={t('site:admin_settings_phone')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item name="email">
                            <Input
                                disabled={true}
                                size="large"
                                placeholder={t('site:admin_settings_email')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item name="cpf">
                            <Input
                                size="large"
                                placeholder={t('site:admin_settings_cpf')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    min: 8,
                                    message: t(
                                        'site:register_passwordMin8Chars',
                                    ),
                                },
                            ]}
                        >
                            <Input.Password
                                size="large"
                                placeholder={t('site:register_password')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="password_confirmation"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue('password') === value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                'The two passwords that you entered do not match',
                                            ),
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                size="large"
                                placeholder={t(
                                    'site:register_passwordConfirmation',
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item
                            name="role"
                            label="Role"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the role',
                                },
                            ]}
                        >
                            <Select size={'large'} options={roles} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item>
                            <Button
                                block
                                type="primary"
                                size="large"
                                htmlType="submit"
                                loading={buttonLoading}
                            >
                                {t('site:admin_settings_button')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <div
                className="pt32 fs-16"
                onClick={showModal}
                style={{ cursor: 'pointer' }}
            >
                <DeleteOutlined style={{ color: '#e31d1c' }} />{' '}
                <span className="color-red">
                    {t('site:admin_settings_removeAccount')}
                </span>
            </div>
            <Modal
                title={t('site:admin_settings_modalRemoveAccountTitle')}
                centered
                open={isModalOpen}
                onOk={handleRemoveAccount}
                onCancel={handleCancel}
                okText={t('site:admin_settings_modalRemoveAccountOk')}
                cancelText={t('site:admin_settings_modalRemoveAccountCancel')}
            >
                <p>{t('site:admin_settings_modalRemoveAccountText')}</p>
            </Modal>
        </>
    );
}
