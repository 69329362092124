const ApplicationsRepository = {
    list: () => {
        return {
            data: [
                {
                    id: 202312,
                    loan_amount: '$ 2.000.000',
                    company: 'Canelloni Group',
                    applied_on: '24.08.2023',
                    grade: '87',
                    status: 'seeding',
                },
                {
                    id: 202310,
                    loan_amount: '$ 2.000.000',
                    company: 'Canelloni Group',
                    applied_on: '24.08.2023',
                    grade: '78',
                    status: 'seeding',
                },
                {
                    id: 202309,
                    loan_amount: '$ 2.000.000',
                    company: 'Canelloni Group',
                    applied_on: '24.08.2023',
                    grade: '89',
                    status: 'growing',
                },
                {
                    id: 202311,
                    loan_amount: '$ 1.800.000',
                    company: 'FarmVentu',
                    applied_on: '24.08.2023',
                    grade: '95',
                    status: 'harvesting',
                },
            ],
        };
    },
};

export default ApplicationsRepository;
