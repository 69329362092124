import Header from '../../Components/Header';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Row, Col, Button, Input, notification } from 'antd';
import PermissionsRepository from './PermissionsRepository';

export default function PermissionsCreate() {
    const navigate = useNavigate();
    const { form } = Form.useForm();
    const formRef = React.useRef(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const onFinish = async (values) => {
        setButtonLoading(true);

        const item = await PermissionsRepository.create(values);

        if (item) {
            api.success({
                message: 'Item create',
                description: 'Item successfully created',
            });
        }

        navigate(`/app/admin/permissions`);
    };

    return (
        <>
            {contextHolder}

            <Header
                title="Permissions"
                subTitle="You can change permissions for users here"
                button={{ to: '/app/admin/permissions', label: 'Back' }}
            />

            <Form
                form={form}
                name="permissions"
                onFinish={onFinish}
                layout="vertical"
                scrollToFirstError
                ref={formRef}
            >
                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={24} md={12}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button
                        loading={buttonLoading}
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
