import { Card, Col, Row } from 'antd';
import BadgeScore from '../../../Components/BadgeScore/BadgeScore';
import BlockInfo from '../BlockInfo/BlockInfo';
import map from '../../../Assets/map_report.svg';
import { useTranslation } from 'react-i18next';
import MapInteractive from '../Map/MapInteractive';
import React, { useState } from 'react';
import { CloseCircleOutlined, UpCircleOutlined } from '@ant-design/icons';

export default function ReportHeader({
    id,
    borrower,
    borrowerId,
    totalCurrentOutstanding,
    numberTransactions,
    CNPJ,
    companyType,
    ownership,
    owners,
    location,
    newLoanAmount,
    interestRate,
    session,
    loanTerm,
    typeContract,
    crop,
    salesChannel,
    inputSeller,
    offTaker,
    collateral,
    score = '-',
}) {
    const { t } = useTranslation(['site']);

    const [isShowMap, setIsShowMap] = useState(false);
    const showMap = () => {
        setIsShowMap(!isShowMap);
    };

    const head = () => {
        return (
            <>
                <h1 className="pb16">{t('site:report_title')}</h1>
                <div className="logo-report"></div>
                <h2>
                    {t('site:report_subtitle')}
                    <span className="fw-700"> {id}</span>
                </h2>
            </>
        );
    };

    return (
        <Card bordered={false} type="inner" extra={head()} className="report">
            <Row>
                <Col xs={24} md={20}>
                    <Row gutter={24}>
                        <Col xs={24} md={12}>
                            <div className="display-flex">
                                <h2 className="fs-18 pb8">
                                    <span className="fw-700">
                                        {t('site:report_borrower')}
                                    </span>
                                    {borrower}
                                </h2>
                                <BadgeScore value={score} />
                            </div>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_borrowerId')}
                                </span>
                                {borrowerId}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_totalCurrentOutstanding')}
                                </span>
                                {totalCurrentOutstanding}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_numberTransactions')}
                                </span>
                                {numberTransactions}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_CNPJ')}
                                </span>
                                {CNPJ}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_companyType')}
                                </span>
                                {companyType}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_ownership')}
                                </span>
                                {ownership}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_owners')}
                                </span>
                                {owners}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_location')}
                                </span>
                                {location}
                            </p>
                            <div className="block-summary text-small">
                                Family owned company run by experienced farmers
                                who are in the region since the end of 80's.
                                Good productivity levels and investments (cotton
                                ginning) driving the positive results and a
                                fairly acceptable indebtedness level over the
                                recent crops.
                            </div>
                        </Col>
                        <Col xs={24} md={12}>
                            <h2 className="fs-18 pb8">
                                <span className="fw-700">
                                    {t('site:report_transactionParameters')}
                                </span>
                            </h2>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_newLoanAmount')}
                                </span>
                                {newLoanAmount}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_interestRate')}
                                </span>
                                {interestRate}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_session')}
                                </span>
                                {session}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_loanTerm')}
                                </span>
                                {loanTerm}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_typeContract')}
                                </span>
                                {typeContract}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_crop')}
                                </span>
                                {crop}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_salesChannel')}
                                </span>
                                {salesChannel}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_inputSeller')}
                                </span>
                                {inputSeller}
                            </p>
                            <p>
                                <span className="fw-700">
                                    {t('site:report_offTaker')}
                                </span>
                                {offTaker}
                            </p>
                            <p className="pb8">
                                <span className="fw-700">
                                    {t('site:report_collateral')}
                                </span>
                                {collateral}
                            </p>
                            <BlockInfo
                                items={[
                                    {
                                        label: 'Loan amount',
                                        value: '$ 2,000,000',
                                    },
                                    {
                                        label: 'Free Cashflow',
                                        value: '$ 5,660,159',
                                    },
                                    {
                                        label: 'Value Guarantee',
                                        value: '$ 4,119,792',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={4}>
                    <div className="display-flex justify-content-end">
                        <BadgeScore value={score} />
                    </div>
                    <img
                        className="img-responsive"
                        src={map}
                        alt="map"
                        style={{
                            cursor: 'pointer',
                            width: '100%',
                            marginTop: '16px',
                            borderRadius: '8px',
                        }}
                        onClick={showMap}
                    />
                    {!isShowMap && (
                        <p
                            style={{ cursor: 'pointer' }}
                            className="fw-700 text-right p16 fs-14"
                            onClick={showMap}
                        >
                            Open Map <UpCircleOutlined className="fs-16" />
                        </p>
                    )}
                </Col>
            </Row>
            {isShowMap && (
                <Row>
                    <Col sm={24} md={24}>
                        <p
                            style={{ cursor: 'pointer' }}
                            className="fw-700 text-right p16 fs-14"
                            onClick={showMap}
                        >
                            Close Map <CloseCircleOutlined className="fs-16" />
                        </p>
                        <MapInteractive
                            center={[-7.229801, -44.523826]}
                            circles={[[-7.229801, -44.523826]]}
                            zoom={15}
                            style={{
                                height: 'calc(100vh - 40em)',
                                minHeight: '400px',
                                width: '100%',
                                minWidth: '200px',
                            }}
                        />
                    </Col>
                </Row>
            )}
        </Card>
    );
}
