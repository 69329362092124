import ChartMultiLine from '../../../../Components/ChartMultiLine/ChartMultiLine';

export default function OneChart({ title, titleChart, typeChart, data }) {
    return (
        <div className="block">
            <h4 className="pt24 pb8">{title}</h4>
            {(() => {
                switch (typeChart) {
                    case 'multiLine':
                        return (
                            <ChartMultiLine data={data} title={titleChart} />
                        );
                    default:
                        return null;
                }
            })()}
        </div>
    );
}
