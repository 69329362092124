import React from 'react';
import Header from '../../Components/Header';
import { Link } from 'react-router-dom';
import {
    BookOutlined,
    EditOutlined,
    EyeOutlined,
    MailOutlined,
} from '@ant-design/icons';
import List from '../../Components/List';
import MonitoringRepository from './MonitoringRepository';
import BadgeStatus from '../../../Components/BadgeStatus/BadgeStatus';
import { useTranslation } from 'react-i18next';

export default function MonitoringList() {
    const { t } = useTranslation(['site']);

    const columns = [
        {
            title: t('site:admin_id'),
            dataIndex: 'id',
            key: 'id',
            render: (text) => text,
        },
        {
            title: t('site:admin_loanAmount'),
            dataIndex: 'loan_amount',
            key: 'loan_amount',
            render: (text) => text,
        },
        {
            title: t('site:admin_company'),
            dataIndex: 'company',
            key: 'company',
            render: (text) => text,
        },
        {
            title: t('site:admin_appliedOn'),
            dataIndex: 'applied_on',
            key: 'applied_on',
            render: (text) => text,
        },
        {
            title: t('site:admin_grade'),
            dataIndex: 'grade',
            render: (text) => text,
        },
        {
            title: t('site:admin_status'),
            dataIndex: 'status',
            key: 'status',
            render: (text) => BadgeStatus({ text }),
        },
        {
            title: t('site:admin_action'),
            key: 'action',
            render: () => (
                <div className="display-flex align-items-center fs-20">
                    <Link to="#">
                        <EyeOutlined
                            className="fs-28"
                            style={{ color: '#444036' }}
                        />
                    </Link>
                    <Link to="#" className="ml16">
                        <EditOutlined
                            className="fs-28"
                            style={{ color: '#444036' }}
                        />
                    </Link>
                    <Link to="#" className="ml16">
                        <BookOutlined
                            className="fs-28"
                            style={{ color: '#444036' }}
                        />
                    </Link>
                    <Link to="#" className="ml16">
                        <MailOutlined
                            className="fs-28"
                            style={{ color: '#444036' }}
                        />
                    </Link>
                </div>
            ),
            width: 170,
            fixed: 'right',
        },
    ];

    return (
        <>
            <Header title={t('site:admin_monitoring')} />
            <List
                fetchDataMethod={MonitoringRepository.list}
                columns={columns}
                pageSize={100}
            />
        </>
    );
}
