import './progress-circle.scss';
import { Progress } from 'antd';
import { isMobile } from 'react-device-detect';

/**
 * @param {number|string} value
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProgressCircle({ value }) {
    let strokeColor = {};

    if (value > 0 && value <= 20) {
        strokeColor = {
            '0%': '#F00',
            '33%': '#FFA400',
        };
    } else if (value > 20 && value <= 40) {
        strokeColor = {
            '0%': '#F00',
            '33%': '#FFA400',
            '100%': '#FFC100',
        };
    } else if (value > 40 && value <= 50) {
        strokeColor = {
            '0%': '#F00',
            '33%': '#FFA400',
            '100%': '#FFC100',
        };
    } else if (value > 50 && value <= 60) {
        strokeColor = {
            '0%': '#F00',
            '33%': '#FFA400',
            '66%': '#FFC100',
            '99%': '#e3fcbb',
        };
    } else if (value > 60 && value <= 70) {
        strokeColor = {
            '0%': '#F00',
            '33%': '#FFA400',
            '66%': '#FFC100',
            '99%': '#e3fcbb',
        };
    } else if (value > 70 && value <= 80) {
        strokeColor = {
            '0%': '#e3fcbb',
            '100%': '#e3fcbb',
        };
    } else if (value > 80 && value <= 100) {
        strokeColor = {
            '0%': '#90EA00',
            '100%': '#90EA00',
        };
    }

    const format = Number.parseInt(value)
        ? !isMobile
            ? value + '/100'
            : value
        : value;

    return (
        <Progress
            size={isMobile ? 50 : 82}
            type="circle"
            percent={value}
            strokeColor={strokeColor}
            format={() => format}
            strokeWidth={isMobile ? 10 : 12}
        />
    );
}
