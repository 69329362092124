import { Col, Row } from 'antd';

export default function ImageGallery({ title, items = [], legend = null }) {
    return (
        <div className="block">
            <h4 className="pb8">{title}</h4>
            <Row gutter={16} justify="center">
                {items.map((item, index) => {
                    return (
                        <Col xs={24} md={8} key={index}>
                            <img
                                className="img-responsive img-rounded img-shadow"
                                src={item.image}
                                alt={item.label ?? ''}
                            />
                        </Col>
                    );
                })}
            </Row>
            {legend && <p className="text-center pt16">{legend}</p>}
        </div>
    );
}
