import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserSettingsRepository from '../UserSettings/UserSettingsRepository';
import http from '../../../Services/HttpService';
import useUserInfo from '../../../Hooks/useUserInfo';

/**
 * Everything that should be loaded before the application
 * starts itself should be done here
 *
 * for now we're loading all user's info
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function PrepareData() {
    const { saveUserInfo, getUserRole } = useUserInfo();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const fetchUserInfo = async (source) => {
        const response = await UserSettingsRepository.show(source);
        saveUserInfo(response);
    };

    useEffect(() => {
        let CancelToken = http.CancelToken;
        let source = CancelToken.source();

        Promise.all([fetchUserInfo(source)]).then(async () => {
            // setTimeout(() => setLoading(false), 1000)
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <>Loading ...</>;
    } else {
        // todo: define first page according to the role
        switch (getUserRole()) {
            case 'admin' || 'agrifin-analyst':
                return navigate('/app/admin/accounts');

            default:
                return navigate('/app/admin/applications/create');
        }
    }
}
