import Header from '../../Components/Header';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    notification,
    InputNumber,
    Switch,
    Select,
} from 'antd';
import ReportBlocksRepository from './ReportBlocksRepository';
import { DEFAULT_VALUES } from './ReportBlockScoreTypeEnum';

export default function ReportBlocksCreate() {
    const navigate = useNavigate();
    const { form } = Form.useForm();
    const formRef = React.useRef(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const onFinish = async (values) => {
        setButtonLoading(true);

        const item = await ReportBlocksRepository.create(values);

        if (item) {
            api.success({
                message: 'Item create',
                description: 'Item successfully created',
            });
        }

        navigate(`/app/admin/report-blocks`);
    };

    return (
        <>
            {contextHolder}

            <Header
                title="Report Blocks"
                subTitle="You can create report blocks here"
                button={{ to: '/app/admin/report-blocks', label: 'Back' }}
            />

            <Form
                form={form}
                name="report-blocks"
                onFinish={onFinish}
                layout="vertical"
                scrollToFirstError
                ref={formRef}
                initialValues={{
                    score_type: 'integer',
                    weightage: 1.0,
                }}
            >
                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={12} md={6}>
                        <Form.Item
                            name="order"
                            label="Display order"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the order',
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100} />
                        </Form.Item>
                    </Col>

                    <Col span={6} xs={12} md={6}>
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please type the title',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={6} xs={12} md={6}>
                        <Form.Item
                            name="is_visible"
                            label="Is visible"
                            valuePropName="checked" // This ensures the form recognizes the switch's state
                        >
                            <Switch
                                checkedChildren="true"
                                unCheckedChildren="false"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    <Col span={6} xs={12} md={6}>
                        <Form.Item name="score_type" label="Score type">
                            <Select
                                style={{
                                    width: 120,
                                }}
                                size={'large'}
                                options={DEFAULT_VALUES}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6} xs={12} md={6}>
                        <Form.Item name="weightage" label="Weightage">
                            <InputNumber
                                defaultValue="1"
                                size={'large'}
                                min="0"
                                max="99"
                                step="0.01"
                                stringMode
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button
                        loading={buttonLoading}
                        type="primary"
                        size="large"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
